import './SharedList.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle, ShortDateTime } from '../../components';
import { useParams } from 'react-router-dom'
import { SharedProductDto, SharedProductState } from './SharedList.model';
import { Api } from '../../api';
import { Popconfirm, Table, Space, Typography } from 'antd';
import { LoaderContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ProductEdit } from './components';

export const SharedList: FC = React.memo(() => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const { Text } = Typography;

    const { setLoaderState } = useContext(LoaderContext)

    const [productsNewData, setProductsNewData] = useState<SharedProductDto[]>([])

    const urlParams = useParams();

    const dataFetch = useCallback(async (shopId: string, editKey: string) => {

        setLoaderState(true)

        const products = await Api.get<SharedProductDto[]>(`sharedlist/${shopId}/products?editKey=${editKey}`);

        setLoaderState(false)

        if (!products.ok || products.result === null)
            return

        setProductsNewData(products.result)
    }, [setLoaderState])

    const [editProductData, setEditProductData] = useState<SharedProductDto | undefined>(undefined)

    const onDelete = async (id: string) => {

        setLoaderState(true)

        var result = await Api.delete(`sharedlist/${urlParams.shopId}/products/${id}?editKey=${urlParams.editKey}`)

        setLoaderState(false)

        if (!result)
            return;

        setProductsNewData(items => items.filter(a => a.id !== id))
    }

    const onUpdateHandler = (product: SharedProductDto) => {
        setProductsNewData(prev => [...prev.filter(a => a.id !== product.id), product]);
        setEditProductData(undefined);
    }

    const onCancelUpdateHandler = () => {
        setEditProductData(undefined);
    }

    const toState = (state: SharedProductState): string => {
        switch (state) {
            case SharedProductState.New:
                return tt('shared-list.state.new')
            case SharedProductState.Ordering:
                return tt('shared-list.state.ordering')
            default:
                return ''
        }

    }

    useEffect(() => {
        dataFetch(urlParams.shopId ?? '', urlParams.editKey ?? '');
    }, [dataFetch, urlParams.shopId, urlParams.editKey])

    return (
        <PageContent>
            <ContentTitle title={t('shared-list.title')} />

            {editProductData &&
                <ProductEdit onUpdateProduct={onUpdateHandler} product={editProductData} onCancelUpdateProduct={onCancelUpdateHandler} />
            }

            <div className="products">
                <Table<SharedProductDto> dataSource={productsNewData}
                    rowKey="id"
                    pagination={false}
                    expandable={{
                        expandedRowRender: item =>
                            <Space direction="vertical">
                                <Text type="secondary">{`${t('shared-list.userNameWillBuy')}: ${item.userNameWillBuy};`}</Text>
                                <Text type="secondary">{`${t('product.createDate')}: `}<ShortDateTime date={item.createDate} /></Text>
                            </Space>,
                    }}
                >

                    <Column<SharedProductDto> title={t('product.name_edit')}
                        responsive={["xs"]}
                        dataIndex="name"
                        key="name"
                        sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                        render={(text, item) => (
                            <>
                                <p>{item.name} - {item.count} {item.unit}</p>
                            </>
                        )}
                    />

                    <Column<SharedProductDto> title={t('product.name_edit')}
                        responsive={["sm", "md", "lg", "xl", "xxl"]}
                        dataIndex="name"
                        key="name"
                        sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                        render={(text, item) => (
                            <p>{item.name}</p>
                        )}
                    />


                    <Column<SharedProductDto>
                        responsive={["sm"]}
                        title={t('count')}
                        dataIndex="count"
                        key="count"
                        sorter={(a, b) => a.count - b.count}
                        render={(text, item) => (
                            <Text>{item.count} {item.unit}</Text>
                        )}
                    />

                    <Column<SharedProductDto> title={t('shared-list.userNameWillBuy')}
                        responsive={["lg"]}
                        dataIndex="userNameWillBuy"
                        key="userNameWillBuy"
                        render={(text, item) => (<>
                            {item.userNameWillBuy && (<Text type="secondary">{item.userNameWillBuy}</Text>)}
                        </>)}
                    />

                    <Column<SharedProductDto> title={t('shared-list.state-name')}
                        responsive={["lg"]}
                        dataIndex="state"
                        key="state"
                        render={(text, item) => (<Text type="secondary">{toState(item.state)}</Text>)}
                    />

                    <Column<SharedProductDto> title={t('product.createDate')}
                        responsive={["lg"]}
                        dataIndex="createDate"
                        key="createDate"
                        render={(text, item) => (
                            <Text type="secondary"><ShortDateTime date={item.createDate} /></Text>
                        )}
                    />
                    <Column<SharedProductDto>
                        title={t('actions')}
                        key="action"
                        render={(text, item) => (
                            <Space>

                                {item.state === SharedProductState.New
                                    && <EditOutlined onClick={() => setEditProductData(item)} />
                                }

                                {item.state === SharedProductState.New
                                    && <Popconfirm
                                                        title={t('products.delete_text')}
                                                        onConfirm={() => onDelete(item.id)}
                                                        okText={t('yes')}
                                                        cancelText={t('no')}>
                                                        <DeleteOutlined />
                                                    </Popconfirm>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </div>

        </PageContent>
    )
})
