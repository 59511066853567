import './ProductEdit.scss'

import React, { useCallback, useContext } from 'react'
import { Form, Input, Button, InputNumber, message, Space } from 'antd'
import { ProductEditProps } from './ProductEdit.model';
import { ProductDto } from '../Products.model';
import { useTranslation } from 'react-i18next';
import { LoaderContext } from '../../../contexts';
import { Api } from '../../../api';

export const ProductEdit: React.FC<ProductEditProps> = React.memo(({ onUpdateProduct, product, onCancelUpdateProduct }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [form] = Form.useForm()
    const { setLoaderState } = useContext(LoaderContext);

    const handleUpdateProduct = useCallback(
        async (values: Partial<ProductDto>) => {

            setLoaderState(true)

            const pr = values as ProductDto;

            const r = await Api.put<ProductDto, ProductDto>(`products/${product.id}`, pr);

            if (r.ok) {
                const updatedProduct = r.result as ProductDto;
                form.resetFields();
                onUpdateProduct(updatedProduct);
                message.success(`${t('updated')} "${updatedProduct.name}"`);
            }
            else {
                message.error(t('product.add_error_message'));
            }

            setLoaderState(false);
        },
        [form, onUpdateProduct, setLoaderState, t, product.id]
    )

    return (
        <Form
            className="content-element"
            name="productEditForm"
            initialValues={product}
            onFinish={handleUpdateProduct}
            form={form}
        >
            <Form.Item >
                <Space>
                    <Button
                        form="productEditForm"
                        type="primary"
                        htmlType="submit"
                    >
                        {t('product.bt_update')}
                    </Button>

                    <Button autoFocus htmlType="button" className="button" onClick={onCancelUpdateProduct}>
                        {t('cancel')}
                    </Button>
                </Space>
            </Form.Item>

            <Form.Item
                hidden={true}
                name="id"
            >
                <Input />
            </Form.Item>

            <Form.Item
                hidden={true}
                name="listId"
            >
                <Input />
            </Form.Item>

            <Form.Item
                hidden={true}
                name="state"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('product.name_edit')}
                name="name"
                rules={[
                    {
                        required: true,
                        message: tt('product.name_required_message'),
                    },
                ]}
            >
                <Input autoComplete="off" />
            </Form.Item>


            < Form.Item
                label={t('count')}
                name="count"
            >
                <InputNumber min={1} type="number" />
            </Form.Item>

            < Form.Item
                label={t('product.unit')}
                name="unit"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('product.expectedPrice')}
                name="expectedPrice"
            >
                <InputNumber min={1} type="number" />
            </Form.Item>

            <Form.Item
                label={t('product.priority')}
                name="priority"
            >
                <InputNumber type="number" />
            </Form.Item>

            <Form.Item >
                <Space>
                    <Button
                        form="productEditForm"
                        type="primary"
                        htmlType="submit"
                    >
                        {t('product.bt_update')}
                    </Button>

                    <Button htmlType="button" className="button" onClick={onCancelUpdateProduct}>
                        {t('cancel')}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
})
