import './DiscountCards.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle, Barcode } from '../../components';
import { Button, message, Drawer, Popconfirm, Space, Table, Image, Upload, Typography } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, CreateDiscountCardRequest, DiscountCardResponse, DiscountCardType } from '../../api';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, UploadOutlined, FireOutlined } from '@ant-design/icons';
import { DiscountCardsCreate } from './components/DiscountCardsCreate';
import QRCode from 'qrcode.react';
import Column from 'antd/es/table/Column';
import { API_BASE_URL } from '../../consts';

export const DiscountCards: FC = React.memo(() => {
    const { t } = useTranslation();
    const { Link } = Typography;
    const [modalScanData, setModalScanData] = useState<boolean>(false)
    const [defaultDiscountCardsData, setDefaultDiscountCardsData] = useState<DiscountCardResponse>({} as DiscountCardResponse)
    const [discountCardsData, setDiscountCardsData] = useState<DiscountCardResponse[]>([])
    //const [editProductListData, setEditProductListData] = useState<ProductListsProp>(createNewProductList())
    const { setLoaderState } = useContext(LoaderContext)

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);

            const response = await Api.get<DiscountCardResponse[]>(`discount-cards`)

            if (!response.ok || !response.result)
                return

            setDiscountCardsData(response.result.sort(comparerDiscountCard));

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    const comparerDiscountCard = (a: DiscountCardResponse, b: DiscountCardResponse) => {
        return ('' + a.name).localeCompare(b.name);
    };

    useEffect(() => {
        dataFetch()
    }, [dataFetch])

    const onAddHandler = async (request: CreateDiscountCardRequest) => {

        setLoaderState(true)

        const result = await Api.post<CreateDiscountCardRequest, DiscountCardResponse>(`discount-cards`, request as CreateDiscountCardRequest);

        setLoaderState(false)

        if (!result.ok || result.result === undefined) {
            message.error(result.exceptionResult.Message);
            return;
        }

        setDiscountCardsData(prev => [...prev.filter(a => a.id !== result.result.id), result.result].sort(comparerDiscountCard))
    }

    const onDelete = (id: string) => {

        setLoaderState(true)

        var result = Api.delete(`discount-cards/${id}`)

        setLoaderState(false)

        if (!result) {
            message.error('Error');
            return;
        }

        setDiscountCardsData(items => items.filter(a => a.id !== id))
    }

    const onDeletePhoto = async (id: string) => {

        setLoaderState(true)

        var result = await Api.delete(`discount-cards/${id}/photo`)

        setLoaderState(false)

        if (!result) {
            message.error('Error');
            return;
        }

        await dataFetch();
    }

    const getUploadUrl = (id: string) => {
        return `${API_BASE_URL}/api/v1/discount-cards/${id}/photo`;
    }

    const onChange = async (info: any) => {
        if (info.file.status === 'uploading') {
            setLoaderState(true);
        }
        else {
            setLoaderState(false);
            await dataFetch();
        }

        if (info.file.status === 'done') {
            message.info(`${info.file.name} файл загружен.`);
        } if (info.file.status === 'error') {
            message.error(`Ошибка загрузки файла ${info.file.name}.`);
        }
    }

    return (
        <PageContent>

            <ContentTitle title={t('discount_cards.title')} />

            <DiscountCardsCreate onAddDiscountCards={onAddHandler} />

            <Table<DiscountCardResponse> dataSource={discountCardsData}
                rowKey="id"
                pagination={false}
            >

                <Column<DiscountCardResponse> title={t('product.name_edit')}
                    responsive={["xs"]}
                    dataIndex="name"
                    key="name"
                    sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                    render={(text, item) => (
                        <Space direction='vertical'>
                            <Link onClick={() => { setDefaultDiscountCardsData(item); setModalScanData(true) }}>{item.name}</Link>
                            {item.fileName && <Image
                                height={50}
                                width={100}
                                src={item.fileName}
                            />}
                        </Space>
                    )}
                />

                <Column<DiscountCardResponse> title={t('product.name_edit')}
                    responsive={["sm", "md", "lg", "xl", "xxl"]}
                    dataIndex="name"
                    key="name"
                    sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                    render={(text, item) => (
                        <>
                            <Link onClick={() => { setDefaultDiscountCardsData(item); setModalScanData(true) }}>{item.name}</Link>
                        </>
                    )}
                />

                <Column<DiscountCardResponse> title={t('product.picture')}
                    responsive={["sm", "md", "lg", "xl", "xxl"]}
                    dataIndex="picture"
                    key="picture"
                    render={(text, item) => (
                        <>
                            {item.fileName && <Image
                                height={50}
                                width={100}
                                src={item.fileName}
                            />}
                        </>
                    )}
                />

                <Column<DiscountCardResponse>
                    title={t('actions')}
                    key="action"
                    render={(text, item) => (
                        <Space>
                            {!item.fileName &&
                                <Upload action={getUploadUrl(item.id)} onChange={onChange} showUploadList={false} >
                                    <Button icon={<UploadOutlined />}></Button>
                                </Upload>}

                            {item.fileName &&
                                <Popconfirm
                                    title={t('delete')}
                                    onConfirm={() => onDeletePhoto(item.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}>
                                    <Button icon={<FireOutlined />}></Button>
                                </Popconfirm>}

                            <Popconfirm
                                title={t('delete')}
                                onConfirm={() => onDelete(item.id)}
                                okText={t('yes')}
                                cancelText={t('no')}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>

            <Drawer

                title={defaultDiscountCardsData.name ?? ""}
                placement='top'
                closable={false}
                onClose={() => setModalScanData(false)}
                open={modalScanData}
                key='DiscounCardDrawer'
                size='large'
                extra={
                    <Space>
                        <Button onClick={() => setModalScanData(false)}>Close</Button>
                    </Space>
                }
            >
                {defaultDiscountCardsData.type === DiscountCardType.QR &&
                    <Space direction='vertical'>
                        <QRCode
                            renderAs="svg"
                            size={300}
                            value={defaultDiscountCardsData.data ?? ""} />
                        <span>{defaultDiscountCardsData.data ?? ""}</span>
                    </Space>
                }
                {defaultDiscountCardsData.type === DiscountCardType.Barcode &&
                    <Barcode
                        value={defaultDiscountCardsData.data ?? ""}
                    />
                }
            </Drawer>
        </PageContent>
    )
})