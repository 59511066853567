import { API_BASE_URL } from "./consts";
import { Guid } from "guid-typescript";

export class Api {
    static getHeaders() {
        return {
            'Content-type': 'application/json; charset=UTF-8',
            'access_token': `${process.env.REACT_APP_AUTH_TOKEN}`,
            //'Content-type': 'multipart/form-data; boundary=----WebKitFormBoundarywzGIXa37z20rpbPR',
            'x-correlation-id': Guid.create().toString()
        }
    };

    static sendFile<TRes>(url: string, item: Blob) {

        var data = new FormData()
        data.append('file', item)

        return fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'POST',
            headers: {

                'x-correlation-id': Guid.create().toString()
            },
            body: data
        })
            .then(response => Api.parseResponse<TRes>(response));
    }

    static tryGet<T>(url: string) {
        try {
            return fetch(`${API_BASE_URL}/api/v1/${url}`, { credentials: 'include', headers: Api.getHeaders() })
                .then(response => response.ok
                    ? response.json().then(result => new ApiResponse(true, result as T, {} as ExceptionContract))
                    : response.json().then(result => new ApiResponse(false, {} as T, result as ExceptionContract)));
        } catch (err) { }
        return new ApiResponse(false, null, { Message: `Something went wrong` } as ExceptionContract)
    }

    static get<T>(url: string) {
        try {
            return fetch(`${API_BASE_URL}/api/v1/${url}`, { credentials: 'include', headers: Api.getHeaders() })
                .then(response => Api.parseResponse<T>(response));
        } catch (err) { }

        return new ApiResponse(false, null, { Message: `Something went wrong` } as ExceptionContract)
    }

    static post<TReq, TRes>(url: string, item: TReq) {

        return fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'POST',
            headers: Api.getHeaders(),
            body: JSON.stringify(item)
        })
            .then(response => Api.parseResponse<TRes>(response));
    }

    static async postWithoutResponse<TReq>(url: string, item: TReq) {

        const fetchResult = await  fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'POST',
            headers: Api.getHeaders(),
            body: JSON.stringify(item)
        });

        if (fetchResult.status === 401)
            window.location.replace("/signin");

        return fetchResult.ok;
    }

    static put<TReq, TRes>(url: string, item: TReq) {

        return fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'PUT',
            headers: Api.getHeaders(),
            body: JSON.stringify(item)
        })
            .then(response => Api.parseResponse<TRes>(response));
    }

    static async putWithoutResponse<T>(url: string, item: T) {

        var fetchResult = await fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'PUT',
            headers: Api.getHeaders(),
            body: JSON.stringify(item)
        });

        if (fetchResult.status === 401)
            window.location.replace("/signin");

        return fetchResult.ok;
    }

    static patch<TReq, TRes>(url: string, item: TReq) {

        return fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'PATCH',
            headers: Api.getHeaders(),
            body: JSON.stringify(item)
        })
            .then(response => Api.parseResponse<TRes>(response));
    }

    static async parseResponse<TRes>(response: Response) {
        switch (response.status) {
            case 200:
                return await response.json().then(result => new ApiResponse(true, result as TRes, {} as ExceptionContract));

            case 204:
                return await new ApiResponse(true, {} as TRes, {} as ExceptionContract);

            case 401:
                {
                    window.location.replace("/signin");
                    return await response.json().then(result => new ApiResponse(false, {} as TRes, { Message : "401 - Unauthorized"} as ExceptionContract));
                }

            default:
                return await response.json().then(result => new ApiResponse(false, {} as TRes, result as ExceptionContract));
        }
    }

    static patchWithoutRequestBody<TRes>(url: string) {

        return fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'PATCH',
            headers: Api.getHeaders()
        })
            .then(response => Api.parseResponse<TRes>(response));
    }

    static async delete(url: string) {

        const fetchResult = await fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'DELETE',
            headers: Api.getHeaders()
        });

        if (fetchResult.status === 401)
            window.location.replace("/signin");

        return fetchResult.ok;
    }

    static async deleteWithoutResult(url: string) {

        const fetchResult = await fetch(`${API_BASE_URL}/api/v1/${url}`, {
            credentials: 'include',
            method: 'DELETE',
            headers: Api.getHeaders()
        });

        if (fetchResult.ok)
            return new ApiResponseWithoutResult(true, {} as ExceptionContract);

        if (fetchResult.status === 401)
            window.location.replace("/signin");

        var error = await fetchResult.json();

        return new ApiResponseWithoutResult(false, { ...error } as ExceptionContract);
    }
}

export class ApiResponseWithoutResult
{
    ok: boolean;
    exceptionResult: ExceptionContract;

    constructor(ok: boolean, exceptionResult: ExceptionContract) {

        this.ok = ok;
        this.exceptionResult = exceptionResult;
    }
}

export class ApiResponse<T>
{
    ok: boolean;
    result: T;
    exceptionResult: ExceptionContract;

    constructor(ok: boolean, result: T, exceptionResult: ExceptionContract) {

        this.ok = ok;
        this.result = result;
        this.exceptionResult = exceptionResult;
    }
}

export interface ExceptionContract
{
    Message: string;
}

export interface NoContentResponse
{

}


/** Contracts */

export interface ProfileContract {

    id: string

    name: string

    loginProviderName: string,

    isAvailableEvents: boolean,

    familyFeatures: number
}

export interface ProductListContract {
    id: string
    name: string
    defaultPurchaseCategoryId: string
    defaultPurchaseCategoryName: string
    isDefault: boolean
}

export interface ProductListRequest {
    id: string

    name: string

    defaultPurchaseCategoryId: string
}

export interface FamilyContract {
    id: string,
    surname: string,
    password: string,
    familyItems: FamilyItem[],
    devices: FamilyDevice[]
}

export interface JoinToFamilyContract {
    id: string,
    password: string,
}

export interface FamilyItem {
    name: string
}

export interface FamilyDevice {
    id: string,
    name: string
}

export interface AddDeviceContract {
    id: string,
    password: string
}

export interface PhotoContract {
    id: string,
    fileName: string,
    date: Date,
    originFileName: string,
    canDelete: boolean
    isLiked: boolean,
    eventPhotoType: EventPhotoTypeContract
}

export enum EventPhotoTypeContract {
    Photo = 1,
    Video = 2,
};

export interface UpdateDatePhotoRequest {
    date: Date,
}

export interface PurchaseReceiptContract {
    id: string,
    fileName: string,
    originFileName: string,
    canDelete: boolean,
}

export interface EventContract {
    id: string,
    name: string,
    date: string,
    titlePhoto: PhotoContract,
    titleIconFileName: string,
}

export interface EventDetailContract {
    id: string,
    name: string,
    date: Date,
    titlePhoto: PhotoContract,
    photos: PhotoContract[],
    isOur: boolean,
    canUploadPhoto: boolean,
    isPublicUrlEdit: boolean,
    isPublicUrlView: boolean,
    publicUrlEdit: string,
    publicUrlView: string,
    guests: string[],
}

export interface EventUpdateContract {
    name: string,
    date: Date,
    titlePhotoId: string,
    isPublicUrlEdit: boolean,
    isPublicUrlView: boolean,
}

export interface EventCreateContract {
    name: string,
    date: Date,
    titlePhotoId: string,
    isPublicUrlEdit: boolean,
    isPublicUrlView: boolean,
}

export interface PurchaseUpdateContract {
    amount: number,
    createDate?: Date,
    description: string,
    budgetCurrentSumId: string,
    products: PurchaseItemUpdateContract[],
    purchaseCategoryId: string,
}

export interface PurchaseItemUpdateContract {
    name: string,
    count: number,
}

export interface FriendsContract {
    familyId: string,
    surname: string
}

export interface PublicEventContract {
    name: string,
    canUpdate: boolean,
    photos: PhotoContract[],
}

export interface BudgetResponse {
    id: string,
    title: string,
    amount: number,
    actualAmount?: number,
    balance: number,
    date: Date,
    budgetType: BudgetType,
    done: boolean,
    staticSumPeriodType?: StaticSumPeriodType,
    notCalculate: boolean,
    notRequired: boolean,
    isCreditCard: boolean,
    creditCardLimit: number,
    last4DigitsOfCard: string,
}

export enum BudgetType {
    Current = 1,
    Static = 2,
    Dynamic = 4
};

export enum StaticSumPeriodType {
    PerMonth = 1,
    PerDay = 2,
    PerYear = 4,
};

export interface BudgetCurrentRequest {
    id: string,
    title: string,
    amount: number,
    notCalculate: boolean,
    isCreditCard: boolean,
    creditCardLimit: number,
    last4DigitsOfCard: string,
}

export interface BudgetStaticRequest {
    id: string,
    title: string,
    amount: number,
    dayOfMonth?: number,
    staticSumPeriodType?: StaticSumPeriodType,
    monthOfYear?: number,
    notRequired: boolean,
}

export interface BudgetDynamicRequest {
    id: string,
    title: string,
    amount: number,
    date: Date,
    purchaseCategoryId?: string,
    notRequired: boolean,
}

export interface DynamicSumDoneRequest {
    title: string,
    amount?: number,
    currentSumId?: string,
    purchaseCategoryId?: string
}

export interface StaticSumDoneRequest{
    title: string,
    date: Date,
    actualAmount?: number,
    currentSumId?: string
}

export interface CorrectStaticSumRequest {
    date: Date,
    amount: number,
}

export interface BudgetCurrentSumResponse {
    id: string,
    title: string,
    amount: number,
}

export interface EventSetTitlePhotoRequest {
    titlePhotoId: string,
}

export interface CreateTempTokenResponse {
    id: string,
    password: string,
}

export interface CompleteShoppingRequest {
    amount: number,
    budgetCurrentSumId: string,
    purchaseCategoryId: string,
}

export interface DiscountCardResponse {
    id: string,
    name: string,
    data: string,
    type: DiscountCardType,
    fileName: string,
}

export interface CreateDiscountCardRequest {
    name: string,
    data?: string,
    type?: DiscountCardType,
}

export enum DiscountCardType {
    QR = 1,
    Barcode = 2,
};

export interface PurchaseCategoryResponse {
    id: string,
    name: string,
    canDelete: boolean,
}

export interface CreatePurchaseCategoryRequest {
    name: string,
}

export interface TransferCurrentSumRequest {
    sourceCurrentSumId: string,
    destinationCurrentSumId: string,
    amount: number,
}

export enum SystemNotificationType {
    Info = 1,
    Error = 2,
    Success = 4
};

export interface SystemNotificationResponse {
    type: SystemNotificationType,
    message: string,
}

export enum FamilyFeatures {
    None = 0,

    GptParser = 1,
};

export interface TreeProductCategoryContract {
    item: ProductCategoryContract,
    children: TreeProductCategoryContract[],
}

export interface ProductCategoryContract {
    id: string,
    parentId?: string,
    icon: string,
    name: string,
    shortName: string,
    weight?: number
    products?: ProductCategoryNameContract[],
    productsDraftCount: number
}

export interface ProductCategoryNameContract {
    id: string,
    name: string,
    createDate: string,
    isDraft: boolean,
}