import { Result } from 'antd'
import React, { FC } from 'react'
import { useLocation } from 'react-router-dom';
import { PageContent } from '../../components'
import { useTranslation } from 'react-i18next';

export const Error: FC = React.memo(() => {
    const { t } = useTranslation();

    const query = new URLSearchParams(useLocation().search);

    const message = query.get("message") ?? t('error.default_subtitle');

    return (
        <PageContent>
            <Result
                status="error"
                title={t('error.title')}
                subTitle={message}
                extra={[
                    //<Button type="primary" key="console">
                    //    Go Console
                    //</Button>,
                ]}
            />

        </PageContent>
    )
})
