import React from 'react'

import { ShortDateTimeProps } from './ShortDateTime.model'
import moment from 'moment';


export const ShortDateTime: React.FC<ShortDateTimeProps> = React.memo(({ date }) => {

    const nowMilliseconds = Date.now();

    const formatDate = (date: Date): string => {

        const momentDate = moment(date);

        var diffTime = Math.abs(momentDate.toDate().getTime() - nowMilliseconds);
        var diffDays = Math.ceil(diffTime / (1000 * 3600 * 24)); 

        if (diffDays < 7)
            return momentDate.format('ddd DD MMM HH:mm');

        if (diffDays >= 7 && diffDays < 360)
            return momentDate.format('DD MMM HH:mm');

        return momentDate.format('DD.MM.YYYY');
    }

    return (
        <>
            {formatDate(date)}
        </>
    )
})
