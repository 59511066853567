import './Products.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
    ChangeEvent,
} from 'react'


import { PageContent, ContentTitle, ShortDateTime, ContentGroup2 } from '../../components';
import { useParams } from 'react-router-dom'
import { ProductDto, ProductState } from './Products.model';
import { Api, BudgetCurrentSumResponse } from '../../api';
import { Popconfirm, Table, Space, Input, Typography, message, Tag, Image, Button } from 'antd';
import { LoaderContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, CloudSyncOutlined } from '@ant-design/icons';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { API_BASE_URL } from "../../consts";
import { ProductCreate, ProductEdit, FinishShopping } from './components';

export const Products: FC = React.memo(() => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const { Text, Link } = Typography;

    const { setLoaderState } = useContext(LoaderContext)

    const [productsNewData, setProductsNewData] = useState<ProductDto[]>([])
    const [productsFilteredNewData, setFilteredProductsNewData] = useState<ProductDto[]>([])
    const [searchData, setSearchData] = useState<string>()
    const [productsOrderingData, setProductsOrderingData] = useState<ProductDto[]>([])
    const [budgetCurrentSumData, setBudgetCurrentSumData] = useState<BudgetCurrentSumResponse[]>([]);

    const urlParams = useParams();

    const dataFetch = useCallback(async (productListId: string) => {

        const listId = productListId !== 'all' ? productListId : '';

        setLoaderState(true)

        const products = await Api.get<ProductDto[]>(`products?ListId=${listId}`);

        setLoaderState(false)

        if (!products.ok || products.result === null)
            return

        setProductsNewData(products.result.filter(p => p.state === ProductState.New)
            .sort((a, b) => (a.rootProductCategoryWeight ?? 0) - (b.rootProductCategoryWeight ?? 0)))

        setProductsOrderingData(products.result.filter(p => p.state === ProductState.Ordering))

    }, [setLoaderState])

    const createNewProduct = () => { return { name: '', count: 1 } as ProductDto };

    const [editProductData, setEditProductData] = useState<ProductDto>(createNewProduct())

    const onAddHandler = useCallback((product: ProductDto) => {

        setProductsNewData(prev => {
            if (prev.some(a => a.id === product.id))
                return prev;
            else
                return [...prev, product]
        });
    }, [])

    const [connection, setConnection] = useState<HubConnection>();

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            //.withUrl(`${API_BASE_URL}/notifications`, { accessTokenFactory: () => 'eyJh' })
            .withUrl(`${API_BASE_URL}/notifications`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');

                    connection.on('ProductCreated', product => {
                        if (urlParams.productListId !== product.listId)
                            return;

                        const newProductDto = product as ProductDto;

                        onAddHandler(newProductDto);
                    });

                    connection.on('ProductMovedToBasket', product => {
                        if (urlParams.productListId !== product.listId)
                            return;

                        const newProductDto = product as ProductDto;

                        moveToOrdering(newProductDto);
                    });

                    connection.on('ProductMovedToList', product => {
                        if (urlParams.productListId !== product.listId)
                            return;

                        const newProductDto = product as ProductDto;

                        moveToNew(newProductDto);
                    });

                    connection.on('ProductUpdated', product => {
                        if (urlParams.productListId !== product.listId)
                            return;

                        const updatedProductDto = product as ProductDto;

                        setProductsNewData(items => {
                            const r = items.filter(a => a.id !== updatedProductDto.id);

                            if (r?.length === items?.length) {
                                return items;
                            }

                            return [updatedProductDto, ...r]
                        });

                        setProductsOrderingData(items => {
                            const r = items.filter(a => a.id !== updatedProductDto.id);

                            if (r?.length === items?.length) {
                                return items;
                            }

                            return [updatedProductDto, ...r]
                        })

                        console.log("ProductUpdated4");
                    });

                    connection.on('ProductDeleted', idResponse => {
                        setProductsNewData(items => items.filter(a => a.id !== idResponse.id));
                        setProductsOrderingData(items => items.filter(a => a.id !== idResponse.id));
                    });

                    connection.on('BudgetCurrentSumDecremented', n => {
                        const budgetCurrentSumResponse = n as BudgetCurrentSumResponse;

                        setBudgetCurrentSumData(items => items.filter(a => a.id !== budgetCurrentSumResponse.id));
                        setBudgetCurrentSumData(items => [...items, budgetCurrentSumResponse]);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection, productsNewData, productsOrderingData, urlParams.productListId, onAddHandler]);

    const toNew = async (id: string) => {
        const item = productsOrderingData.find(a => a.id === id);

        if (item === undefined)
            return;

        setLoaderState(true)

        try {
            const response = await Api.patchWithoutRequestBody<ProductDto>(`products/${item.id}/move_to_list`);

            if (response.ok) {
                moveToNew(response.result);
                return;
            }

            message.error(t('products.update_error'));
        }
        catch {
            message.error(t('products.update_error'));
        }
        finally {
            setLoaderState(false)
        }
    }

    const moveToNew = async (product: ProductDto) => {

        setProductsOrderingData(items => items.filter(a => a.id !== product.id));
        setProductsNewData(items => {
            if (items.some(a => a.id === product.id))
                return items;

            return [...items, product]
        });
    }

    const toOrdering = async (id: string) => {
        const item = productsNewData.find(a => a.id === id);

        if (item === undefined)
            return;

        setLoaderState(true)

        const response = await Api.patchWithoutRequestBody<ProductDto>(`products/${item.id}/move_to_basket`);

        setLoaderState(false)

        if (response.ok) {
            moveToOrdering(response.result);
            return;
        }

        message.error(t('products.update_error'));
    }

    const moveToOrdering = async (product: ProductDto) => {

        setProductsNewData(items => items.filter(a => a.id !== product.id));
        setProductsOrderingData(items => {
            if (items.some(a => a.id === product.id))
                return items;

            return [...items, product]
        });
    }

    const onDelete = (id: string) => {

        setLoaderState(true)

        var result = Api.delete(`products/${id}`)

        setLoaderState(false)

        if (!result)
            return;

        setProductsNewData(items => items.filter(a => a.id !== id))
    }

    const onUpdateHandler = (product: ProductDto) => {
        setProductsNewData(prev => [...prev.filter(a => a.id !== product.id), product]);
        setEditProductData(createNewProduct());
    }

    const onCancelUpdateHandler = () => {
        setEditProductData(createNewProduct());
    }

    const productsNewDataCost = productsNewData.filter(item => item.expectedPrice !== null)
        .reduce((sum, current) => sum + (current.expectedPrice * current.count), 0);

    const productsOrderingDataCost = productsOrderingData.filter(item => item.expectedPrice !== null)
        .reduce((sum, current) => sum + (current.expectedPrice * current.count), 0);

    const isUpdating = editProductData.id !== undefined;
    const isNotAll = urlParams.productListId !== 'all';

    const handleSearchOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchData(e.target.value);
        },
        []
    );

    useEffect(() => {

        if (searchData !== undefined)
            setFilteredProductsNewData(productsNewData.filter(p => p.name.toLowerCase().includes(searchData.toLowerCase())));
        else
            setFilteredProductsNewData(productsNewData);

    }, [productsNewData, searchData])

    const dataFetchBudgetCurrentSums = useCallback(async () => {

        setLoaderState(true)

        const budgetCurrentSumsResponse = await Api.get<BudgetCurrentSumResponse[]>(`purchases/budget/current-sums`);

        setLoaderState(false)

        if (!budgetCurrentSumsResponse.ok || budgetCurrentSumsResponse.result === null)
            return

        setBudgetCurrentSumData(budgetCurrentSumsResponse.result ?? []);

    }, [setLoaderState])

    useEffect(() => {
        dataFetch(urlParams.productListId ?? '');
        dataFetchBudgetCurrentSums();
    }, [dataFetch, dataFetchBudgetCurrentSums, urlParams.productListId])

    return (
        <PageContent>
            <ContentTitle title={t('products.title')} />

            {isNotAll &&
                (isUpdating
                    ? <ProductEdit onUpdateProduct={onUpdateHandler} product={editProductData} onCancelUpdateProduct={onCancelUpdateHandler} />
                    : <ProductCreate onAddProduct={onAddHandler} />)
            }

            <ContentGroup2 title={t('products.need_buy')}>
                <Input.Search allowClear onChange={handleSearchOnChange} placeholder={tt('search')} />
                <Table<ProductDto> dataSource={productsFilteredNewData}
                    rowKey="id"
                    pagination={false}
                    footer={() =>
                        <Space direction='horizontal'>
                            <Button type="primary" onClick={() => dataFetch(urlParams.productListId ?? '')}>
                                <CloudSyncOutlined /> {t('refresh')}
                            </Button>
                            <Text type="secondary">{t('products.expectedCost')}: ${productsNewDataCost}</Text>
                        </Space>
                    }
                    expandable={{
                        expandedRowRender: item =>
                            <Space direction="vertical">
                                {item.expectedPrice && <Text type="secondary">{`${t('product.expectedPrice')}: ${item.expectedPrice};`}</Text>}
                                <Text type="secondary">{`${t('product.creator')}: ${item.createDeviceName || item.createUserName};`}</Text>
                                <Text type="secondary">{`${t('product.createDate')}: `}<ShortDateTime date={item.createDate} /></Text>
                                {item.fileName && <Image
                                    height={150}
                                    width={250}
                                    src={item.fileName}
                                />}
                            </Space>,
                    }}
                >

                    <Column<ProductDto> title={t('product.picture')}
                        responsive={["lg", "xl", "xxl"]}
                        dataIndex="picture"
                        key="picture"
                        render={(text, item) => (
                            <>
                                {item.fileName && <Image
                                    height={50}
                                    width={100}
                                    src={item.fileName}
                                />}
                            </>
                        )}
                    />

                    <Column<ProductDto> title={t('product.name_edit')}
                        responsive={["xs"]}
                        dataIndex="name"
                        key="name"
                        sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                        render={(text, item) => (
                            <>
                                <Link onClick={() => toOrdering(item.id)}>{item.name} - {item.count} {item.unit}</Link>
                                {item.priority != null && <>&nbsp;<Tag>{t('product.priority_abbreviations')} {item.priority}</Tag></>}
                                {item.fileName && <>&nbsp;<Tag color="red">{t('product.has_picture')}</Tag></>}
                                {item.productCategoryName && <>&nbsp;<Tag color="green">{item.productCategoryName}</Tag></>}
                            </>
                        )}
                    />

                    <Column<ProductDto> title={t('product.name_edit')}
                        responsive={["sm", "md", "lg", "xl", "xxl"]}
                        dataIndex="name"
                        key="name"
                        sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                        render={(text, item) => (
                            <>
                                <Link onClick={() => toOrdering(item.id)}>{item.name}</Link>
                                {item.productCategoryName && <>&nbsp;<Tag color="green">{item.productCategoryName}</Tag></>}
                            </>
                        )}
                    />


                    <Column<ProductDto>
                        responsive={["sm"]}
                        title={t('count')}
                        dataIndex="count"
                        key="count"
                        sorter={(a, b) => a.count - b.count}
                        render={(text, item) => (
                            <Text>{item.count} {item.unit}</Text>
                        )}
                    />
                    <Column<ProductDto> responsive={["sm"]} title={t('product.expectedPrice')} dataIndex="expectedPrice" key="expectedPrice" sorter={(a, b) => a.expectedPrice - b.expectedPrice} />
                    <Column<ProductDto> responsive={["sm"]} title={t('product.priority')} dataIndex="priority" key="priority" sorter={(a, b) => (a.priority ?? 0) - (b.priority ?? 0)} />
                    <Column<ProductDto> title={t('product.creator')}
                        responsive={["lg"]}
                        dataIndex="creator"
                        key="creator"
                        render={(text, item) => (<>
                            {item.createDeviceName && (<Text type="secondary">{item.createDeviceName}</Text>)}
                            {item.createUserName && (<Text type="secondary">{item.createUserName}</Text>)}
                        </>)}
                    />
                    <Column<ProductDto> title={t('product.createDate')}
                        responsive={["lg"]}
                        dataIndex="createDate"
                        key="createDate"
                        render={(text, item) => (
                            <Text type="secondary"><ShortDateTime date={item.createDate} /></Text>
                        )}
                    />
                    <Column<ProductDto>
                        title={t('actions')}
                        key="action"
                        render={(text, item) => (
                            <Space>
                                <EyeInvisibleOutlined onClick={() => setProductsNewData(items => items.filter(a => a.id !== item.id))} />
                                <EditOutlined onClick={() => setEditProductData(item)} />
                                <Popconfirm
                                    title={t('products.delete_text')}
                                    onConfirm={() => onDelete(item.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}>
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Space>
                        )}
                    />
                </Table>
            </ContentGroup2>

            {productsOrderingData.length > 0 && <ContentGroup2 title={t('products.bought')}>
                <Table<ProductDto> dataSource={productsOrderingData}
                    pagination={false}
                    footer={() => `${t('products.expectedCost')}: ${productsOrderingDataCost}`}
                    rowKey="id"
                >
                    <Column<ProductDto> title={t('product.name_edit')}
                        responsive={["sm"]}
                        dataIndex="name"
                        key="name"
                        sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                        width='50%'
                        fixed='left'
                        render={(text, item) => (
                            <Link onClick={() => toNew(item.id)}>{item.name}</Link>
                        )}
                    />
                    <Column<ProductDto> title={t('product.name_edit')}
                        responsive={["xs"]}
                        dataIndex="name"
                        key="name"
                        sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                        width='50%'
                        fixed='left'
                        render={(text, item) => (
                            <Space direction="vertical" >
                                <Link onClick={() => toNew(item.id)}>{item.name}</Link>
                                <Text type="secondary">{`${t('count')}: ${item.count}; ${t('product.expectedPrice')}: ${item.expectedPrice}`}</Text>
                            </Space>
                        )}
                    />
                    <Column<ProductDto> responsive={["sm"]} title={t('count')} dataIndex="count" key="count" sorter={(a, b) => a.count - b.count} width='10%' />
                    <Column<ProductDto> responsive={["sm"]} title={t('product.expectedPrice')} dataIndex="expectedPrice" key="expectedPrice" sorter={(a, b) => a.expectedPrice - b.expectedPrice} width='10%' />
                </Table>
            </ContentGroup2>}

            {productsOrderingData.length > 0 && <ContentGroup2 title={t('products.finish_shopping')} className="element-group-padding">
                <FinishShopping
                    setProductsOrderingData={setProductsOrderingData}
                    budgetCurrentSums={budgetCurrentSumData}
                    productListId={urlParams.productListId}
                />
            </ContentGroup2>}
        </PageContent>
    )
})
