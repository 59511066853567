import { BrowserRouter } from 'react-router-dom'

import './App.css';

import { Header } from './components/Header';
import { LoaderContextProvider, UserContextProvider } from './contexts';
import { Compose } from './components';
import { Routing } from './routing/Routing';
import { CookiesAgree } from './components/CookiesAgree/CookiesAgree';
import { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { SystemNotificationResponse, SystemNotificationType } from './api';
import { message } from 'antd';
import { API_BASE_URL } from './consts';

function App() {

    const [connection, setConnection] = useState<HubConnection>();

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            //.withUrl(`${API_BASE_URL}/notifications`, { accessTokenFactory: () => 'eyJh' })
            .withUrl(`${API_BASE_URL}/notifications`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');

                    connection.on('SendNotifications', n => {

                        const not = n as SystemNotificationResponse;

                        switch (not.type) {
                            case SystemNotificationType.Info:
                                {
                                    message.info(not.message, 10);
                                    break;
                                }
                            case SystemNotificationType.Error:
                                {
                                    message.error(not.message, 10);
                                    break;
                                }
                            case SystemNotificationType.Success:
                                {
                                    message.success(not.message, 10);
                                    break;
                                }
                        }

                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return (
        <div className="App">
            <Compose
                components={[
                    BrowserRouter,
                    LoaderContextProvider,
                    UserContextProvider,
                ]}
            >
                <Header />

                <Routing />
            </Compose>

            <CookiesAgree />
        </div>
    );
}

export default App;
