import './Purchase.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle, PhotoUploader, PhotoList, PhotoItemProps, BackTo } from '../../components';
import { Button, Form, Input, List, message, Popconfirm, Select, Space } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, PurchaseCategoryResponse, PurchaseUpdateContract } from '../../api';
import { useTranslation } from 'react-i18next';
import { PurchaseDetailItemProp, PurchaseDetailProp } from './Purchase.model';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { PurchaseItemCreate } from './components/PurchaseItemCreate';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DATE_TIME_LOCAL_FORMAT } from '../../consts';
import { PurchaseItemDescription } from './PurchaseItemDescription';

export const Purchase: FC = React.memo(() => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const urlParams = useParams();
    const [form] = Form.useForm()
    const [purchaseData, setPurchaseData] = useState<PurchaseDetailProp>()
    const [purchaseItemData, setPurchaseItemData] = useState<PurchaseDetailItemProp>()
    const [purchaseCategoryData, setPurchaseCategoryData] = useState<PurchaseCategoryResponse[]>([]);

    const { setLoaderState } = useContext(LoaderContext)

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);

            const response = await Api.get<PurchaseDetailProp>(`purchases/${urlParams.id}`)

            if (!response.ok || !response.result)
                return

            setPurchaseData(response.result);

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState, urlParams])

    const handleFinish = useCallback(
        async (values: Partial<PurchaseUpdateContract>) => {

            setLoaderState(true)

            const response = await Api.put<PurchaseUpdateContract, PurchaseDetailProp>(`purchases/${purchaseData?.id}`, values as PurchaseUpdateContract)

            setLoaderState(false)

            if (!response.ok || response.result === undefined)
                return;

            setPurchaseData(response.result)
        },
        [purchaseData, setLoaderState]
    )

    const onDeleteReceipt = async (id: string) => {

        try {
            setLoaderState(true);

            const response = await Api.delete(`purchases/${urlParams?.id}/receipts/${id}`);

            if (!response)
                return;

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }

        await dataFetch(); // TODO without reload;
    }

    const dataFetchPurchaseCategories = useCallback(async () => {

        setLoaderState(true)

        const response = await Api.get<PurchaseCategoryResponse[]>(`purchase-categories`);

        setLoaderState(false)

        if (!response.ok || response.result === null)
            return

        setPurchaseCategoryData(response.result.sort((a, b) => ('' + a.name).localeCompare(b.name)) ?? []);

    }, [setLoaderState])

    const onPurchaseCategoryChange = (value: string) => {
        form.setFieldsValue({ purchaseCategoryId: value });
    };

    const onAddItem = useCallback(async (item: PurchaseDetailItemProp) => {
        try {
            setLoaderState(true);

            const response = item.id !== undefined
                ? await Api.put<PurchaseDetailItemProp, PurchaseDetailItemProp>(`purchases/${urlParams.id}/items/${item.id}`, item)
                : await Api.post<PurchaseDetailItemProp, PurchaseDetailItemProp>(`purchases/${urlParams.id}/items`, item);

            if (!response.ok || !response.result)
                return

            setPurchaseData(a => {
                if (!a)
                    return;

                a.purchaseItems = item.id !== undefined
                    ? [response.result, ...a.purchaseItems.filter(i => i.id !== item.id)]
                    : [response.result, ...a.purchaseItems];

                return a;
            });

            setPurchaseItemData(undefined);

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState, urlParams])

    const onDeleteItem = async (itemId: string) => {

        try {
            setLoaderState(true);

            const response = await Api.deleteWithoutResult(`purchases/${urlParams?.id}/items/${itemId}`);

            if (!response.ok) {
                message.error(response.exceptionResult?.Message ?? '')

                return;
            }

            setPurchaseData(a => {
                if (!a)
                    return;

                a.purchaseItems = a.purchaseItems.filter(a => a.id !== itemId);

                return a;
            })

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }

    useEffect(() => {

        const cd = purchaseData?.createDate !== undefined
            ? moment(purchaseData?.createDate).format(DATE_TIME_LOCAL_FORMAT)
            : undefined;

        form.setFieldsValue({ ...purchaseData, purchaseCategoryId: purchaseData?.purchaseCategory?.id, createDate: cd } as PurchaseUpdateContract);
    }, [purchaseData, form])

    useEffect(() => {
        dataFetchPurchaseCategories()
        dataFetch()
    }, [dataFetch, dataFetchPurchaseCategories])

    const Option = Select.Option;

    return (
        <PageContent>

            <BackTo to="/purchases" text={t('purchase-categories.back_to_purchases')} />

            <ContentTitle title={t('purchase.title')} />

            <Form
                className="content-element"
                name="purchaseUpdateForm"
                initialValues={purchaseData}
                onFinish={handleFinish}
                form={form}
            >
                <Form.Item
                    label={t('purchase.amount')}
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: tt('field_required'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label={t('purchase.createDate')} name="createDate">
                    <Input type="datetime-local" />
                </Form.Item>

                <Form.Item
                    label={t('purchases.purchase_category')}
                    name="purchaseCategoryId"
                >
                    <Select
                        placeholder={t('purchases.purchase_category')}
                        onChange={onPurchaseCategoryChange}
                        allowClear
                    >
                        {
                            purchaseCategoryData.map(a => <Option key={a.id} value={a.id}>{a.name}</Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item label={t('purchase.description')} name="description">
                    <TextArea rows={4} placeholder={tt('purchase.description')} />
                </Form.Item>

                <Form.Item >
                    <Button
                        form="purchaseUpdateForm"
                        type="primary"
                        htmlType="submit"
                    >
                        {t('save')}
                    </Button>
                </Form.Item>
            </Form>

            <PurchaseItemCreate
                onAdd={onAddItem}
                onCancel={() => setPurchaseItemData(undefined)}
                purchaseItem={purchaseItemData}
                purchaseCategoryData={purchaseCategoryData}
            />

            <List
                className="purchases"
                bordered
                dataSource={purchaseData?.purchaseItems}
                renderItem={item => (
                    <List.Item
                        key={item.id}
                        actions={[
                            <Space>
                                <EditOutlined onClick={() => setPurchaseItemData(item)} />
                                <Popconfirm
                                    title={t('delete')}
                                    onConfirm={() => onDeleteItem(item.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}>
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Space>]}
                    >
                        <List.Item.Meta
                            title={item.name}
                            description=
                            {<>
                                <PurchaseItemDescription title='count' value={item.count} />
                                <PurchaseItemDescription title='cost' value={item.cost} />
                                <PurchaseItemDescription title='purchases.purchase_category' value={item.purchaseCategoryName} />
                            </>
                            }
                        />

                    </List.Item>
                )}
            />

            <ContentTitle title={t('purchase.receipt')} />

            <PhotoList photos={purchaseData?.purchaseReceipts?.map((item, index) => {
                const f: PhotoItemProps = {
                    fileName: item.fileName,
                    id: item.id,
                    originFileName: item.originFileName,
                    canDelete: true,
                };

                return f;
            }) || []}
                onDeletePhoto={onDeleteReceipt}
            />

            <PhotoUploader url={`purchases/${urlParams?.id ?? ''}/receipts`} uploadCallback={dataFetch} />
        </PageContent>
    )
})
