import React, { FC, useCallback, useEffect } from 'react'
import { Button, Checkbox, Form, Input, InputNumber, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { BudgetCurrentRequest } from '../../../api';


export interface CurrentFormProps {
    data: any;
    setData: (value: BudgetCurrentRequest) => void;
    onFinish: (value: Partial<BudgetCurrentRequest>) => Promise<void>;
}

export const CurrentForm: FC<CurrentFormProps> = React.memo(({ data, setData, onFinish}) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const [form] = Form.useForm();
    const isCreditCard = Form.useWatch(`isCreditCard`, form);

    const onCancel = useCallback(
        async (values: any) => {
            setData({} as BudgetCurrentRequest);
        },
        [setData]
    )

    useEffect(() => {
        form.resetFields();
    }, [data, form])

    return (
        <Form
            className="content-element"
            name="currentUpdateForm"
            initialValues={data || {}}
            onFinish={onFinish}
            form={form}
        >
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>

            <Form.Item
                label={t('budget.title')}
                name="title"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('budget.amount')}
                name="amount"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <InputNumber type="number" />
            </Form.Item>

            <Form.Item
                label={t('budget.notCalculate')}
                name="notCalculate"
                valuePropName="checked"
                tooltip={t('budget.notCalculateTooltip')}
            >
                <Checkbox />
            </Form.Item>

            <Form.Item
                label={t('budget.isCreditCard')}
                name="isCreditCard"
                valuePropName="checked"
            >
                <Checkbox />
            </Form.Item>

            {isCreditCard &&
                <Form.Item
                    label={t('budget.creditCardLimit')}
                    name="creditCardLimit"
                >
                    <InputNumber type="number" />
                </Form.Item>
            }

            <Form.Item
                label={t('budget.last4DigitsOfCard')}
                name="last4DigitsOfCard"
                tooltip={t('budget.last4DigitsOfCardTooltip')}
            >
                <Input maxLength={4} />
            </Form.Item>

            <Form.Item >
                <Space>
                    <Button
                        form="currentUpdateForm"
                        type="primary"
                        htmlType="submit"
                    >
                        {data?.id === undefined ? t('add') : t('save')}
                    </Button>
                    {data?.id !== undefined && (<Button htmlType="button" className="button" onClick={onCancel}>
                        {t('cancel')}
                    </Button>)}
                </Space>
            </Form.Item>
        </Form>
    )
})
