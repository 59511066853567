import './Friends.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle, Content, ContentGroup } from '../../components';
import { Button, List, Modal, Popconfirm, Space } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, FamilyContract } from '../../api';
import { useTranslation } from 'react-i18next';
import { FriendsProp } from './Friends.model';
import { DeleteOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import { QrReader } from 'react-qr-reader';

export const Friends: FC = React.memo(() => {
    const { t } = useTranslation();
    
    const [friendsData, setFriendsData] = useState<FriendsProp[]>([])
    const [familyData, setFamilyData] = useState<FamilyContract>()
    const { setLoaderState } = useContext(LoaderContext)
    const [modalScanData, setModalScanData] = useState<boolean>(false)

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);


            const response = await Api.get<FriendsProp[]>(`friends`)

            if (!response.ok || !response.result)
                return

            setFriendsData(response.result);

            const responseFamily = await Api.get<FamilyContract>(`family`)

            if (!responseFamily.result)
                return

            setFamilyData(responseFamily.result)

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    const onDelete = (id: string) => {

        setLoaderState(true)

        var result = Api.delete(`friends/${id}`)

        setLoaderState(false)

        if (!result)
            return;

        setFriendsData(items => items.filter(a => a.familyId !== id))
    }

    const handleScan = async (data: any) => {
        if (data === null)
            return

        const obj = JSON.parse(data)

        const request = { familyId: obj.Id, password: obj.Password }

        const result = await Api.postWithoutResponse(`friends`, request)

        setModalScanData(false)

        if (result) {
            Modal.success({
                content: t('friends.scan_success'),
            });
        }
        else {
            Modal.error({
                title: t('error_message'),
                content: t('something_went_wrong'),
            });
        }
    }

    const handleError = (err: any) => {
        console.error(err)
    }

    useEffect(() => {
        dataFetch()
    }, [dataFetch])

    return (
        <PageContent>

            <ContentTitle title={t('friends.title')} />

            <Content>
                <List
                    className="friends"
                    bordered
                    dataSource={friendsData}
                    renderItem={item => (
                        <List.Item
                            key={item.familyId}
                            actions={[
                                <Space>
                                    <Popconfirm
                                        title={t('product_list.delete_text')}
                                        onConfirm={() => onDelete(item.familyId)}
                                        okText={t('yes')}
                                        cancelText={t('no')}>
                                        <DeleteOutlined />
                                    </Popconfirm>
                                </Space>

                            ]}
                        >
                            <List.Item.Meta
                                title={item.surname}
                            />

                        </List.Item>
                    )}
                />
            </Content>

            <Content>
                <ContentGroup title={t('friends.qr')}>
                    <QRCode
                        renderAs="svg"
                        size={200}
                        value={JSON.stringify({ Id: familyData?.id, Password: familyData?.password })} />
                </ContentGroup>

                <ContentGroup title={t('friends.scan_qr')}>
                    <Button type="link" onClick={() => setModalScanData(true)}>{t('scan_qr')}</Button>
                </ContentGroup>

                <Modal
                    title={t('scan_qr')}
                    open={modalScanData}
                    onOk={() => { setModalScanData(false) }}
                    onCancel={() => { setModalScanData(false) }}
                >
                    <QrReader
                        constraints={{ facingMode: 'user' }}
                        onResult={(result, error) => {
                            if (!!result) {
                                handleScan(result?.getText());
                            }

                            if (!!error) {
                                handleError(error);
                            }
                        }}
                    />
                </Modal>

            </Content>
           
        </PageContent>
    )
})
