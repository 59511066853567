import './Logon.scss'

import React, { useContext } from 'react'
import { UserContext } from '../../../../contexts';
import { NavLink } from 'react-router-dom';
import { Login } from './Login';

export const Logon: React.FC = React.memo(() => {
    const userData = useContext(UserContext)

    return (
        <div className="logon">
            {userData.isAutorized
                ? (<NavLink to="/profile" className="link">
                    {userData.userName} ({userData.loginProviderName})
                   </NavLink>)
                : (<Login/>)}

        </div>
    )
})
