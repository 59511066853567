import './Routing.scss'

import React, { FC } from 'react'

import { Route, Routes } from 'react-router-dom'

import {
    Home, ProductLists, Profile, Products, Events, Event, Error,
    Purchases, Purchase, Friends, Budget, NotFound, SignIn, PurchaseCategories, Help, ProductCategories
} from '../pages'

import { DiscountCards } from '../pages/DiscountCards/DiscountCards'; //Crutch ХЗ что такое
import { SharedList } from '../pages/SharedList';
import { Article } from '../pages/Help/Article';

export const Routing: FC = React.memo(() => {

    const contentStyles = 'content scroll';

    return (
        <div className={contentStyles} id="scrolling_div">
            <div className="container">
                <Routes>

                    <Route path='/' element={<Home />} />

                    <Route path='/product-lists/:productListId/products' element={<Products />} />

                    <Route path='/product-lists' element={<ProductLists />} />

                    <Route path='/profile' element={<Profile />} />

                    <Route path='/events/:id/' element={<Event /> } />
                        
                    <Route path='/events' element={<Events />} />

                    <Route path='/purchases/:id/' element={<Purchase /> } />

                    <Route path='/purchases' element={<Purchases />} />

                    <Route path='/budgets' element={<Budget />} />
                        
                    <Route path='/friends' element={<Friends />} />

                    <Route path='/signin' element={<SignIn />} />

                    <Route path='/discount-cards' element={<DiscountCards />} />

                    <Route path='/product-categories' element={<ProductCategories />} />

                    <Route path='/purchase-categories' element={<PurchaseCategories />} />

                    <Route path='/shared-list/:shopId/:editKey' element={<SharedList />} />

                    <Route path='/help/:id' element={<Article />} />

                    <Route path='/help' element={<Help />} />

                    <Route path='/error' element={<Error />} />

                    <Route path='*' element={<NotFound />} />
                        
                </Routes>
            </div>
            <footer className="footer">
                <div className="container">
                    <div className="copyright-block">
                        Copyright © 2020 - {new Date().getFullYear()}
                    </div>
                    <div className="email-block">
                        <a href="mailto:familymattersru@yandex.ru">Задавайте вопросы и предлагайте</a>
                    </div>
                </div>
            </footer>
        </div>
    )
})
