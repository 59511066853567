import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Form, InputNumber, Modal, Select } from 'antd';
import { BudgetResponse, BudgetType, TransferCurrentSumRequest } from '../../../api';
import { useTranslation } from 'react-i18next';

export interface TransferCurrentSumModalProps {
    budgetResponse: BudgetResponse;
    budgetData: BudgetResponse[];
    onCancel: () => void;
    onFinish: (value: TransferCurrentSumRequest) => void;
}

export const TransferCurrentSumModal: FC<TransferCurrentSumModalProps> = React.memo(({ budgetResponse, budgetData, onCancel, onFinish }) => {
    const { t } = useTranslation();

    const [form] = Form.useForm<TransferCurrentSumRequest>();

    const { Option } = Select;

    const [currents, setCurrents] = useState<BudgetResponse[]>([]);

    const formatTitle = (item: BudgetResponse): string => `${item.title}: ${item.amount}`;

    const setSource = useCallback((value: string): void => {
        form.setFieldsValue({ sourceCurrentSumId: value });
    }, [form]);

    const setDestination = useCallback((value: string): void => {
        form.setFieldsValue({ destinationCurrentSumId: value });
    }, [form]);

    useEffect(() => {
        setCurrents(budgetData.filter(a => a.budgetType === BudgetType.Current));
    }, [budgetData])

    useEffect(() => {
        setSource(budgetResponse.id);
    }, [budgetResponse, setSource])

    return (
        <>
            <Modal
                title={t('budget.transferModal.title')}
                open={true}
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        {t('cancel')}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    onFinish(values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        {t('ok')}
                    </Button>
                ]} >
                <Form
                    name="transferCurrentSumForm"
                    initialValues={budgetResponse || {}}
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        label={t('budget.transferModal.source')}
                        name="sourceCurrentSumId"
                    >
                        <Select
                            onChange={(a) => { setSource(a) } }
                            allowClear
                        >
                            {
                                currents.map(a => <Option key={a.id} value={a.id}>{formatTitle(a)}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('budget.transferModal.destination')}
                        name="destinationCurrentSumId"
                    >
                        <Select
                            onChange={(a) => setDestination(a)}
                            allowClear
                        >
                            {
                                currents.map(a => <Option key={a.id} value={a.id}>{formatTitle(a)}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('amount')}
                        name="amount"
                    >
                        <InputNumber type="number" />
                    </Form.Item>

                </Form>
            </Modal>

        </>
    )
})
