import './ProductLists.scss'

import React, {
    FC,
    useContext,
} from 'react'

import { UserContext } from '../../contexts';
import { ProductListsAuthorized, ProductListsUnauthorized } from './components';

export const ProductLists: FC = React.memo(() => {

    const userData = useContext(UserContext)

    return (<>
        {userData.isAutorized && <ProductListsAuthorized />}
        {!userData.isAutorized && <ProductListsUnauthorized />}
    </>
    )
})
