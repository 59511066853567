import './AddDevice.scss'

import React, { FC, useCallback, useEffect } from 'react'
import { Form, Input, Button, Space } from 'antd'
import { AddDeviceProps } from './AddDevice.model';
import { AddDeviceContract } from '../../../api';
import { useTranslation } from 'react-i18next';

export const AddDevice: React.FC<AddDeviceProps> = React.memo(({ onAddDevice }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [form] = Form.useForm()

    const handleFinish = useCallback(
        (values: Partial<AddDeviceContract>) => {
            onAddDevice(values as AddDeviceContract)

            form.resetFields();
        },
        [form, onAddDevice]
    )

    useEffect(() => {
        form.resetFields()
    }, [form])

    return (
        <Form
            className="form"
            name="addDeviceForm"
            onFinish={handleFinish}
            form={form}
        >
            <Form.Item
                label={t('profile.add_device.id')}
                name="id"
                rules={[
                    {
                        required: true,
                        message: tt('profile.add_device.id_required_message'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('profile.add_device.password')}
                name="password"
                rules={[
                    {
                        required: true,
                        message: tt('profile.add_device.password_required_message'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item >
                <Space>
                    <Button
                        form="addDeviceForm"
                        type="primary"
                        htmlType="submit"
                        className="button"
                    >
                        <>{t('profile.add_device.bt_add')}</>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
})
