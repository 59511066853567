import React, { FC, useCallback, useEffect } from 'react'
import { Button, Checkbox, Form, Input, InputNumber, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { BudgetStaticRequest, PurchaseCategoryResponse, StaticSumPeriodType } from '../../../api';
import { NavLink } from 'react-router-dom';


export interface StaticFormProps {
    data: any;
    setData: (value: BudgetStaticRequest) => void;
    onFinish: (value: Partial<BudgetStaticRequest>) => Promise<void>;
    purchaseCategories: PurchaseCategoryResponse[];
}

export const StaticForm: FC<StaticFormProps> = React.memo(({ data, setData, onFinish, purchaseCategories }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const [form] = Form.useForm();

    const onGenderPeriodTypeChange = (value: string) => {
        const v: number = +value;
        setData({ ...data, staticSumPeriodType: v } as BudgetStaticRequest);
    };

    const { Option } = Select;

    const onCancel = useCallback(
        async (values: any) => {
            setData({ staticSumPeriodType: StaticSumPeriodType.PerDay } as BudgetStaticRequest);
        },
        [setData]
    )

    const onPurchaseCategoryChange = (value: string) => {
        form.setFieldsValue({ purchaseCategoryId: value });
    };

    const onMonthOfYearChange = (value: string) => {
        form.setFieldsValue({ monthOfYear: value });
    };

    useEffect(() => {
        form.resetFields();
    }, [data, form])

    return (
        <Form
            className="content-element"
            name="staticUpdateForm"
            initialValues={data || {}}
            onFinish={onFinish}
            form={form}
        >
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>

            <Form.Item
                name="staticSumPeriodType"
                label={t('budget.period')}
                rules={[{ required: true }]}>
                <Select
                    placeholder={t('budget.period')}
                    value={StaticSumPeriodType[data.staticSumPeriodType]}
                    onChange={onGenderPeriodTypeChange}
                >
                    <Option value={StaticSumPeriodType.PerDay}>{t('budget.staticSumPeriodType.PerDay')}</Option>
                    <Option value={StaticSumPeriodType.PerMonth}>{t('budget.staticSumPeriodType.PerMonth')}</Option>
                    <Option value={StaticSumPeriodType.PerYear}>{t('budget.staticSumPeriodType.PerYear')}</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label={t('budget.title')}
                name="title"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('budget.amount')}
                name="amount"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <InputNumber type="number" />
            </Form.Item>

            {(data.staticSumPeriodType === StaticSumPeriodType.PerMonth
                || data.staticSumPeriodType === StaticSumPeriodType.PerYear)
                && < Form.Item
                label={t('budget.dayOfMonth')}
                name="dayOfMonth"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <InputNumber />
            </Form.Item>}

            {data.staticSumPeriodType === StaticSumPeriodType.PerYear && < Form.Item
                label={t('budget.monthOfYear')}
                name="monthOfYear"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <Select
                    placeholder={t('budget.monthOfYear')}
                    value={StaticSumPeriodType[data.staticSumPeriodType]}
                    onChange={onMonthOfYearChange}
                >
                    <Option value={1}>{t('monthNames.January')}</Option>
                    <Option value={2}>{t('monthNames.February')}</Option>
                    <Option value={3}>{t('monthNames.March')}</Option>
                    <Option value={4}>{t('monthNames.April')}</Option>
                    <Option value={5}>{t('monthNames.May')}</Option>
                    <Option value={6}>{t('monthNames.June')}</Option>
                    <Option value={7}>{t('monthNames.July')}</Option>
                    <Option value={8}>{t('monthNames.August')}</Option>
                    <Option value={9}>{t('monthNames.September')}</Option>
                    <Option value={10}>{t('monthNames.October')}</Option>
                    <Option value={11}>{t('monthNames.November')}</Option>
                    <Option value={12}>{t('monthNames.December')}</Option>
                </Select>
            </Form.Item>}

            <Form.Item
                label={<NavLink to={`/purchase-categories`} className="link">
                    {t('purchases.purchase_category')}
                </NavLink>}
                name="purchaseCategoryId"
            >
                <Select
                    placeholder={t('purchases.purchase_category')}
                    onChange={onPurchaseCategoryChange}
                    allowClear
                >
                    {
                        purchaseCategories.map(a => <Option key={a.id} value={a.id}>{a.name}</Option>)
                    }
                </Select>
            </Form.Item>

            <Form.Item
                name="notRequired"
                valuePropName="checked"
                label={t('budget.notRequired')}
                tooltip={t('budget.notRequiredTooltip')}
            >
                <Checkbox />
            </Form.Item>

            <Form.Item >
                <Space>
                    <Button
                        form="staticUpdateForm"
                        type="primary"
                        htmlType="submit"
                    >
                        {data?.id === undefined ? t('add') : t('save')}
                    </Button>
                    {data?.id !== undefined && (<Button htmlType="button" className="button" onClick={onCancel}>
                        {t('cancel')}
                    </Button>)}
                </Space>
            </Form.Item>
        </Form>
    )
})
