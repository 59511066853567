import React, { useMemo } from 'react'
import { Table, Tag, Typography } from 'antd'
import { useTranslation } from 'react-i18next';
import { PurchasesProp } from '../Purchases.model';
import { ColumnsType } from 'antd/lib/table';
import { ShortDateTime } from '../../../components';
import { NavLink } from 'react-router-dom';

export interface GroupTableDataProps {
    purchasesData: PurchasesProp[],
}

interface DataType {
    key: React.ReactNode;
    name: JSX.Element;
    amount: number;
    hasReceipt: JSX.Element,
    createDate: JSX.Element,
    actions: JSX.Element;
    children?: DataType[];
}

interface DataType2 {
    purchaseItemId: string,
    purchaseId: string,
    purchaseCategoryId: string,
    purchaseItemCategoryId: string,
    name: string;
    cost: number;
    purchaseCategoryName: string,
    createDate: Date,
    hasReceipt: boolean,
}

const groupBy2 = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);

export const GroupTableData: React.FC<GroupTableDataProps> = React.memo(({ purchasesData }) => {
    const { t } = useTranslation();

    const { Text } = Typography;

    const columns: ColumnsType<DataType> = [
        {
            title: t('purchases.item_names'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('purchase.amount'),
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: t('purchases.has_receipt'),
            dataIndex: 'hasReceipt',
            key: 'hasReceipt',
            responsive: ["sm"]
        },
        {
            title: t('purchase.createDate'),
            dataIndex: 'createDate',
            key: 'createDate',
            responsive: ["sm"]
        },
        {
            title: t('actions'),
            dataIndex: 'actions',
            key: 'actions',
        },
    ];

    const data = useMemo(() => {

        const set = new Set<DataType2>();

        purchasesData.forEach(p => {

            let costUsed = false;

            p.purchaseItems.forEach(pi => {

                var cost = 0;

                if (p.purchaseItems.length === 1) {
                    cost = p.amount ?? 0;
                } else {
                    var amountOther = p.purchaseItems.filter(a => a.purchaseCategoryId !== p.purchaseCategory?.id)
                        .reduce((sum, current) => sum + (current.cost ?? 0), 0);

                    if (!pi.purchaseCategoryId
                        && !costUsed) {
                        costUsed = true;
                        cost = (p.amount ?? 0) - amountOther;
                    }
                    else {
                        cost = pi.cost ?? 0;
                    }
                }

                set.add({
                    purchaseItemId: pi.id,
                    purchaseId: p.id,
                    purchaseItemCategoryId: pi.purchaseCategoryId,
                    purchaseCategoryId: p.purchaseCategory?.id,
                    name: pi.name,
                    cost: cost,
                    purchaseCategoryName: pi.purchaseCategoryName ?? p.purchaseCategory?.name,
                    createDate: p.createDate,
                    hasReceipt: p.hasReceipt
                } as DataType2);
            });
        })

        const list = Array.from(set);

        return Object.entries(groupBy2(list, t => t.purchaseCategoryName ?? ''))
            .sort((a, b) => (a[0]).localeCompare(b[0]))
            .map(a => (
                {
                    key: a[0],
                    name: <Text strong>{a[0]}</Text>,
                    amount: a[1].reduce((sum, current) => sum + current.cost, 0),
                    children: a[1].map(x => (
                        {
                            key: x.purchaseItemId,
                            amount: x.cost,
                            name: <NavLink to={`/purchases/${x.purchaseId}/`} key={x.purchaseId} className="link">{x.name}</NavLink>,
                            createDate: <ShortDateTime date={x.createDate} />,
                            hasReceipt: x.hasReceipt ? (<Tag color="green" key={x.purchaseItemId}>{t('purchases.has_receipt')}</Tag>) : (<></>),
                        } as DataType))
                        .sort((x1, x2) => (x2.createDate?.toLocaleString()).localeCompare(x1.createDate?.toLocaleString()))
                } as DataType));

    }, [purchasesData, Text, t]);

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
        />
    )
})
