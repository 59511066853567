import './DateText.scss'

import React, { FC } from 'react'
import { Space, Typography } from 'antd';


export interface DateTextProps {
    date: Date;
    formatedDate: string;
    children?: React.ReactNode;
}

export const DateText: FC<DateTextProps> = React.memo(({ formatedDate, date, children }) => {
    const { Text } = Typography;

    if (formatedDate === '')
        return (<Text type="secondary">{children}</Text>);

    const isDayOff = (dt: Date) => {
        const d = new Date(dt).getDay();

        return (d === 0 || d === 6);
    }

    return (
        <Space direction="horizontal">
            <Text strong className={isDayOff(date) ? 'day_off' : ''}>{formatedDate}</Text>
            <Text type="secondary">{children}</Text>
        </Space>
    )
})
