import './Events.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle } from '../../components';
import { NavLink } from 'react-router-dom';
import { List, Popconfirm, Button, Avatar } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, EventContract, EventCreateContract } from '../../api';
import { useTranslation } from 'react-i18next';
import { EventCreate } from './components/EventCreate';

export const Events: FC = React.memo(() => {
    const { t } = useTranslation();
    const [eventsData, setEventsData] = useState<EventContract[]>([])
    const createNewEvent = () => {
        const now = new Date();
        const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDay(), now.getHours(), now.getMinutes(), 0, 0);
        return { name: '', date: nowDate } as EventCreateContract
    }
    const [editEventData, setEditEventData] = useState<EventCreateContract>(createNewEvent())
    const [loadMoreState, setLoadMoreState] = useState<boolean>(false)
    const { setLoaderState } = useContext(LoaderContext)
    const countLoadItems = 10;
    const dataFetch = useCallback(async (skip: number) => {
        try {
            setLoaderState(true);

            const response = await Api.get<EventContract[]>(`events?Take=${countLoadItems}&Skip=${skip}`)

            if (!response.ok || !response.result)
                return

            setLoadMoreState(response.result.length === countLoadItems)
            setEventsData(prev => prev.concat(response.result))

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    useEffect(() => {
        dataFetch(0)
    }, [dataFetch])

    const onDelete = (id: string) => {
        setLoaderState(true)

        var result = Api.delete(`events/${id}`)

        setLoaderState(false)

        if (!result)
            return;

        setEventsData(items => items.filter(a => a.id !== id))
    }

    const onAddHandler = async (event: EventCreateContract) => {

        setLoaderState(true)

        const result = await Api.post<EventCreateContract, EventContract>(`events`, event)

        setLoaderState(false)

        if (!result.ok || result.result === undefined)
            return;

        setEventsData(prev => [result.result, ...prev]);

        setEditEventData(createNewEvent())
    }

    return (
        <PageContent>

            <ContentTitle title={t('events.title')} />

            <EventCreate onAddEvent={onAddHandler} eventCreate={editEventData}/>

            <List
                className="event-list"
                bordered
                dataSource={eventsData}
                loadMore={loadMoreState && <div className="load-more"><Button type="link" onClick={() => dataFetch(eventsData.length)}>{t('load_more')}</Button></div>}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Popconfirm
                                title={t('events.delete_confirm_text')}
                                onConfirm={() => onDelete(item.id)}
                                okText={t('yes')}
                                cancelText={t('no')}>
                                <Button type="link">{t('delete')}</Button>
                            </Popconfirm>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <NavLink to={`/events/${item.id}/`} className="link">
                                    <Avatar src={item.titleIconFileName ?? item.titlePhoto?.fileName} />
                                </NavLink>
                            }
                            title={<NavLink to={`/events/${item.id}/`} className="link">
                                {item.name}
                            </NavLink>}
                            description={
                                <NavLink to={`/events/${item.id}/`} style={{ color: 'black' }}>
                                {item.date}
                            </NavLink>}
                        />

                    </List.Item>
                )}
            />
        </PageContent>
    )
})
