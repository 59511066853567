import './Content.scss'

import React from 'react'

import { ContentProps } from './Content.model'

export const Content: React.FC<ContentProps> = React.memo(
    ({ children }) => (
        <div className="content-element">
            {children}
        </div>
    )
)
