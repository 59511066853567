import './Logon.scss'

import React from 'react'
import { API_BASE_URL, FRONT_BASE_URL } from '../../../../consts';
import { LoginElementProps } from './LoginElement.model';
import { useTranslation } from 'react-i18next';

export const LoginElement: React.FC<LoginElementProps> = React.memo(({ name, scheme }) => {
    const { t } = useTranslation();

    return (
        <a href={`${API_BASE_URL}/api/v1/auth/${scheme}?channel=1&redirect_uri=${FRONT_BASE_URL}/`} title={`${t('header.logon.loginWith')} ${scheme}`}>{name}</a>
    )
})
