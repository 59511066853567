import './ProductListsAuthorized.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle } from '../../../components';
import { NavLink } from 'react-router-dom';
import { message, Popconfirm, Space, Table, Tag, Typography } from 'antd';
import { LoaderContext } from '../../../contexts';
import { Api, ProductListContract, ProductListRequest, PurchaseCategoryResponse } from '../../../api';
import { ProductListCreate } from './ProductListCreate';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';

export const ProductListsAuthorized: FC = React.memo(() => {
    const { t } = useTranslation();
    const createNewProductList = () => { return { name: '' } as ProductListContract }

    const [productListsData, setProductListsData] = useState<ProductListContract[]>([])
    const [editProductListData, setEditProductListData] = useState<ProductListContract>(createNewProductList())
    const [purchaseCategoryData, setPurchaseCategoryData] = useState<PurchaseCategoryResponse[]>([]);
    const { setLoaderState } = useContext(LoaderContext)

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);

            const response = await Api.get<ProductListContract[]>(`productlists`)

            if (!response.ok || !response.result)
                return

            setProductListsData([{ id: 'all', name: t('product_list.all') } as ProductListContract, ...response.result])

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState, t])

    const dataFetchPurchaseCategories = useCallback(async () => {

        setLoaderState(true)

        const response = await Api.get<PurchaseCategoryResponse[]>(`purchase-categories`);

        setLoaderState(false)

        if (!response.ok || response.result === null)
            return

        setPurchaseCategoryData(response.result.sort((a, b) => ('' + a.name).localeCompare(b.name)) ?? []);

    }, [setLoaderState, setPurchaseCategoryData])

    useEffect(() => {
        dataFetch();
        dataFetchPurchaseCategories();
    }, [dataFetch, dataFetchPurchaseCategories])

    const onAddHandler = async (productList: ProductListRequest) => {

        setLoaderState(true)

        const result = productList.id !== undefined
            ? await Api.put<ProductListRequest, ProductListContract>(`productlists/${productList.id}`, productList)
            : await Api.post<ProductListRequest, ProductListContract>(`productlists`, productList)

        setLoaderState(false)

        if (!result.ok || result.result === undefined)
            return;

        setProductListsData(prev => [...prev.filter(a => a.id !== result.result.id), result.result])

        setEditProductListData(createNewProductList())
    }

    const onCancelAddHandler = () => setEditProductListData(createNewProductList());

    const makeDefault = async (productListId: string) => {

        setLoaderState(true)

        const result = await Api.patchWithoutRequestBody<ProductListContract>(`productlists/${productListId}/make-default`)

        setLoaderState(false)

        if (!result.ok || result.result === undefined)
            return;

        dataFetch(); // crutch because other product list made not default
    }

    const onDelete = async (id: string) => {

        setLoaderState(true)

        var result = await Api.deleteWithoutResult(`productlists/${id}`)

        setLoaderState(false)

        if (!result.ok)
        {
            message.error(result.exceptionResult?.Message);
            return;
        }

        setProductListsData(items => items.filter(a => a.id !== id))
    }

    const { Text } = Typography;

    return (
        <PageContent>

            <ContentTitle title={t('product_list.title')} />

            <ProductListCreate productList={editProductListData}
                onAddProductList={onAddHandler}
                onCancelAddProductList={onCancelAddHandler}
                purchaseCategories={purchaseCategoryData}
            />

            <div className="products">
                <Table<ProductListContract> dataSource={productListsData}
                    pagination={false}
                    rowKey='id'
                >
                    <Column<ProductListContract>
                        title={t('product_list.create.name')}
                        dataIndex="name"
                        key="name"
                        render={(text, item) => (
                            <NavLink to={`/product-lists/${item.id}/products`} key={item.id} className="link">
                                {item.name}
                            </NavLink>
                        )}
                    />

                    <Column<ProductListContract> title={t('purchases.purchase_category')}
                        responsive={["sm", "md", "lg", "xl", "xxl"]}
                        dataIndex="defaultPurchaseCategoryName"
                        key="defaultPurchaseCategoryName"
                        render={(text, item) => (
                            <Text type="secondary">{item.defaultPurchaseCategoryName}</Text>
                        )}
                    />

                    <Column<ProductListContract> title={t('product_list.is_default')}
                        responsive={["sm", "md", "lg", "xl", "xxl"]}
                        key="isDefault"
                        render={(text, item) => (
                            <Text type="secondary">{item.isDefault && <Tag color="green">{t('product_list.is_default')}</Tag>}</Text>
                        )}
                    />

                    <Column<ProductListContract> title={t('purchases.purchase_category')}
                        responsive={["xs"]}
                        dataIndex="defaultPurchaseCategoryName"
                        key="defaultPurchaseCategoryName"
                        render={(text, item) => (
                            <Space direction='vertical'>
                                <Text type="secondary">{item.defaultPurchaseCategoryName}</Text>
                                <Text type="secondary">{item.isDefault && <Tag color="green">{t('product_list.is_default')}</Tag>}</Text>
                            </Space>
                        )}
                    />

                    <Column<ProductListContract>
                        title={t('actions')}
                        key="action"
                        render={(item) => item.id !== 'all' ? 
                            <Space>
                                {!item.isDefault && <CheckCircleOutlined onClick={() => makeDefault(item.id)} />}
                                <EditOutlined onClick={() => setEditProductListData(item)} />
                                <Popconfirm
                                    title={t('product_list.delete_text')}
                                    onConfirm={() => onDelete(item.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}>
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Space>

                         : <></>}
                    />
                </Table>
            </div>
        </PageContent>
    )
})
