import React, { FC, useCallback, useEffect } from 'react'
import { Button, Form, Input, InputNumber, Space, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { BudgetDynamicRequest, PurchaseCategoryResponse } from '../../../api';
import { NavLink } from 'react-router-dom';


export interface DynamicFormProps {
    data: any;
    setData: (value: BudgetDynamicRequest) => void;
    onFinish: (value: Partial<BudgetDynamicRequest>) => Promise<void>;
    purchaseCategories: PurchaseCategoryResponse[];
}

export const DynamicForm: FC<DynamicFormProps> = React.memo(({ data, setData, onFinish, purchaseCategories }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const [form] = Form.useForm();

    const onCancel = useCallback(
        async (values: any) => {
            setData({} as BudgetDynamicRequest);
        },
        [setData]
    )

    const onPurchaseCategoryChange = (value: string) => {
        form.setFieldsValue({ purchaseCategoryId: value });
    };

    useEffect(() => {
        form.resetFields();
    }, [data, form])

    const { Option } = Select;

    return (
        <Form
            className="content-element"
            name="dynamicUpdateForm"
            initialValues={data || {}}
            onFinish={onFinish}
            form={form}
        >
            <Form.Item name="id" hidden>
                <InputNumber />
            </Form.Item>

            <Form.Item
                label={t('budget.title')}
                name="title"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('budget.amount')}
                name="amount"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <InputNumber type="number" />
            </Form.Item>

            <Form.Item
                label={t('budget.date')}
                name="date"
                rules={[
                    {
                        required: true,
                        message: tt('field_required'),
                    },
                ]}
            >
                <Input type="datetime-local" />
            </Form.Item>

            <Form.Item
                label={<NavLink to={`/purchase-categories`} className="link">
                    {t('purchases.purchase_category')}
                </NavLink>}
                name="purchaseCategoryId"
            >
                <Select
                    placeholder={t('purchases.purchase_category')}
                    onChange={onPurchaseCategoryChange}
                    allowClear
                >
                    {
                        purchaseCategories.map(a => <Option key={a.id} value={a.id}>{a.name}</Option>)
                    }
                </Select>
            </Form.Item>

            <Form.Item
                name="notRequired"
                valuePropName="checked"
                label={t('budget.notRequired')}
                tooltip={t('budget.notRequiredTooltip')}
            >
                <Checkbox />
            </Form.Item>

            <Form.Item >
                <Space>
                    <Button
                        form="dynamicUpdateForm"
                        type="primary"
                        htmlType="submit"
                    >
                        {data?.id === undefined ? t('add') : t('save')}
                    </Button>
                    {data?.id !== undefined && (<Button htmlType="button" className="button" onClick={onCancel}>
                        {t('cancel')}
                    </Button>)}
                </Space>
            </Form.Item>
        </Form>
    )
})
