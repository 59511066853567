import './Budget.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle } from '../../components';
import { Form, Table, Tabs, Space, Popconfirm, message, Typography, Tag, Divider, Tooltip } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, BudgetCurrentRequest, BudgetDynamicRequest, BudgetResponse, BudgetStaticRequest, BudgetType, CorrectStaticSumRequest, DynamicSumDoneRequest, PurchaseCategoryResponse, StaticSumDoneRequest, StaticSumPeriodType, TransferCurrentSumRequest } from '../../api';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { CheckOutlined, DeleteOutlined, EditOutlined, UndoOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { DynamicDoneModal } from './Components/DynamicDoneModal';
import { CurrentForm } from './Components/CurrentForm';
import { DynamicForm } from './Components/DynamicForm';
import { StaticForm } from './Components/StaticForm';
import { StaticDoneModal } from './Components/StaticDoneModal';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import moment from 'moment';
import { DateText } from './Components/DateText';
import { CorrectStaticSumModal } from './Components/CorrectStaticSumModal';
import { TableCurrent } from './Components/TableCurrent';
import { TransferCurrentSumModal } from './Components/TransferCurrentSumModal';
import { BudgetTitle } from './Components/BudgetTitle';

export const Budget: FC = React.memo(() => {
    const { t } = useTranslation();

    const { Text } = Typography;

    const [formStatic] = Form.useForm();

    const [currentData, setCurrentData] = useState<BudgetCurrentRequest>();
    const [staticData, setStaticData] = useState<BudgetStaticRequest>({ staticSumPeriodType: StaticSumPeriodType.PerMonth } as BudgetStaticRequest);
    const [staticDoneData, setStaticDoneData] = useState<BudgetResponse>();
    const [correctStaticSum, setCorrectStaticSum] = useState<BudgetResponse>();
    const [dynamicData, setDynamicData] = useState<BudgetDynamicRequest>();
    const [dynamicDoneData, setDynamicDoneData] = useState<BudgetResponse>();
    const [transferCurrentSumData, setTransferCurrentSumData] = useState<BudgetResponse>();
    const [tabActiveKey, setTabActiveKey] = useState<string>("1");
    const [purchaseCategoryData, setPurchaseCategoryData] = useState<PurchaseCategoryResponse[]>([]);

    const [budgetData, setBudgetData] = useState<BudgetResponse[]>([])

    const { setLoaderState } = useContext(LoaderContext)

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);

            let response = await Api.get<BudgetResponse[]>(`budget`)

            if (response.ok && response.result) {
                setBudgetData(response.result);
            }
            else {
                response = await Api.post<BudgetResponse, BudgetResponse[]>(`budget`, {} as BudgetResponse);
                if (response.ok && response.result) {
                    setBudgetData(response.result);
                }
            }

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    const dataFetchPurchaseCategories = useCallback(async () => {

        setLoaderState(true)

        const response = await Api.get<PurchaseCategoryResponse[]>(`purchase-categories`);

        setLoaderState(false)

        if (!response.ok || response.result === null)
            return

        setPurchaseCategoryData(response.result.sort((a, b) => ('' + a.name).localeCompare(b.name)) ?? []);

    }, [setLoaderState])

    const onCurrentFinish = useCallback(
        async (values: Partial<BudgetCurrentRequest>) => {

            const budgetCurrentRequest = values as BudgetCurrentRequest;

            setLoaderState(true)

            const response = budgetCurrentRequest.id === undefined
                ? await Api.post<BudgetCurrentRequest, BudgetResponse[]>(`budget/current`, budgetCurrentRequest)
                : await Api.put<BudgetCurrentRequest, BudgetResponse[]>(`budget/current/${budgetCurrentRequest.id}`, budgetCurrentRequest);

            setLoaderState(false)

            if (!response.ok || response.result === undefined) {
                message.error(response?.exceptionResult?.Message);
                return;
            }

            setBudgetData(response.result);

            setCurrentData({} as BudgetCurrentRequest);
        },
        [setLoaderState]
    )

    const staticSumDoneHandle = useCallback(
        async (item: any) => {

            setLoaderState(true)

            const staticSumDoneRequest = {
                title: item.title,
                date: item.date,
                actualAmount: item.amount,
                currentSumId: item.currentSumId
            } as StaticSumDoneRequest;

            const response = await Api.patch<StaticSumDoneRequest, BudgetResponse[]>(`budget/static/${item.id}/done`, staticSumDoneRequest);

            setLoaderState(false)

            if (!response.ok || response.result === undefined)
                return;

            setBudgetData(response.result);

            setStaticDoneData(undefined);
        },
        [setLoaderState]
    )

    const correctStaticSumHandle = useCallback(
        async (budgetResponse: BudgetResponse) => {

            setLoaderState(true)

            const correctStaticSumRequest = {
                date: budgetResponse.date,
                amount: budgetResponse.amount
            } as CorrectStaticSumRequest;

            const response = await Api.patch<CorrectStaticSumRequest, BudgetResponse[]>(`budget/static/${budgetResponse.id}/correct`, correctStaticSumRequest);

            setLoaderState(false)

            if (!response.ok || response.result === undefined)
                return;

            setBudgetData(response.result);

            setCorrectStaticSum(undefined);
        },
        [setLoaderState]
    )

    const staticSumUndone = useCallback(
        async (budgetResponse: BudgetResponse) => {

            setLoaderState(true)

            const staticSumDoneRequest = { date: budgetResponse.date } as StaticSumDoneRequest;

            const response = await Api.patch<StaticSumDoneRequest, BudgetResponse[]>(`budget/static/${budgetResponse.id}/undone`, staticSumDoneRequest);

            setLoaderState(false)

            if (!response.ok || response.result === undefined)
                return;

            setBudgetData(response.result);
        },
        [setLoaderState]
    )

    const dynamicSumDone = useCallback(
        async (budgetResponse: BudgetResponse) => {
            setDynamicDoneData(budgetResponse);
        },
        []
    )

    const dynamicSumDoneHandle = useCallback(
        async (values: Partial<any>) => {
            setLoaderState(true);

            const budgetResponse = {
                amount: values.amount,
                title: values.title,
                currentSumId: values.currentSumId
            } as DynamicSumDoneRequest;

            const response = await Api.patch<DynamicSumDoneRequest, BudgetResponse[]>(`budget/dynamic/${values.id}/done`, budgetResponse);

            setLoaderState(false);

            if (!response.ok || response.result === undefined)
                return;

            setDynamicDoneData(undefined)
            setBudgetData(response.result);
        },
        [setLoaderState]
    )

    const dynamicSumUndone = useCallback(
        async (budgetResponse: BudgetResponse) => {

            setLoaderState(true)

            const response = await Api.patchWithoutRequestBody<BudgetResponse[]>(`budget/dynamic/${budgetResponse.id}/undone`);

            setLoaderState(false)

            if (!response.ok || response.result === undefined)
                return;

            setBudgetData(response.result);
        },
        [setLoaderState]
    )

    const onStaticFinish = useCallback(
        async (values: Partial<BudgetStaticRequest>) => {

            const budgetStaticRequest = values as BudgetStaticRequest;

            setLoaderState(true)

            const response = budgetStaticRequest.id === undefined
                ? await Api.post<BudgetStaticRequest, BudgetResponse[]>(`budget/static`, budgetStaticRequest)
                : await Api.put<BudgetStaticRequest, BudgetResponse[]>(`budget/static/${budgetStaticRequest.id}`, budgetStaticRequest);

            setLoaderState(false)

            if (!response.ok || response.result === undefined) {
                message.error(`${t('error_message')} ${response?.exceptionResult?.Message}`);
                return;
            }

            setBudgetData(response.result);

            setStaticData({ staticSumPeriodType: StaticSumPeriodType.PerMonth } as BudgetStaticRequest);

            formStatic.resetFields();
        },
        [formStatic, setLoaderState, t]
    )

    const onDynamicFinish = useCallback(
        async (values: Partial<BudgetDynamicRequest>) => {

            const budgetDynamicRequest = values as BudgetDynamicRequest;

            setLoaderState(true)

            const response = budgetDynamicRequest.id === undefined
                ? await Api.post<BudgetDynamicRequest, BudgetResponse[]>(`budget/dynamic`, budgetDynamicRequest)
                : await Api.put<BudgetDynamicRequest, BudgetResponse[]>(`budget/dynamic/${budgetDynamicRequest.id}`, budgetDynamicRequest);

            setLoaderState(false)

            if (!response.ok || response.result === undefined) {
                message.error(`${t('error_message')} ${response?.exceptionResult?.Message}`);
                return;
            }

            setBudgetData(response.result);

            setDynamicData({} as BudgetDynamicRequest);
        },
        [setLoaderState, t]
    )

    const onDelete = (id: string) => {

        setLoaderState(true)

        var result = Api.delete(`budget/sums/${id}`)

        setLoaderState(false)

        if (!result)
            return;

        setBudgetData(items => items.filter(a => a.id !== id))
    }

    const transferCurrentSumHandle = useCallback(
        async (values: Partial<any>) => {
            setLoaderState(true);

            const request = { ...values } as TransferCurrentSumRequest;

            const response = await Api.patch<TransferCurrentSumRequest, BudgetResponse[]>(`budget/transfer`, request);

            setLoaderState(false);

            if (!response.ok || response.result === undefined)
                return;

            setTransferCurrentSumData(undefined)
            setBudgetData(response.result);
        },
        [setLoaderState]
    )

    const confirmCurrentSum = useCallback(
        async (budgetResponse: BudgetResponse) => {

            setLoaderState(true)

            const response = await Api.patchWithoutRequestBody<BudgetResponse[]>(`budget/current/${budgetResponse.id}/confirm`);

            setLoaderState(false)

            if (!response.ok || response.result === undefined)
                return;

            setBudgetData(response.result);
        },
        [setLoaderState]
    )

    const formatDate = useCallback((date: Date, index: number): string => {
        const currDate = (moment(date)).format('DD MMM');

        if (index === 0)
            return currDate;

        const previousRow = budgetData[index - 1];

        const previousDate = (moment(previousRow.date)).format('DD MMM');

        return previousDate === currDate
            ? ''
            : currDate;
    }, [budgetData]);

    useEffect(() => {
        dataFetch()
        dataFetchPurchaseCategories()
    }, [dataFetch, dataFetchPurchaseCategories])

    const onChange = (key: string) => {
        setTabActiveKey(key);
    };

    const updateData = (budgetResponse: BudgetResponse) => {

        const el = document.getElementById('scrolling_div');
        if (el !== undefined && el !== null) {
            el.scrollTop = 60;
        }

        if (budgetResponse.budgetType === BudgetType.Current) {
            setTabActiveKey("1");

            const curSum = {
                ...budgetResponse,
                last4DigitsOfCard: budgetResponse.last4DigitsOfCard?.toString()
            } as BudgetCurrentRequest;

            setCurrentData(curSum);
        } else if (budgetResponse.budgetType === BudgetType.Dynamic) {
            setTabActiveKey("3");

            const budgetDynamicRequest = { ...budgetResponse } as BudgetDynamicRequest;

            setDynamicData(budgetDynamicRequest);
        } else if (budgetResponse.budgetType === BudgetType.Static) {
            setTabActiveKey("2");

            const budgetStaticRequest = {
                ...budgetResponse,
                dayOfMonth: new Date(budgetResponse.date).getDate(),
                monthOfYear: new Date(budgetResponse.date).getMonth() + 1,
            } as BudgetStaticRequest;

            setStaticData(budgetStaticRequest);
        }
    }

    const budgetTypeToName = (budgetType: BudgetType): string => {
        return t(`budget.${BudgetType[budgetType]}`);
    };

    const staticSumPeriodTypeToName = (budgetType: StaticSumPeriodType): string => {
        return t(`budget.staticSumPeriodType.${StaticSumPeriodType[budgetType]}`);
    };

    const budgetTypeToShortName = (budgetType: BudgetType): string => {
        return t(`budget.short${BudgetType[budgetType]}`);
    };

    const items = [
        {
            label: budgetTypeToName(BudgetType.Current), key: '1', children: <CurrentForm data={currentData}
                setData={setCurrentData}
                onFinish={onCurrentFinish}
            />
        },
        {
            label: budgetTypeToName(BudgetType.Static), key: '2', children: <StaticForm data={staticData}
                setData={setStaticData}
                onFinish={onStaticFinish}
                purchaseCategories={purchaseCategoryData}
            />
        },
        {
            label: budgetTypeToName(BudgetType.Dynamic), key: '3', children: <DynamicForm data={dynamicData}
                setData={setDynamicData}
                onFinish={onDynamicFinish}
                purchaseCategories={purchaseCategoryData}
            />
        },
    ];

    const budgetColumnFilterItem = [
        {
            text: budgetTypeToName(BudgetType.Dynamic),
            value: BudgetType.Dynamic,
        },
        {
            text: budgetTypeToName(BudgetType.Static),
            value: BudgetType.Static,
            children: [
                {
                    text: staticSumPeriodTypeToName(StaticSumPeriodType.PerDay),
                    value: StaticSumPeriodType.PerDay * -1,
                },
                {
                    text: staticSumPeriodTypeToName(StaticSumPeriodType.PerMonth),
                    value: StaticSumPeriodType.PerMonth * -1,
                },
                {
                    text: staticSumPeriodTypeToName(StaticSumPeriodType.PerYear),
                    value: StaticSumPeriodType.PerYear * -1,
                },
            ]
        }
    ]

    return (
        <PageContent>

            <ContentTitle title={t('budget.title')} />

            <Tabs defaultActiveKey="1" onChange={onChange} activeKey={tabActiveKey} items={items} />
            <TableCurrent data={budgetData}
                confirmCurrentSum={confirmCurrentSum}
                formatDate={formatDate}
                onDelete={onDelete}
                updateData={updateData}
                transerCurrentSumShowModal={setTransferCurrentSumData}
                budgetTypeToName={budgetTypeToName}
            />

            <Divider />

            <Table<BudgetResponse> dataSource={budgetData.filter(a => a.budgetType !== BudgetType.Current)}
                pagination={false}
                rowKey={(item) => `${item.id}_${item.date}`}
                className='purchases_table'
                title={() => <h4>{budgetTypeToName(BudgetType.Dynamic) + ' ' + budgetTypeToName(BudgetType.Static)}</h4>}
            >
                <Column<BudgetResponse> title={t('budget.date')}
                    dataIndex="date"
                    key="date"
                    responsive={["sm"]}
                    render={(d, item, index) =>
                    (
                        <DateText date={item.date} formatedDate={formatDate(item.date, index)} />
                    )}
                />

                <Column<BudgetResponse> title={t('budget.budgetType')}
                    dataIndex="budgetType"
                    key="budgetType"
                    responsive={["sm"]}
                    filters={budgetColumnFilterItem}
                    filterMode='tree'
                    onFilter={(value: string | number | boolean, record: BudgetResponse) => record.budgetType === value || (record.staticSumPeriodType !== undefined && record.staticSumPeriodType * -1 === value)}
                    render={(text, item) => (
                        <Space direction="vertical">
                            <Tag color="green" key={item.id}>{budgetTypeToName(item.budgetType)}</Tag>
                            {item.notRequired && <Tag icon={<ClockCircleOutlined />} color="blue" key={item.id}>{t('budget.notRequired')}</Tag>}
                        </Space>
                    )}
                />

                <Column<BudgetResponse> title={t('budget.title')}
                    dataIndex="title"
                    key="title"
                    responsive={["sm"]}
                    filters={budgetData.map((a, i) => a.title)
                        .filter((value, index, self) => {
                            return self.indexOf(value) === index
                        }).sort()
                        .map<ColumnFilterItem>((a, i) => {
                            return {
                                text: a,
                                value: a,
                            } as ColumnFilterItem
                        })
                    }
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: string | number | boolean, record: BudgetResponse) => record.title === value}
                    render={(a, item) => <BudgetTitle budgetResponse={item} />}
                />

                <Column<BudgetResponse> title={t('budget.amount')}
                    dataIndex="amount"
                    key="amount"
                    responsive={["sm"]}
                    render={(a, item) => {

                        if (item.actualAmount === null
                            || item.actualAmount === item.amount)
                            return (
                                <Space direction="horizontal">
                                    <Text>{item.amount}</Text>
                                    {item.budgetType === BudgetType.Static && (<EditOutlined onClick={() => setCorrectStaticSum(item)} />)}
                                </Space>
                            )


                        return (<Space direction="horizontal">
                            <Text disabled delete>{item.amount}</Text>
                            <Text>{item.actualAmount}</Text>
                        </Space>)
                    }}
                    filters={
                        [
                            {
                                text: `${t('budget.amount')} > 0`,
                                value: '>',
                            },
                            {
                                text: `${t('budget.amount')} < 0`,
                                value: '<',
                            }
                        ]}
                    onFilter={(value: string | number | boolean, record: BudgetResponse) => {
                        if (value === '>')
                            return record.amount > 0;
                        else
                            if (value === '<')
                                return record.amount < 0;
                            else
                                return true;

                    }}

                />

                <Column<BudgetResponse> title={t('budget.balance')}
                    dataIndex="balance"
                    key="balance"
                    responsive={["sm"]}
                    render={(a, item) => (<Text type={item.balance < 0 ? "danger" : "secondary"} strong>{item.balance}</Text>)}
                />

                <Column<BudgetResponse> title={t('budget.data')}
                    dataIndex="date"
                    key="date"
                    responsive={["xs"]}
                    filters={budgetColumnFilterItem}
                    onFilter={(value: string | number | boolean, record: BudgetResponse) => record.budgetType === value || (record.staticSumPeriodType !== undefined && record.staticSumPeriodType * -1 === value)}
                    filterMode='tree'
                    render={(d, item, index) => (
                        <Space direction="vertical">
                            <DateText date={item.date} formatedDate={formatDate(item.date, index)} >
                                <Tooltip title={budgetTypeToName(item.budgetType)} color="blue" key={item.id}>
                                    <Tag color="green" key={item.id}>{budgetTypeToShortName(item.budgetType)}</Tag>
                                </Tooltip>
                                {item.notRequired &&
                                    <Tooltip title={t('budget.notRequired')} color="blue" key={item.id}>
                                        <Tag icon={<ClockCircleOutlined />} color="blue" key={item.id} />
                                    </Tooltip>
                                }
                                <BudgetTitle budgetResponse={item} />
                            </DateText>

                            {item.actualAmount === null || item.actualAmount === item.amount
                                ? <Space direction="horizontal">
                                    <Text type="secondary">{t('budget.short_amount')}: {item.amount}</Text>
                                    {item.budgetType === BudgetType.Static && (<EditOutlined onClick={() => setCorrectStaticSum(item)} />)}
                                </Space>
                                : <Space direction="horizontal">
                                    <Text type="secondary">{t('budget.short_amount')}</Text>
                                    <Text type="secondary" delete disabled>{item.amount}</Text>
                                    <Text type="secondary">{item.actualAmount}</Text>
                                </Space>}

                            <Text type={item.balance < 0 ? "danger" : "secondary"} strong>{t('budget.balance')}: {item.balance}</Text>
                        </Space>)}
                />

                <Column<BudgetResponse>
                    title={t('actions')}
                    key="action"
                    align="right"
                    render={(text, item) => (
                        <Space>
                            {item.budgetType === BudgetType.Dynamic && !item.done && (<CheckOutlined onClick={() => dynamicSumDone(item)} />)}
                            {item.budgetType === BudgetType.Dynamic && item.done && (<UndoOutlined onClick={() => dynamicSumUndone(item)} />)}
                            {item.budgetType === BudgetType.Dynamic && (<EditOutlined onClick={() => updateData(item)} />)}
                            {item.budgetType === BudgetType.Static && !item.done && (<CheckOutlined onClick={() => setStaticDoneData(item)} />)}
                            {item.budgetType === BudgetType.Static && item.done && (<UndoOutlined onClick={() => staticSumUndone(item)} />)}
                            {item.budgetType === BudgetType.Static && (<EditOutlined onClick={() => updateData(item)} />)}
                            <Popconfirm
                                title={t('products.delete_text')}
                                onConfirm={() => onDelete(item.id)}
                                okText={t('yes')}
                                cancelText={t('no')}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>

            {dynamicDoneData && <DynamicDoneModal
                budgetResponse={dynamicDoneData}
                onCancel={() => setDynamicDoneData(undefined)}
                onFinish={dynamicSumDoneHandle}
                currentSums={budgetData.filter(a => a.budgetType === BudgetType.Current)}
            />}

            {staticDoneData && <StaticDoneModal
                budgetResponse={staticDoneData}
                onCancel={() => setStaticDoneData(undefined)}
                onFinish={staticSumDoneHandle}
                currentSums={budgetData.filter(a => a.budgetType === BudgetType.Current)}
            />}

            {correctStaticSum && <CorrectStaticSumModal
                budgetResponse={correctStaticSum}
                onCancel={() => setCorrectStaticSum(undefined)}
                onFinish={correctStaticSumHandle}
            />}

            {transferCurrentSumData && <TransferCurrentSumModal
                budgetResponse={transferCurrentSumData}
                budgetData={budgetData}
                onCancel={() => setTransferCurrentSumData(undefined)}
                onFinish={transferCurrentSumHandle}
            />}

        </PageContent>
    )
})
