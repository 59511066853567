import './Help.scss'

import React, {
    FC,
} from 'react'

import 'antd/dist/reset.css';
import { useParams } from 'react-router-dom';

export const articles = [
    {
        id: "join-alice",
        title: "Как подключить умную колонку с Алисой к вашей семье?",
        content: <ul>
            <li>Скажите "<a href='https://dialogs.yandex.ru/store/skills/4b64f757-semejnye-dela?utm_source=site&utm_medium=badge&utm_campaign=v1&utm_term=d3'>Алиса</a>, запусти навык семейные покупки"</li>
            <li>После запуска скажите "Подключение к семье", в ответ Алиса скажет ID и пароль подключения (позже она его повторит)</li>
            <li>Авторизуйтесь на сайте любым удобным для вас способом</li>
            <li>Нажмите на свое имя в правом верхнем углу для перехода в профиль</li>
            <li>В блок "Ваши устройства с Алисой" введите ID и пароль, который сказала Алиса</li>
            <li>Нажмите кнопку «Добавить»</li>
        </ul>
    },
    {
        id: "join-telegram",
        title: "Как подключить телеграмм бота к вашей семье?",
        content: <ul>
            <li>Авторизуйтесь на сайте любым удобным для вас способом</li>
            <li>Нажмите на свое имя в правом верхнем углу для перехода в профиль</li>
            <li>В блоке "Состав семьи" нажмите кнопку "Подключить учетную запись телеграмм"</li>
            <li>В открывшемся окне нажмите на ссылку "Ссылка для подключения телеграмм учетки к вашей семье" для перехода в телеграмм</li>
            <li>В телеграмме нажмите кнопку старт</li>
        </ul>
    },
    {
        id: "alice-disable-long-hi",
        title: "Как отключить длинное приветствие в навыке \"семейные покупки\" от Алисы?",
        content: <ul>
            <li>Скажите "<a href='https://dialogs.yandex.ru/store/skills/4b64f757-semejnye-dela?utm_source=site&utm_medium=badge&utm_campaign=v1&utm_term=d3'>Алиса</a>, запусти навык семейные покупки"</li>
            <li>После запуска скажите "короткое приветствие"</li>
        </ul>
    },


]

export const Article: FC = React.memo(() => {

    const urlParams = useParams();

    const art = articles.find(a => a.id === urlParams?.id);

    return (
        <div className="article-block" itemScope itemType="http://schema.org/Question">
            <h1 itemProp="name">{art?.title}</h1>

            <div itemProp="acceptedAnswer" itemScope itemType="http://schema.org/Answer">
                <meta itemProp="upvoteCount" content="3" />
                <div itemProp="text">
                    {art?.content}
                </div>
            </div>
        </div>
    )
})
