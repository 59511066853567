import React, { FC } from 'react'
import { PageContent } from '../../components'

export const NotFound: FC = React.memo(() => {
    return (
        <PageContent>
            <h1>404- Page NotFound</h1>
        </PageContent>
    )
})
