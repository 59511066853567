import './ContentGroup.scss'

import React from 'react'

import { ContentGroupProps } from './ContentGroup.model'

export const ContentGroup: React.FC<ContentGroupProps> = React.memo(
    ({ title, children }) => (
        <div className="element-group">
            <div className="element-title">{title}</div>
            <div className="element-content">
                {children}
            </div>
        </div>
    )
)
