import './ContentTitle.scss'

import React from 'react'

import { ContentTitleProps } from './ContentTitle.model'

export const ContentTitle: React.FC<ContentTitleProps> = React.memo(
    ({ title }) => (
        <h3 className='content-title'>
            {title}
        </h3>
    )
)
