import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Form, Input, Button, TreeSelect } from 'antd'
import { Api, ProductCategoryContract, TreeProductCategoryContract } from '../../../api';
import { useTranslation } from 'react-i18next';
import { LoaderContext } from '../../../contexts';

export interface ProductCategoryCreateProps {
    onAdd: (event: ProductCategoryContract) => void;
    data: TreeProductCategoryContract[]
    editValue?: ProductCategoryContract
}

export const ProductCategoryCreate: React.FC<ProductCategoryCreateProps> = React.memo(({ onAdd, data, editValue }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const { setLoaderState } = useContext(LoaderContext)

    const [form] = Form.useForm<ProductCategoryContract>()

    const [value, setValue] = useState<string>();

    const onChange = (newValue: string) => {
        setValue(newValue);
    };

    const handleFinish = useCallback(
        async (values: Partial<ProductCategoryContract>) => {

            const pc = values as ProductCategoryContract;

            try {
                setLoaderState(true);

                const response = editValue
                    ? await Api.patch<ProductCategoryContract, string>(`admin/ProductCategories/${editValue.id}`, pc)
                    : await Api.post<ProductCategoryContract, string>(`admin/ProductCategories`, pc);

                if (!response.ok || !response.result)
                    return

                onAdd(pc);

            } catch (err) {
                console.error(err)
            } finally {
                setLoaderState(false);
            }

            form.resetFields();
        },
        [form, onAdd]
    )

    const tree = useMemo(() => {
        return data.map((a) => (
            {
                title: a.item.name,
                value: a.item.id,
                children: a.children.map((c) => ({
                    title: c.item.name,
                    value: c.item.id,
                    children: c.children.map((c2) => ({
                        title: c2.item.name,
                        value: c2.item.id,
                    }))
                }))
            }))
    }, [data]);

    useEffect(() => {
        form.resetFields()
    }, [form, editValue])

    return (
        <Form
            className="form"
            name="ProductCategoryCreateForm"
            onFinish={handleFinish}
            form={form}
            initialValues={editValue}
        >
            <Form.Item
                name="id"
                hidden
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: tt('events.create.name_required_message'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Short name"
                name="shortName"
                rules={[
                    {
                        required: true,
                        message: tt('events.create.name_required_message'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Icon"
                name="icon"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Weight"
                name="weight"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Parent"
                name="parentId"
            >
                <TreeSelect
                    value={value}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    onChange={onChange}
                    treeData={tree}
                />
            </Form.Item>

            <Form.Item >
                <Button
                    form="ProductCategoryCreateForm"
                    type="primary"
                    htmlType="submit"
                    className="button"
                >
                    {t(editValue ? 'update' : 'add')}
                </Button>

            </Form.Item>
        </Form>
    )
})
