import './ProductCategories.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle, Content } from '../../components';
import { message, Tree, } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, ProductCategoryContract, TreeProductCategoryContract } from '../../api';
import { DataNode, TreeProps } from 'antd/es/tree';
import { ProductCategoryElement } from './components/ProductCategoryElement';
import { DownOutlined } from '@ant-design/icons';
import { ProductCategoryCreate } from './components/ProductCategoryCreate';
import { ProductCategoryNames } from './components/ProductCategoryNames';



export const ProductCategories: FC = React.memo(() => {
    const [editData, setEditData] = useState<ProductCategoryContract>()
    const [data, setData] = useState<TreeProductCategoryContract[]>([])
    const [treeData, setTreeData] = useState<DataNode[]>([])
    const { setLoaderState } = useContext(LoaderContext)

    const onDelete = async (id: string) => {

        try {
            setLoaderState(true);

            const response = await Api.deleteWithoutResult(`admin/ProductCategories/${id}`);

            if (!response.ok) {
                message.error(response.exceptionResult?.Message ?? 'Error on delete');
                return;
            }

            await dataFetch();

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);

            const response = await Api.get<TreeProductCategoryContract[]>(`admin/ProductCategories`)

            if (!response.ok || !response.result)
                return

            setData(response.result);

            const d = response.result
                .sort((a, b) => (a.item.weight ?? 0) - (b.item.weight ?? 0))
                .map((a) =>
                (
                    {
                        title: <ProductCategoryElement item={a.item} onEdit={setEditData} onDelete={onDelete} />,
                        key: a.item.id,
                        children: a.children
                            .sort((a, b) => (a.item.weight ?? 0) - (b.item.weight ?? 0))
                            .map((c) => (
                                {
                                    key: `${c.item.id}`,
                                    title: <ProductCategoryElement item={c.item} onEdit={setEditData} onDelete={onDelete} />,
                                    children: c.children
                                        .sort((a, b) => (a.item.weight ?? 0) - (b.item.weight ?? 0))
                                        .map((c2) => (
                                            {
                                                key: `${c2.item.id}`,
                                                title: <ProductCategoryElement item={c2.item} onEdit={setEditData} onDelete={onDelete} />,
                                            }))
                                }))
                    } as DataNode)
                );

            setTreeData(d);

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    const [selectData, setSelectData] = useState<string>()
    const onSelect: TreeProps['onSelect'] = async (selectedKeys, info) => {
        setSelectData(info.node.key?.toString());
    };

    const onAdd = () => {
        dataFetch();
    }

    useEffect(() => {
        dataFetch()
    }, [dataFetch])

    return (
        <PageContent>

            <ContentTitle title="Product categories" />

            <ProductCategoryCreate onAdd={onAdd} data={data} editValue={editData} />

            <Content>
                <Tree
                    onSelect={onSelect}
                    defaultExpandAll={true}
                    showLine
                    switcherIcon={<DownOutlined />}
                    treeData={treeData}
                />
            </Content>

            <Content>
                <ProductCategoryNames productCotegoryId={selectData } />
            </Content>

        </PageContent>
    )
})
