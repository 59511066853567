import './DiscountCardsCreate.scss'

import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input, Button, Space, Modal, Select } from 'antd'
import { DiscountCardsCreateProps } from './DiscountCardsCreate.model';
import { CreateDiscountCardRequest, DiscountCardType } from '../../../api';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { QrReader } from 'react-qr-reader';

export const DiscountCardsCreate: React.FC<DiscountCardsCreateProps> = React.memo(({ onAddDiscountCards }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const [form] = Form.useForm();
    const [modalScanData, setModalScanData] = useState<boolean>(false)

    const [dataData, setDataData] = useState<string>("");

    const onChangeData = (event: any) => {
        setDataData(event.target.value);
    };

    const handleFinish = useCallback(
        (values: Partial<CreateDiscountCardRequest>) => {
            onAddDiscountCards(values as CreateDiscountCardRequest)

            form.resetFields();
            setDataData("");
        },
        [form, onAddDiscountCards]
    );

    const handleScan = (value: string | null) => {
        if (value === null)
            return

        form.setFieldsValue({ data: value });
        setDataData(value);

        setModalScanData(false);
    }

    const handleError = (err: any) => {
        console.error(err)
    }

    const { Option } = Select;

    const onTypeChange = (value: DiscountCardType) => {
        form.setFieldsValue({ type: value });
    };

    useEffect(() => {
        form.resetFields()
    }, [form])

    return (
        <div>
            <Form
                className="form"
                name="form"
                onFinish={handleFinish}
                form={form}
            >
                <Form.Item
                    hidden={true}
                    name="id"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('discount_cards.create.name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: tt('field_is_required'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('discount_cards.create.data')}
                    name="data"
                >
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        <TextArea rows={4}
                            placeholder={tt('discount_cards.create.data')}
                            value={dataData}
                            onChange={onChangeData}
                        />
                        <Button type="link" block onClick={() => setModalScanData(true)}>
                            Scan QR
                        </Button>
                    </Space>
                </Form.Item>

                <Form.Item
                    label={t('discount_cards.create.type')}
                    name="type"
                >
                    <Select
                        onChange={onTypeChange}
                    >
                        <Option value={DiscountCardType.QR}>QR</Option>
                        <Option value={DiscountCardType.Barcode}>Barcode</Option>
                    </Select>
                </Form.Item>

                <Form.Item >
                    <Space>
                        <Button
                            form="form"
                            type="primary"
                            htmlType="submit"
                            className="button"
                        >
                            {t('add')}
                        </Button>


                    </Space>
                </Form.Item>
            </Form>

            <Modal
                title={t('scan_qr')}
                open={modalScanData}
                onOk={() => { setModalScanData(false) }}
                onCancel={() => { setModalScanData(false) }}
            >
                <QrReader
                    constraints={{ facingMode: 'user' }}
                    onResult={(result, error) => {
                        if (!!result) {
                            handleScan(result?.getText());
                        }

                        if (!!error) {
                            handleError(error);
                        }
                    }}
                />
            </Modal>
        </div>
    )
})
