import './CreatePurchase.scss'

import React, { FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import { Form, Input, Button, Space, Select, message, Tag } from 'antd'
import { useTranslation } from 'react-i18next';
import { BudgetCurrentSumResponse, PurchaseUpdateContract, PurchaseItemUpdateContract, Api, PurchaseCategoryResponse, FamilyFeatures } from '../../../api';
import { LoaderContext, UserContext } from '../../../contexts';
import { PurchaseCategory, PurchasesProp } from '../Purchases.model';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { DATE_TIME_LOCAL_FORMAT } from '../../../consts';


export interface CreatePurchaseProp {
    budgetCurrentSums: BudgetCurrentSumResponse[]
    addPurchase: (purchasesProp: PurchasesProp) => void,
    purchaseCategories: PurchaseCategoryResponse[],
}

export interface CreatePurchaseModel {
    name: string
    amount: number
    budgetCurrentSumId: string
    purchaseCategoryId: string
}

export const CreatePurchase: React.FC<CreatePurchaseProp> = React.memo(({ budgetCurrentSums, addPurchase, purchaseCategories }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [form] = Form.useForm();
    const userData = useContext(UserContext); 

    const [purchaseItems, setPurchaseItems] = useState<PurchaseItemUpdateContract[]>([]);
    const { setLoaderState } = useContext(LoaderContext);

    const handleReset = useCallback(
        (event?: FormEvent<HTMLFormElement>) => {
            form.resetFields();
            form.setFieldsValue({ createDate: moment(Date.now()).format(DATE_TIME_LOCAL_FORMAT) });
            setPurchaseItems([]);
        },
        [form]
    )

    const handleFinish = useCallback(
        async (values: Partial<CreatePurchaseModel>) => {

            const request = { ...values, products: purchaseItems } as PurchaseUpdateContract;

            setLoaderState(true)

            const response = await Api.post<PurchaseUpdateContract, PurchasesProp>(`purchases`, request);

            setLoaderState(false)

            if (!response.ok) {
                message.error(t('products.update_error'));
                return;
            }

            addPurchase({
                ...response.result, purchaseCategory: {
                    id: values.purchaseCategoryId,
                    name: purchaseCategories.filter(a => a.id === values.purchaseCategoryId)[0]?.name
                } as PurchaseCategory
            } as PurchasesProp); // crutch

            handleReset(undefined);
        },
        [addPurchase, setLoaderState, t, purchaseCategories, handleReset, purchaseItems]
    )

    const onCurrentSumIdChange = (value: string) => {
        form.setFieldsValue({ budgetCurrentSumId: value });
    };

    const onPurchaseCategoryChange = (value: string) => {
        form.setFieldsValue({ purchaseCategoryId: value });
    };

    const nameChange = (element: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (!element)
            return;

        const p = element.target.value
            .split(',')
            .map(a => a.trim())
            .filter(a => a.length > 0)
            .map((a) => parsePurchaseItem(a));

        setPurchaseItems(p);
    }

    const nameBlur = async (element: React.FocusEvent<HTMLInputElement> | undefined) => {
        if (!element)
            return;

        const pcId = form.getFieldValue("purchaseCategoryId");

        if (pcId !== undefined)
            return;

        const searchParams = new URLSearchParams();
        searchParams.set("skip", "0");
        searchParams.set("take", "1");
        searchParams.set("ItemName", element.target.value);

        const response = await Api.get<PurchasesProp[]>(`purchases?${searchParams.toString()}`);

        if (!response.ok || !response.result)
            return;

        const firstResult = response.result.at(0);

        if (!firstResult?.purchaseCategory?.id)
            return;

        const pc2Id = form.getFieldValue("purchaseCategoryId");

        if (pc2Id !== undefined)
            return;

        form.setFieldValue("purchaseCategoryId", firstResult.purchaseCategory.id)
    }

    const parsePurchaseItem = (s: string): PurchaseItemUpdateContract  => {
        const a = s.split('-');

        const c = a.length > 0 ? parseInt(a[1]) : 1

        return {
            name: a[0].trim(),
            count: isNaN(c) ? 1 : c,
        } as PurchaseItemUpdateContract
    }

    const { Option } = Select;
    const { TextArea } = Input;


    useEffect(() => {
        form.setFieldsValue({ createDate: moment(Date.now()).format(DATE_TIME_LOCAL_FORMAT) });
    }, [form])

    const [ showText, setShowText ] = useState<boolean>(false);

    useEffect(() => {
        setShowText((userData.familyFeatures & FamilyFeatures.GptParser) > 0)
    }, [userData])

    return (
        <div className='create-purchase-form'>
            <Form
                className="form"
                name="CreatePurchase"
                onFinish={handleFinish}
                onReset={handleReset}
                form={form}
            >

                <Form.Item
                    label={t('purchase.title')}
                    name="name"
                >
                    <Input placeholder={tt('purchase.title_placeholder')} onChange={nameChange} onBlur={nameBlur} />
                </Form.Item>

                {purchaseItems.length > 1 &&
                    <Form.Item
                    >
                    <Space direction='horizontal'>
                        {purchaseItems.map((p, i) => <Tag key={i}>{p.name} - {p.count}</Tag>)}
                        </Space>
                    </Form.Item>}

                <Form.Item
                    label={t('purchase.amount')}
                    name="amount"
                >
                    <Input type="number" placeholder={tt('purchase.amount')} />
                </Form.Item>

                <Form.Item
                    label={t('product.budget_current_sum')}
                    name="budgetCurrentSumId"
                >
                    <Select
                        placeholder={t('budget.select_current_sum_')}
                        onChange={onCurrentSumIdChange}
                        allowClear
                    >
                        {
                            budgetCurrentSums.map(a => <Option key={a.id} value={a.id}>{`${a.title} ${a.amount}`}</Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t('purchase.createDate')}
                    name="createDate"
                >
                    <Input type="datetime-local" placeholder={tt('purchase.createDate')} />
                </Form.Item>

                <Form.Item
                    label={<NavLink to={`/purchase-categories`} className="link">
                        {t('purchases.purchase_category')}
                    </NavLink>}
                    name="purchaseCategoryId"
                >
                    <Select
                        placeholder={t('purchases.purchase_category')}
                        onChange={onPurchaseCategoryChange}
                        allowClear
                    >
                        {
                            purchaseCategories.map(a => <Option key={a.id} value={a.id}>{a.name}</Option>)
                        }
                    </Select>
                </Form.Item>

                {showText && <Form.Item
                    label={t('purchases.text')}
                    name="text"
                >
                    <TextArea rows={4} />
                </Form.Item>}

                <Form.Item >
                    <Space>
                        <Button
                            form="CreatePurchase"
                            type="primary"
                            htmlType="submit"
                            className="button"
                        >
                            <>{t('add')}</>
                        </Button>

                        <Button
                            form="CreatePurchase"
                            htmlType="reset"
                            className="button"
                        >
                            <>{t('reset')}</>
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    )
})
