import './CookiesAgree.scss'

import React, { useState } from 'react'

import { Button, Space } from 'antd';

export interface CookiesAgreeProps {

}

export const CookiesAgree: React.FC<CookiesAgreeProps> = React.memo(() => {
    const [state, setState] = useState<string>(localStorage.getItem('CookiesAgree') ?? 'false');

    const agree = (a: any) => {
        localStorage.setItem('CookiesAgree', 'true');
        setState('true');
    }

    return (
        <>
            {state !== 'true' && <div className='cookies-agree-block'>
                <Space direction='vertical'>
                    <div>
                        Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, которые обеспечивают правильную работу сайта.
                    </div>
                    <Button type="primary" onClick={agree}>
                        Я&nbsp;согласен(-а)
                    </Button>
                </Space>
            </div>}
        </>)
})
