/*eslint unicode-bom: ["error", "always"]*/

import React, { useCallback, useEffect } from 'react'
import { Form, Input, Button, Space, Select } from 'antd'
import { useTranslation } from 'react-i18next';
import { PurchaseDetailItemProp } from '../Purchase.model';
import { PurchaseCategoryResponse } from '../../../api';

export interface PurchaseItemCreateProps {
    onAdd: (a: PurchaseDetailItemProp) => void;
    onCancel: () => void;
    purchaseItem?: PurchaseDetailItemProp
    purchaseCategoryData: PurchaseCategoryResponse[]
}

export const PurchaseItemCreate: React.FC<PurchaseItemCreateProps> = React.memo(({ onAdd, onCancel, purchaseItem, purchaseCategoryData }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [form] = Form.useForm();

    const handleFinish = useCallback(
        (values: Partial<PurchaseDetailItemProp>) => {
            onAdd(values as PurchaseDetailItemProp)

            form.resetFields();
        },
        [form, onAdd]
    );

    const onPurchaseCategoryChange = (value: string) => {
        form.setFieldsValue({ purchaseCategoryId: value });
    };

    useEffect(() => {
        form.resetFields()
    }, [form, purchaseItem])

    const Option = Select.Option;

    const isUpdating = purchaseItem?.id !== undefined;

    return (
        <div>
            <Form
                className="form"
                name="form-create-purchase-item"
                initialValues={purchaseItem}
                onFinish={handleFinish}
                form={form}
            >
                <Form.Item
                    hidden={true}
                    name="id"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('purchase.item.name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: tt('field_is_required'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('purchase.item.count')}
                    name="count"
                    initialValue={1}
                >
                    <Input type='number' />
                </Form.Item>

                <Form.Item
                    label={t('cost')}
                    name="cost"
                >
                    <Input type='number' />
                </Form.Item>

                <Form.Item
                    label={t('purchases.purchase_category')}
                    name="purchaseCategoryId"
                >
                    <Select
                        placeholder={t('purchases.purchase_category')}
                        onChange={onPurchaseCategoryChange}
                        allowClear
                    >
                        {
                            purchaseCategoryData.map(a => <Option key={a.id} value={a.id}>{a.name}</Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item >
                    <Space>
                        <Button
                            form="form-create-purchase-item"
                            type="primary"
                            htmlType="submit"
                            className="button"
                        >
                            {isUpdating ? (<>{t('update')}</>) : (<>{t('add')}</>)}
                        </Button>

                        {isUpdating && (<Button htmlType="button" className="button" onClick={onCancel}>
                            {t('product_list.create.bt_reset')}
                        </Button>)}
                    </Space>
                </Form.Item>
            </Form>

        </div>
    )
})
