import './EventPhotos.scss'

import React, { useState } from 'react'

import { EventPhotosProps } from './EventPhotos.model'
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Select, Image, Space, Divider } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { API_BASE_URL } from '../../consts';
import { ContentGroup } from '../ContentGroup/ContentGroup';
import { MoreButton } from './components';
import { UpdateDateModal } from './components/UpdateDateModal';
import { EventPhotoTypeContract, PhotoContract } from '../../api';
import { Like } from './components/Like';

export const EventPhotos: React.FC<EventPhotosProps> = React.memo(({ eventId, photos, onDeletePhoto, className, viewKey, editKey, setTitlePhotoAvailable, dataFetch }) => {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState<number>()
    const [currentSort, setCurrentSort] = useState<string>(localStorage.getItem('event_photos_sort') ?? 'desc');
    const [updateDatePhoto, setUpdateDatePhoto] = useState<PhotoContract>();

    const showPopupHandle = (index: number) => {
        setCurrentIndex(index);
    }

    const onDelPhoto = (id: string) => {
        if (onDeletePhoto !== undefined)
            onDeletePhoto(id);
    }

    const { Option } = Select;

    const handleChange = (value: string) => {
        localStorage.setItem('event_photos_sort', value);
        setCurrentSort(value);
    };

    const sortFunc = (a: PhotoContract, b: PhotoContract) => {
        if (a.date === b.date) {
            return 0;
        }

        if (currentSort === 'asc') {
            return a.date > b.date ? 1 : -1;
        }
        else {
            return a.date < b.date ? 1 : -1;
        }
    }

    return (
        <div className={`${className}`} >
            <ContentGroup title={t('sort')}>
                <Select defaultValue={currentSort} style={{ width: 250 }} onChange={handleChange}>
                    <Option value="asc">{t('event_photos.asc')}</Option>
                    <Option value="desc">{t('event_photos.desc')}</Option>
                </Select>
            </ContentGroup>
            <div className='event_images' >


                {photos?.sort(sortFunc).map((item, index) =>
                    <div key={item.id} className="img-container">
                        {item.eventPhotoType === EventPhotoTypeContract.Photo &&
                            <img key={item.id} src={item.fileName} alt={item.originFileName} onClick={e => showPopupHandle(index)} loading="lazy" />
                        }
                        {item.eventPhotoType === EventPhotoTypeContract.Video &&
                            <video controls >
                                <source src={item.fileName} type="video/mp4" />
                            </video>
                        }
                        <div>
                            <Space>
                                {setTitlePhotoAvailable && <>
                                    <Like eventId={eventId} photo={item} dataFetch={dataFetch} />

                                    <Divider type="vertical" />
                                </>}

                                <Button target="_blank"
                                    href={`${API_BASE_URL}/api/v1/events/${eventId}/photos/${item.id}?viewKey=${viewKey}&editKey=${editKey}`}
                                    type="link"
                                    className="img-download"
                                    icon={< DownloadOutlined />}
                                    download={item.originFileName}>
                                   </Button>

                                <Divider type="vertical" />

                                {item.canDelete && <><Popconfirm
                                    title={t('event_photos.delete_confirm')}
                                    onConfirm={() => onDelPhoto(item.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}>
                                    <Button type="link" className="img-delete" icon={< DeleteOutlined />} />
                                </Popconfirm>
                                    <Divider type="vertical" />
                                </>}

                                <MoreButton eventId={eventId}
                                    photoContract={item}
                                    setTitlePhotoAvailable={setTitlePhotoAvailable}
                                    dataFetch={dataFetch}
                                    selectUpdateDatePhoto={setUpdateDatePhoto}
                                />
                            </Space>
                        </div>
                    </div>
                )}
            </div>


            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ current: currentIndex, visible: currentIndex !== undefined, onVisibleChange: vis => setCurrentIndex(undefined) }}>

                    {photos?.sort(sortFunc).map((item, index) =>
                        <Image key={item.id} src={item.fileName} alt={item.originFileName} loading="lazy" />
                    )}
                </Image.PreviewGroup>
            </div>

            <UpdateDateModal eventId={eventId} photo={updateDatePhoto} setPhoto={setUpdateDatePhoto} />

        </div>
    )
})
