import './PublicEvents.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'

import 'antd/dist/reset.css';
import { Content, ContentTitle, EventPhotoUploader, PageContent } from '../../components';
import { useLocation } from 'react-router-dom'
import { LoaderContext } from '../../contexts';
import { Api, PublicEventContract } from '../../api';
import { EventPhotos } from '../../components/EventPhotos/EventPhotos';

export const PublicEvents: FC = React.memo(() => {
    const [eventData, setEventData] = useState<PublicEventContract>()

    const { setLoaderState } = useContext(LoaderContext)

    const query = new URLSearchParams(useLocation().search);

    const eventId = query.get("eventId");
    const viewKey = query.get("viewKey") ?? '';
    const editKey = query.get("editKey") ?? '';

    const dataFetch = useCallback(async () => {

        if (eventId && (viewKey || editKey)) {
            try {
                setLoaderState(true)

                const response = await Api.get<PublicEventContract>(`public-events/${eventId}?viewKey=${viewKey}&editKey=${editKey}`)

                if (!response.result)
                    return

                setEventData(response.result)

            } catch (err) {
                console.error(err)
            } finally {
                setLoaderState(false)
            }
        }
    }, [eventId, viewKey, editKey, setLoaderState])

    const onDeletePhoto = async (id: string) => {

        try {
            setLoaderState(true);

            const response = await Api.delete(`events/${eventId}/photos/${id}?editKey=${editKey}`);

            if (!response)
                return;

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }

        await dataFetch(); // TODO without reload;
    }

    useEffect(() => {
        dataFetch()
    }, [dataFetch])

    return (
        <PageContent>
            <ContentTitle title={eventData?.name ?? ''} />

            <Content>
                <EventPhotos
                    eventId={eventId ?? ''}
                    photos={eventData?.photos}
                    onDeletePhoto={onDeletePhoto}
                    viewKey={viewKey}
                    editKey={editKey}
                    setTitlePhotoAvailable={false}
                />
            </Content>

            {eventData?.canUpdate ?
                <div>
                    <EventPhotoUploader eventId={eventId ?? ''}
                        uploadCallback={dataFetch}
                        editKey={editKey}
                    />
                </div>
                : <></>}

        </PageContent>
    )
})
