import './Purchases.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle } from '../../components';
import { Button, Space, Switch } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, BudgetCurrentSumResponse, PurchaseCategoryResponse } from '../../api';
import { useTranslation } from 'react-i18next';
import { PurchasesProp } from './Purchases.model';
import { FilterBlock } from './components/FilterBlock';
import { CreatePurchase } from './components/CreatePurchase';
import { TableData } from './components/TableData';
import { GroupTableData } from './components/GroupTableData';

export const Purchases: FC = React.memo(() => {
    const { t } = useTranslation();

    const [purchasesData, setPurchasesData] = useState<PurchasesProp[]>([])
    const [budgetCurrentSumData, setBudgetCurrentSumData] = useState<BudgetCurrentSumResponse[]>([]);
    const [purchaseCategoryData, setPurchaseCategoryData] = useState<PurchaseCategoryResponse[]>([]);
    const [grouping, setGrouping] = useState(false);

    const { setLoaderState } = useContext(LoaderContext)
    const [loadMoreState, setLoadMoreState] = useState<boolean>(false);
    const countLoadItems = 20;

    const dataFetch = useCallback(async (skip: number) => {
        try {
            setLoaderState(true);

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("skip", skip.toString());
            searchParams.set("take", countLoadItems.toString());

            const response = await Api.get<PurchasesProp[]>(`purchases?${searchParams.toString()}`);

            if (!response.ok || !response.result)
                return

            setLoadMoreState(response.result.length === countLoadItems)
            setPurchasesData(prev => skip === 0 ? response.result : prev.concat(response.result));

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    const dataFetchPurchaseCategories = useCallback(async () => {

        setLoaderState(true)

        const response = await Api.get<PurchaseCategoryResponse[]>(`purchase-categories`);

        setLoaderState(false)

        if (!response.ok || response.result === null)
            return

        setPurchaseCategoryData(response.result.sort((a, b) => ('' + a.name).localeCompare(b.name)) ?? []);

    }, [setLoaderState])

    const onDelete = (id: string) => {

        setLoaderState(true)

        var result = Api.delete(`purchases/${id}`)

        setLoaderState(false)

        if (!result)
            return;

        setPurchasesData(items => items.filter(a => a.id !== id))
    }

    const dataFetchBudgetCurrentSums = useCallback(async () => {

        setLoaderState(true)

        const budgetCurrentSumsResponse = await Api.get<BudgetCurrentSumResponse[]>(`purchases/budget/current-sums`);

        setLoaderState(false)

        if (!budgetCurrentSumsResponse.ok || budgetCurrentSumsResponse.result === null)
            return

        setBudgetCurrentSumData(budgetCurrentSumsResponse.result ?? []);

    }, [setLoaderState])

    const initDataFetch = useCallback(async () => {
        await dataFetch(0);
        await dataFetchBudgetCurrentSums();
        await dataFetchPurchaseCategories();
    }, [dataFetch, dataFetchBudgetCurrentSums, dataFetchPurchaseCategories])

    useEffect(() => {
        initDataFetch();
    }, [initDataFetch])

    return (
        <PageContent>

            <ContentTitle title={t('purchases.title')} />

            <CreatePurchase budgetCurrentSums={budgetCurrentSumData}
                addPurchase={p => { setPurchasesData(s => [p, ...s]); dataFetchBudgetCurrentSums(); }}
                purchaseCategories={purchaseCategoryData}
            />

            <FilterBlock onLoadData={initDataFetch} purchaseCategories={purchaseCategoryData} />

            <div className="purchases">
                <Space align="center" style={{ margin: 16 }}>
                    {t('purchases.grouping')}: <Switch checked={grouping} onChange={setGrouping} />
                </Space>

                {!grouping && <TableData purchasesData={purchasesData} onDelete={onDelete} />}
                {grouping && <GroupTableData purchasesData={purchasesData} />}

                {loadMoreState && <div className="load-more"><Button type="link" onClick={() => dataFetch(purchasesData.length)}>{t('load_more')}</Button></div>}
            </div>

        </PageContent>
    )
})
