import React, { FC } from 'react'
import { Tag } from 'antd';
import { BudgetResponse } from '../../../api';
import { CreditCardOutlined } from '@ant-design/icons';

export interface CreditCardProps {
    budgetResponse: BudgetResponse;
}

export const CreditCard: FC<CreditCardProps> = React.memo(({ budgetResponse }) => {

    if (!budgetResponse.isCreditCard)
        return (
            <></>
        )

    return (
        <Tag icon={<CreditCardOutlined />} color="blue" key={budgetResponse.id}>{(budgetResponse.creditCardLimit ?? 0) + budgetResponse.amount} / {budgetResponse.creditCardLimit}</Tag>
    )
})
