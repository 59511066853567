import './ShortDate.scss'

import React, { useCallback } from 'react'

import moment from 'moment';
import { Typography } from 'antd';

export interface ShortDateProps {
    date: Date;
    dateFormat?: string
}


export const ShortDate: React.FC<ShortDateProps> = React.memo(({ date, dateFormat = 'DD MMM (dddd)' }) => {

    const { Text } = Typography;

    const isDayOff = (dt: Date): string => {
        const d = new Date(dt).getDay();

        return (d === 0 || d === 6) ? 'short_date_day_off' : '';
    }

    const formatDate = useCallback((date: Date): string => {
        const currDate = (moment(date)).format(dateFormat);

        return currDate;
    }, [dateFormat]);

    return (
        <Text className={isDayOff(date)}>{formatDate(date)}</Text>
    )
})
