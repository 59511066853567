import React, { useCallback, useContext } from 'react'


import { Button } from 'antd';
import { Api, EventDetailContract, PhotoContract } from '../../../api';
import { LoaderContext } from '../../../contexts';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';


export interface LikeProps {
    eventId: string;
    photo: PhotoContract | undefined;
    dataFetch: (() => void) | undefined;
}

export const Like: React.FC<LikeProps> = React.memo(({ eventId, photo, dataFetch }) => {

    const { setLoaderState } = useContext(LoaderContext);

    const handle = useCallback(async (photoId: string | undefined, p: string) => {

        if (!photoId)
            return;

        setLoaderState(true)

        const response = await Api.patchWithoutRequestBody<EventDetailContract>(`events/${eventId}/photos/${photoId}/${p}`);

        setLoaderState(false)

        if (!response.ok || response.result === null)
            return

        if (dataFetch)
            dataFetch();

    }, [setLoaderState, eventId, dataFetch])

    const like = useCallback(async (photoId: string | undefined) => {
        handle(photoId, 'like');
    }, [handle])

    const dislike = useCallback(async (photoId: string | undefined) => {
        handle(photoId, 'dislike');
    }, [handle])

    return (
        <>
            {photo?.isLiked
                ?  <Button 
                    type="link"
                    icon={< HeartFilled />}
                    onClick={() => dislike(photo?.id)}
                />
                : <Button 
                    type="link"
                    icon={< HeartOutlined />}
                    onClick={() => like(photo?.id)}
                />
            }
        </>
    )
})
