/*eslint unicode-bom: ["error", "always"]*/

import './PurchaseCategories.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle, BackTo } from '../../components';
import { message, Popconfirm, Space, Table } from 'antd';
import { LoaderContext } from '../../contexts';
import { Api, CreatePurchaseCategoryRequest, PurchaseCategoryResponse } from '../../api';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { PurchaseCategoryCreate } from './components/PurchaseCategoryCreate';
import Column from 'antd/lib/table/Column';

export const PurchaseCategories: FC = React.memo(() => {
    const { t } = useTranslation();

    const [purchaseCategoriesData, setPurchaseCategoriesData] = useState<PurchaseCategoryResponse[]>([])
    const { setLoaderState } = useContext(LoaderContext)

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);

            const response = await Api.get<PurchaseCategoryResponse[]>(`purchase-categories`)

            if (!response.ok || !response.result)
                return

            setPurchaseCategoriesData(response.result.sort(comparer));
               
        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    const comparer = (a: PurchaseCategoryResponse, b: PurchaseCategoryResponse) => {
        return ('' + a.name).localeCompare(b.name);
    };

    useEffect(() => {
        dataFetch()
    }, [dataFetch])

    const onAddHandler = async (request: CreatePurchaseCategoryRequest) => {

        setLoaderState(true)

        const result = await Api.post<CreatePurchaseCategoryRequest, PurchaseCategoryResponse>(`purchase-categories`, request);

        setLoaderState(false)

        if (!result.ok || result.result === undefined) {
            message.error(result.exceptionResult.Message);
            return;
        }

        message.success(`${t('added')} ${result.result.name}`);

        setPurchaseCategoriesData(prev => [result.result, ...prev].sort(comparer))
    }

    const onDelete = (id: string) => {

        setLoaderState(true)

        var result = Api.delete(`purchase-categories/${id}`)

        setLoaderState(false)

        if (!result) {
            message.error('Error');
            return;
        }

        message.success(`${t('deleted')}`);

        setPurchaseCategoriesData(items => items.filter(a => a.id !== id))
    }

    return (
        <PageContent>

            <BackTo to="/purchases" text={t('purchase-categories.back_to_purchases')} />

            <ContentTitle title={t('purchase-categories.title')} />

            <PurchaseCategoryCreate onAdd={onAddHandler} />

            <Table<PurchaseCategoryResponse>
                dataSource={purchaseCategoriesData}
                pagination={false}
                className='table-purchase-categories'
                rowKey='id'
            >
                <Column<PurchaseCategoryResponse>
                    title={t('product.name_edit')}
                    dataIndex="name"
                    key="name"
                    sorter={(a, b) => ('' + a.name).localeCompare(b.name)}
                    render={(text, item) => (
                        item.name
                    )}
                />

                <Column<PurchaseCategoryResponse>
                    title={t('actions')}
                    key="action"
                    width="100px"
                    render={(text, item) => (
                        <Space>
                            {item.canDelete && < Popconfirm
                                title={t('products.delete_text')}
                                onConfirm={() => onDelete(item.id)}
                                okText={t('yes')}
                                cancelText={t('no')}>
                                <DeleteOutlined />
                            </Popconfirm>}
                        </Space>
                    )}
                />
            </Table>

        </PageContent>
    )
})