export interface SharedProductsUrlParamProp {
    shopId: string,
    editKey: string,
}

export interface SharedProductDto {
    id: string
    name: string
    count: number
    state: SharedProductState
    unit: string,
    userNameWillBuy: string,
    createDate: Date,
}

export enum SharedProductState {
    'New' = 1,
    'Ordering' = 2,
}
