import './SignIn.scss'

import React, { FC } from 'react'
import { ContentTitle, PageContent } from '../../components'
import { useTranslation } from 'react-i18next';
import { LoginElement } from '../../components/Header/components/Logon/LoginElement';

export const SignIn: FC = React.memo(() => {
    const { t } = useTranslation();

    return (
        <PageContent>

            <ContentTitle title={t('header.logon.login')} />

            <ul className="signin-list">
                <li>
                    <a href="/login-telegram.html">Telegram</a>
                </li>
                <li>
                    <LoginElement name="Google" scheme="Google" />
                </li>
                <li>
                    <LoginElement name="VK" scheme="Vkontakte" />
                </li>
                <li>
                    <LoginElement name="Яндекс" scheme="Yandex" />
                </li>
                <li>
                    <LoginElement name="mail.ru" scheme="MailRu" />
                </li>
            </ul>
        </PageContent>
    )
})
