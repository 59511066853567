/*eslint unicode-bom: ["error", "always"]*/

import './PurchaseCategoryCreate.scss'

import React, { useCallback, useEffect } from 'react'
import { Form, Input, Button, Space } from 'antd'
import { PurchaseCategoryCreateProps } from './PurchaseCategoryCreate.model';
import { CreatePurchaseCategoryRequest } from '../../../api';
import { useTranslation } from 'react-i18next';

export const PurchaseCategoryCreate: React.FC<PurchaseCategoryCreateProps> = React.memo(({ onAdd }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [form] = Form.useForm();

    const handleFinish = useCallback(
        (values: Partial<CreatePurchaseCategoryRequest>) => {
            onAdd(values as CreatePurchaseCategoryRequest)

            form.resetFields();
        },
        [form, onAdd]
    );

    useEffect(() => {
        form.resetFields()
    }, [form])

    return (
        <div>
            <Form
                className="form"
                name="form-create-purchase-category"
                onFinish={handleFinish}
                form={form}
            >
                <Form.Item
                    hidden={true}
                    name="id"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('discount_cards.create.name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: tt('field_is_required'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item >
                    <Space>
                        <Button
                            form="form-create-purchase-category"
                            type="primary"
                            htmlType="submit"
                            className="button"
                        >
                            {t('add')}
                        </Button>


                    </Space>
                </Form.Item>
            </Form>

        </div>
    )
})
