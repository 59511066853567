import './Header.scss'

import React from 'react'
import { HMenu } from './components/HMenu'
import { Logon } from './components/Logon'

export const Header: React.FC = React.memo(() => {

    return (
        <header className="header">
            <div className="title">
                <div className="container">
                    <div className="logo">
                        Family-<span>Matters</span>
                    </div>

                    <Logon />
                </div>
            </div>


            <div className="menu">
                <div className="container">
                    <HMenu />
                </div>
            </div>

        </header>
    )
})
