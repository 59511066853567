import './UnauthorizedContent.scss'

import React, {
    FC,
} from 'react'

import 'antd/dist/reset.css';
import { Card, Carousel } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentTitle } from '../../../components';
import { RegistrationStepper } from './RegistrationStepper';

export const UnauthorizedContent: FC = React.memo(() => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    return (<>

        <Carousel
            autoplay
            autoplaySpeed={3000}
            speed={3000}
        >
            <img
                src="images/slide1.jpg" alt="Slide1"
            />
            <img
                src="images/slide2.jpg" alt="Slide2"
            />
            <img
                src="images/slide3.jpg" alt="Slide3"
            />
            <img
                src="images/slide4.jpg" alt="Slide4"
            />
        </Carousel>

        <RegistrationStepper />

        <div>&nbsp;</div>

        <ContentTitle title={t('home.unauthorized_content.title')} />

        <Card>{t('home.unauthorized_content.description')}</Card>

        <div>&nbsp;</div>

        <ContentTitle title={t('home.unauthorized_content.what_we_can_do')} />

        <Card title={t('home.unauthorized_content.products.title')}><div dangerouslySetInnerHTML={{ __html: tt('home.unauthorized_content.products.description') }} /></Card>

        <Card title={t('home.unauthorized_content.purchases.title')}><div dangerouslySetInnerHTML={{ __html: tt('home.unauthorized_content.purchases.description') }} /></Card>

        <Card title={t('home.unauthorized_content.budget.title')}><div dangerouslySetInnerHTML={{ __html: tt('home.unauthorized_content.budget.description') }} /></Card>

        <Card title={t('home.unauthorized_content.discount_cards.title')}><div dangerouslySetInnerHTML={{ __html: tt('home.unauthorized_content.discount_cards.description') }} /></Card>

    </>)
})
