import React from 'react';
import { useTranslation } from 'react-i18next';
import './Purchase.scss'

export interface PurchaseItemDescriptionProps {
    title: string,
    value?: string
}

export const PurchaseItemDescription: React.FC<PurchaseItemDescriptionProps> = React.memo(({ title, value }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    if (!value)
        return (<></>)

    return (<span>{tt(title)} <b>{value}</b>; </span>)
})
