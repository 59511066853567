import './HMenu.scss'

import React, { useContext, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { HomeOutlined, FileJpgOutlined, DollarOutlined, TeamOutlined, BarChartOutlined, ShoppingCartOutlined, CreditCardOutlined, QuestionOutlined } from '@ant-design/icons'
import { UserContext } from '../../../../contexts'
import { useTranslation } from 'react-i18next';
import Menu, { MenuProps } from 'antd/lib/menu'

export const HMenu: React.FC = React.memo(() => {
    const { t } = useTranslation();
    const userData = useContext(UserContext)
    const location = useLocation()

    const items = useMemo(() => {

        let result: MenuProps['items'] = [
            {
                key: 'home',
                icon: <HomeOutlined />,
                label: (
                    <NavLink to="/">
                        {t('header.menu.home')}
                    </NavLink>
                )
            },
            {
                key: 'product-lists',
                icon: <ShoppingCartOutlined />,
                label: (
                    <NavLink to="/product-lists">
                        {t('header.menu.products')}
                    </NavLink>
                )
            },
        ]

        if (userData.isAutorized) {
            const authorizedMenu: MenuProps['items'] = [
                {
                    key: 'discount-cards',
                    icon: <CreditCardOutlined />,
                    label: (
                        <NavLink to="/discount-cards">
                            {t('header.menu.discount-cards')}
                        </NavLink>
                    )
                },
                {
                    key: 'purchases',
                    icon: <DollarOutlined />,
                    label: (
                        <NavLink to="/purchases">
                            {t('header.menu.purchase')}
                        </NavLink>
                    )
                },
                {
                    key: 'budgets',
                    icon: <BarChartOutlined />,
                    label: (
                        <NavLink to="/budgets">
                            {t('header.menu.budget')}
                        </NavLink>
                    )
                },
            ]

            result = result.concat(authorizedMenu)
        }

        if (userData.isAvailableEvents)
            result = result.concat([{
                key: 'events',
                icon: <FileJpgOutlined />,
                label: (
                    <NavLink to="/events">
                        {t('header.menu.events')}
                    </NavLink>
                )
            },
            {
                key: 'friends',
                icon: <TeamOutlined />,
                label: (
                    <NavLink to="/friends">
                        {t('header.menu.friends')}
                    </NavLink>
                )
            }])

        result = result.concat([{
            key: 'help',
            icon: <QuestionOutlined />,
            label: (
                <NavLink to="/help">
                    {t('header.menu.help')}
                </NavLink>
            )
        }])

        return result;

    }, [userData, t])

    const getSelectedKey = useMemo(() => {

        let pathname = location.pathname.split('/').filter(a => a !== '').at(0)

        return pathname ?? 'home';

    }, [location])

    return (
        <Menu mode="horizontal"
            selectedKeys={[getSelectedKey]}
            items={items} />
    )
})
