import './RegistrationStepper.scss';

import React, {
    FC, useCallback, useContext, useEffect, useState,
} from 'react'

import 'antd/dist/reset.css';
import { Steps, Card, Space, message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentTitle } from '../../../components';
import { LoaderContext, UserContext } from '../../../contexts';
import { AddDeviceContract, Api, FamilyContract, FamilyDevice } from '../../../api';
import { Login } from '../../../components/Header/components/Logon/Login';
import { CreateTelegramTempTokenBotton } from '../../Profile/components/CreateTelegramTempTokenBotton';
import { AddDevice } from '../../Profile/components/AddDevice';

export const RegistrationStepper: FC = React.memo(() => {

    const { Text } = Typography;

    const userData = useContext(UserContext);
    const { setLoaderState } = useContext(LoaderContext);
    const [familyData, setFamilyData] = useState<FamilyContract>();

    const { t } = useTranslation();

    const [current, setCurrent] = useState(0);

    const onChange = (value: number) => {

        if (!userData.isAutorized) {
            setCurrent(0);
            return;
        }

        setCurrent(value);
    };

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true)

            const response = await Api.tryGet<FamilyContract>(`family`)

            if (!response.result)
                return

            setFamilyData(response.result)

        } catch (err) {

        } finally {
            setLoaderState(false)
        }

    }, [setLoaderState])

    const onAddDevice = async (device: AddDeviceContract) => {

        setLoaderState(true)

        try {
            const response = await Api.post<AddDeviceContract, FamilyDevice>(`devices`, device)

            if (response.ok) {
                await dataFetch();
                message.success("Устройство подключено");
                return;
            }

            message.error(t('profile.add_device.error'));
        }
        catch {
            message.error(t('profile.add_device.error'));
        }
        finally {
            setLoaderState(false)
        }
    }

    useEffect(() => {
        dataFetch()
    }, [dataFetch])

    return (<>

        <div>&nbsp;</div>

        <ContentTitle title="Начать пользоваться" />

        <Steps
            current={current}
            onChange={onChange}
            items={[
                {
                    title: 'Вход',
                    status: userData.isAutorized ? "finish" : "process",
                },
                {
                    title: 'Добавить родных',
                    status: userData.isAutorized ? undefined : "wait",
                    // status: (familyData?.familyItems?.length ?? 0) > 1 ? "finish" : "error", 
                },
                {
                    title: 'Добавить Алису (если есть)',
                    status: userData.isAutorized ? undefined : "wait",
                    // status: (familyData?.devices?.length ?? 0) > 0 ? "finish" : "error",

                },
                {
                    title: 'Готово',
                    status: userData.isAutorized ? undefined : "wait",
                },
            ]}
        />
        <Card className="step-card">
            {current === 0 &&
                <>
                    {!userData.isAutorized && <Login />}
                {userData.isAutorized && <Text strong type="success">Вы уже вошли в систему</Text>}
                </>
            }

            {current === 1 &&
                <Space direction="vertical">
                    <Text strong>Добавьте свою учетную запись телеграмм и своих близких: (для каждого подключения требуется отдельная ссылка)</Text>
                    <Text strong>1. Нажмите кнопку "Подключить уч. запись телеграмм"</Text>
                    <Text strong>2. Скопируйте и отправьте ссылку родным.</Text>
                    <Text strong>3. Необходимо перейти по ссылки и в телеграмм нажать кнопку "старт".</Text>
                    <Text strong type="success">Сейчас у Вас в семье: {familyData?.familyItems.map(a => a.name).join(', ')}</Text>
                    <CreateTelegramTempTokenBotton dataFetch={dataFetch} />
                </Space>
            }

            {current === 2 &&
                <Space direction="vertical">
                    <Text type="danger">Если у Вас нет устройства с Алисой, пропустите этот шаг.</Text>
                    <Text strong>1. Скажите: Алиса, запусти навык семейные покупки (Произойдет запуск навыка)</Text>
                    <Text strong>2. После запуска скажите "Подключение к семье", в ответ Алиса скажет ИД и пароль подключения (позже она его повторит)</Text>
                    <Text strong>3. Введите ИД и пароль, который сказала Алиса в окно ниже</Text>
                    <Text strong type="success">Подключенные устройства: {familyData?.devices.map(a => a.name).join(', ')}</Text>
                    <AddDevice onAddDevice={onAddDevice} />
                </Space>
            }

            {current === 3 &&
                <Text strong type="success">
                    Настройка завершена! Можете начать с добавления покупок.
                </Text>
            }
        </Card>

    </>)
})
