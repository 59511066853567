import React, { FC } from 'react'
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { BudgetResponse } from '../../../api';
import { useTranslation } from 'react-i18next';

export interface StaticDoneModalProps {
    budgetResponse: BudgetResponse;
    onCancel: () => void;
    onFinish: (value: any) => void;
    currentSums: BudgetResponse[];
}

export const StaticDoneModal: FC<StaticDoneModalProps> = React.memo(({ budgetResponse, onCancel, onFinish, currentSums }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const [form] = Form.useForm();

    const onCurrentSumIdChange = (value: string) => {
        form.setFieldsValue({ currentSumId: value });
    };

    const { Option } = Select;

    return (
        <>
            <Modal
                title="Title"
                open={true}
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        {t('cancel')}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    onFinish(values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        {t('ok')}
                    </Button>
                ]} >
                <Form
                    name="staticDoneForm"
                    initialValues={budgetResponse || {}}
                    layout="vertical"
                    form={form}
                >
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item name="date" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t('budget.title')}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: tt('field_required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t('budget.amount')}
                        name="amount"
                    >
                        <InputNumber type="number" />
                    </Form.Item>

                    <Form.Item
                        name="currentSumId"
                        label={t('budget.select_current_sum_')}
                    >
                        <Select
                            placeholder={t('budget.select_current_sum_')}
                            onChange={onCurrentSumIdChange}
                            allowClear
                        >
                            {
                                currentSums.map(a => <Option value={a.id}>{`${a.title} ${a.amount}`}</Option>)
                            }
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>

        </>
    )
})
