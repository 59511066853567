import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Button, InputNumber, Popconfirm, Select, message } from 'antd'

import { useTranslation } from 'react-i18next';
import { Api, BudgetCurrentSumResponse, CompleteShoppingRequest, NoContentResponse, ProductListContract, PurchaseCategoryResponse, PurchaseUpdateContract } from '../../../api';
import { LoaderContext } from '../../../contexts';
import { ProductDto } from '../Products.model';
import { NavLink } from 'react-router-dom';


export interface FinishShoppingProps {
    setProductsOrderingData: (products: ProductDto[]) => void;
    budgetCurrentSums: BudgetCurrentSumResponse[]
    productListId: string | undefined
}

export const FinishShopping: React.FC<FinishShoppingProps> = React.memo(({ setProductsOrderingData,
    budgetCurrentSums, productListId }) => {
    const { t } = useTranslation();
    const { setLoaderState } = useContext(LoaderContext)
    const [finishShoppingForm] = Form.useForm()

    const [purchaseAmountData, setPurchaseAmountData] = useState<number | string | undefined | null>()
    const [purchaseCategoryData, setPurchaseCategoryData] = useState<PurchaseCategoryResponse[]>([]);
    const [productListData, setProductListData] = useState<ProductListContract>();

    const dataFetchPurchaseCategories = useCallback(async () => {

        setLoaderState(true)

        const response = await Api.get<PurchaseCategoryResponse[]>(`purchase-categories`);

        setLoaderState(false)

        if (!response.ok || response.result === null)
            return

        setPurchaseCategoryData(response.result.sort((a, b) => ('' + a.name).localeCompare(b.name)) ?? []);

    }, [setLoaderState])

    const dataFetchProductList = useCallback(async () => {

        if (!productListId)
            return;

        setLoaderState(true)

        const response = await Api.get<ProductListContract>(`productlists/${productListId}`);

        setLoaderState(false)

        if (!response.ok || response.result?.defaultPurchaseCategoryId === null)
            return

        finishShoppingForm.setFieldsValue({ purchaseCategoryId: response.result?.defaultPurchaseCategoryId });

    }, [setLoaderState, productListId, finishShoppingForm])

    const handlePurchaseFinish = useCallback(
        async (values: Partial<CompleteShoppingRequest>) => {

            const completeShoppingRequest = { ...values } as CompleteShoppingRequest;

            setLoaderState(true)

            const result = await Api.patch<CompleteShoppingRequest, NoContentResponse>(`products/complete_shopping`, completeShoppingRequest);

            setLoaderState(false)

            if (!result.ok) {
                message.error(`Ошибка: '${result.exceptionResult.Message}'`);
                return;
            }

            setProductsOrderingData([])

            finishShoppingForm.resetFields();
        },
        [finishShoppingForm, setLoaderState, setProductsOrderingData]
    )

    const onCurrentSumIdChange = (value: string) => {
        finishShoppingForm.setFieldsValue({ budgetCurrentSumId: value });
    };

    const onPurchaseCategoryChange = (value: string) => {
        finishShoppingForm.setFieldsValue({ purchaseCategoryId: value });
    };

    const { Option } = Select;

    useEffect(() => {
        dataFetchPurchaseCategories();
        dataFetchProductList();
    }, [dataFetchPurchaseCategories, dataFetchProductList])

    return (
        <Form
            name="finishShoppingForm"
            initialValues={{} as PurchaseUpdateContract}
            onFinish={handlePurchaseFinish}
            form={finishShoppingForm}

        >
            <Form.Item >
                <NavLink to={`/discount-cards`} className="link">
                    {t('header.menu.discount-cards')}
                </NavLink>
            </Form.Item>

            <Form.Item
                label={t('product.amount')}
                name="amount"
            >
                <InputNumber style={{ width: "100%" }} type="number" onChange={(e) => setPurchaseAmountData(e)} />
            </Form.Item>

            <Form.Item
                label={t('product.budget_current_sum')}
                name="budgetCurrentSumId"
            >
                <Select
                    placeholder={t('budget.select_current_sum_')}
                    onChange={onCurrentSumIdChange}
                    allowClear
                >
                    {
                        budgetCurrentSums.map(a => <Option key={a.id} value={a.id}>{`${a.title} ${a.amount}`}</Option>)
                    }
                </Select>
            </Form.Item>

            <Form.Item
                label={<NavLink to={`/purchase-categories`} className="link">
                    {t('purchases.purchase_category')}
                </NavLink>}
                name="purchaseCategoryId"
                initialValue={productListData?.defaultPurchaseCategoryId}
            >
                <Select
                    placeholder={t('purchases.purchase_category')}
                    onChange={onPurchaseCategoryChange}
                    allowClear

                >
                    {
                        purchaseCategoryData.map(a => <Option key={a.id} value={a.id} >{a.name}</Option>)
                    }
                </Select>
            </Form.Item>

            <Form.Item >
                {purchaseAmountData === undefined
                    || purchaseAmountData === null
                    || purchaseAmountData === ''
                    ? <Popconfirm
                        title={t('products.purchase_text')}
                        okText={t('yes')}
                        onConfirm={() => finishShoppingForm.submit()}

                        cancelText={t('no')}>
                        <Button type="primary" className="button-purchase" htmlType="submit">
                            {t('products.purchase')}
                        </Button>
                    </Popconfirm>
                    : <Button type="primary" className="button-purchase" htmlType="submit" onClick={() => { setPurchaseAmountData(undefined); finishShoppingForm.submit(); }}>
                        {t('products.purchase')}
                    </Button>
                }
            </Form.Item>
        </Form>
    )
})
