import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { LoaderContext, UserContextProps } from '..'
import { UserContext } from './UserContext'
import { ProfileContract, Api } from '../../api'
import { UserContextProviderProps } from './UserContextProviderProps'

export const UserContextProvider: FC<UserContextProviderProps> = React.memo(({ children }) => {
    const { setLoaderState } = useContext(LoaderContext)
    const [userData, setUserData] = useState<UserContextProps>({
        isRequested: false,
        isAutorized: false,
        userName: '',
        loginProviderName: '',
        isAvailableEvents: false,
        familyFeatures: 0,
    })

    const checkAuth = useCallback(async () => {
        if (userData.isAutorized) {
            return
        }

        try {
            setLoaderState(true)
            const response = await Api.tryGet<ProfileContract>(`profiles`)
            setUserData({
                isRequested: true,
                isAutorized: response.ok,
                userName: response.result?.name,
                loginProviderName: response.result?.loginProviderName,
                isAvailableEvents: response.result?.isAvailableEvents ?? false,
                familyFeatures: response.result?.familyFeatures ?? 0,
             })
        } catch (e) {
            console.log(e)
        } finally {
            setLoaderState(false)
        }
    }, [userData, setLoaderState, setUserData])

    useEffect(() => {
        checkAuth()
    }, [checkAuth])

    return (
        <UserContext.Provider value={{ ...userData }}>
            {userData && children}
        </UserContext.Provider>
    )
})
