import React, { FC } from 'react'
import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { BudgetResponse } from '../../../api';
import { useTranslation } from 'react-i18next';

export interface CorrectStaticSumModalProps {
    budgetResponse: BudgetResponse;
    onCancel: () => void;
    onFinish: (value: any) => void;
}

export const CorrectStaticSumModal: FC<CorrectStaticSumModalProps> = React.memo(({ budgetResponse, onCancel, onFinish }) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    return (
        <>
            <Modal
                title="Title"
                open={true}
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        {t('cancel')}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    onFinish(values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        {t('ok')}
                    </Button>
                ]} >
                <Form
                    name="staticDoneForm"
                    initialValues={budgetResponse || {}}
                    layout="vertical"
                    form={form}
                >
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item name="date" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t('budget.amount')}
                        name="amount"
                    >
                        <InputNumber type="number" />
                    </Form.Item>

                </Form>
            </Modal>

        </>
    )
})
