import React, {
    FC, useCallback, useContext, useEffect, useState,
} from 'react'

import 'antd/dist/reset.css';
import { useTranslation } from 'react-i18next';
import { ProductDto, ProductState } from '../../Products/Products.model';
import { LoaderContext } from '../../../contexts';
import { Api, BudgetResponse, BudgetType } from '../../../api';
import { NavLink } from 'react-router-dom';
import { Card, List, message, Timeline, TimelineItemProps, Typography } from 'antd';
import { ContentTitle, ShortDate } from '../../../components';

export interface AuthorizedProductsProps {
    anyData: (hasData: boolean) => void;
}

export const AuthorizedProducts: FC<AuthorizedProductsProps> = React.memo(({ anyData }) => {

    const { t } = useTranslation();
    const { Text } = Typography;

    const { setLoaderState } = useContext(LoaderContext)

    const [timelineData, setTimelineData] = useState<TimelineItemProps[]>([])

    const dataFetch = useCallback(async () => {

        const listId = ''; // TODO

        setLoaderState(true)

        const productsResponse = await Api.get<ProductDto[]>(`products?ListId=${listId}`);

        setLoaderState(false)

        if (!productsResponse.ok || productsResponse.result === null) {
            message.error(productsResponse.exceptionResult?.Message ?? 'Ошибка получения данных');
            return;
        }

        const products = productsResponse.result
            .filter(a => a.state === ProductState.New)
            .sort((a, b) => (a.createDate > b.createDate ? -1 : 1))
            .slice(0, 5);

        const productsData = products.length > 0 ? [{
            label: <ShortDate key={-1} date={new Date()} />,
            children:
                <List
                    header={t('home.authorized_content.products.now_in_product_lists')}
                    size="small"
                    bordered
                    dataSource={products}
                    renderItem={(item) => (
                        <List.Item>
                            <NavLink to={`/product-lists/${item.listId}/products`} key={item.id} className="link">
                                {` ${item.name} `}
                            </NavLink>
                        </List.Item>
                    )}
                />,
        } as TimelineItemProps]
        : [];

        setLoaderState(true)

        const budgetResponse = await Api.get<BudgetResponse[]>(`budget`);

        setLoaderState(false)

        if (!budgetResponse.ok || budgetResponse.result === null) {
            message.error(budgetResponse.exceptionResult?.Message ?? 'Ошибка получения данных')
            return
        }

        const budgetData = budgetResponse.result
            .filter(a => a.budgetType !== BudgetType.Current && !a.notRequired)
            .slice(0, 10).map(item => (
                {
                    label: <ShortDate key={item.id} date={item.date} />,
                    children:
                        <Text delete={item.done}>
                            <NavLink to={`/budgets`} key={item.id} className="link">
                                {item.title} {item.amount}
                            </NavLink>
                        </Text>
                }));

        let data = productsData.concat(budgetData);

        const productInBasket = productsResponse.result.find(a => a.state === ProductState.Ordering);
        if (productInBasket) {
            data = [{
                label: <ShortDate key={-1} date={new Date()} />,
                children:
                    <NavLink to={`/product-lists/${productInBasket.listId}/products`} key={productInBasket.id} className="link">
                        <Text type="danger">{t('home.authorized_content.products.now_in_basket')} {productInBasket.name}</Text>
                    </NavLink>
            } as TimelineItemProps].concat(data);
        }

        anyData(data.length > 0);

        setTimelineData(a => data);

    }, [setLoaderState, Text, t, anyData])

    useEffect(() => {
        dataFetch();
    }, [dataFetch])

    return (
        <>
            <ContentTitle title={t('home.authorized_content.timeline_title')} />

            <Card>
                <Timeline
                    mode='left'
                    items={timelineData}
                />
            </Card>
        </>
    )
})
