import './AuthorizedContent.scss'

import React, {
    FC, useState,
} from 'react'

import 'antd/dist/reset.css';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthorizedProducts } from './AuthorizedProducts';
import { RegistrationStepper } from './RegistrationStepper';

export const AuthorizedContent: FC = React.memo(() => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [hasData, setHasData] = useState<boolean>();

    return (<>
        {!hasData && <>
            <RegistrationStepper />

            <Divider />
        </>}

        <AuthorizedProducts anyData={setHasData} />
    </>)
})
