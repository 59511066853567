import './Profile.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'

import 'antd/dist/reset.css';
import { PageContent, ContentTitle } from '../../components';
import { LoaderContext } from '../../contexts';
import { AddDeviceContract, Api, FamilyContract, FamilyDevice } from '../../api';
import QRCode from 'qrcode.react';
import { QrReader } from 'react-qr-reader'
import { Modal, Button, Popconfirm, message, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { AddDevice } from './components/AddDevice';
import { CreateTelegramTempTokenBotton } from './components/CreateTelegramTempTokenBotton';

export const Profile: FC = React.memo(() => {
    const { t } = useTranslation();

    const [familyData, setFamilyData] = useState<FamilyContract | null>(null)
    const [modalScanData, setModalScanData] = useState<boolean>(false)
    const { setLoaderState } = useContext(LoaderContext)

    const dataFetch = useCallback(async () => {

        try {
            setLoaderState(true)

            const response = await Api.get<FamilyContract>(`family`)

            if (!response.result)
                return

            setFamilyData(response.result)

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false)
        }

    }, [setLoaderState])

    useEffect(() => {
        dataFetch()
    }, [dataFetch, setFamilyData, setLoaderState])

    const handleScan = async (data: any) => {
        if (data === null)
            return

        const request = JSON.parse(data)

        const result = await Api.putWithoutResponse(`family`, request)

        setModalScanData(false)

        if (result) {
            Modal.success({
                content: 'You have joined to family.',
            });
        }
        else {
            Modal.error({
                title: 'Error',
                content: 'Something went wrong',
            });
        }
    }

    const handleError = (err: any) => {
        console.error(err)
    }

    const handleLogout = async () => {
        const result = await Api.delete(`auth/`);

        if (result) {
            window.location.href = '/';
        }
        else {
            Modal.error({
                title: 'Error',
                content: 'Something went wrong',
            });
        }
    }

    const onAddDevice = async (device: AddDeviceContract) => {

        setLoaderState(true)

        try {
            const response = await Api.post<AddDeviceContract, FamilyDevice>(`devices`, device)

            if (response.ok) {
                message.success(t('profile.add_device.success'));
                return;
            }

            message.error(t('profile.add_device.error'));
        }
        catch {
            message.error(t('profile.add_device.error'));
        }
        finally {
            setLoaderState(false)
        }
    }

    return (
        <PageContent>
            <ContentTitle title={t('profile.title')} />
            <div className="profile">

                <div className="profile-item">
                    <div className="element-title">{t('logout')}</div>
                    <div className="element-content">
                        <Popconfirm
                            title={t('profile.logout_confirm_text')}
                            onConfirm={() => handleLogout()}
                            okText={t('yes')}
                            cancelText={t('no')}>
                            <Button type="link">{t('logout')}</Button>
                        </Popconfirm>
                    </div>
                </div>

                <div className="profile-item">
                    <div className="element-title">{t('profile.surname')}</div>
                    <div className="element-content">{familyData?.surname}</div>
                </div>

                <div className="profile-item">
                    <div className="element-title">{t('profile.your_family')}</div>
                    <div className="element-content">
                        <Space direction='vertical'>
                            <div>{familyData?.familyItems.map(a => a.name).join(', ')}</div>
                            <CreateTelegramTempTokenBotton dataFetch={dataFetch} />
                        </Space>
                    </div>
                </div>

                <div className="profile-item">
                    <div className="element-title">{t('profile.devices')}</div>
                    <div className="element-content">1. Скажите: Алиса, запусти навык семейные покупки (Произойдет запуск навыка)</div>
                    <div className="element-content">2. После запуска скажите "Подключение к семье", в ответ Алиса скажет ИД и пароль подключения (позже она его повторит)</div>
                    <div className="element-content">3. Введите ИД и пароль, который сказала Алиса в окно ниже</div>
                    <div className="element-content">Подключенные устройства: {familyData?.devices.map(a => a.name).join(', ')}</div>
                    <div className="profile-item">
                        <AddDevice onAddDevice={onAddDevice} />
                    </div>
                </div>

                <div className="profile-item">
                    <div className="element-title">{t('profile.qr')}</div>
                    <div className="element-content">
                        <QRCode
                            renderAs="svg"
                            size={200}
                            value={JSON.stringify({ id: familyData?.id, password: familyData?.password })} />
                    </div>
                </div>

                <div className="profile-item">
                    <div className="element-title">{t('profile.join_to_family')}</div>
                    <div className="element-content">
                        <Popconfirm
                            title={t('profile.join_to_family_confirm_text')}
                            onConfirm={() => setModalScanData(true)}
                            okText={t('yes')}
                            cancelText={t('no')}>
                            <Button type="link">{t('scan_qr')}</Button>
                        </Popconfirm>
                    </div>
                </div>

                <Modal
                    title={t('scan_qr')}
                    open={modalScanData}
                    onOk={() => { setModalScanData(false) }}
                    onCancel={() => { setModalScanData(false) }}
                >
                    <QrReader
                        constraints={{ facingMode: 'user' }}
                        onResult={(result, error) => {
                            if (!!result) {
                                handleScan(result?.getText());
                            }

                            if (!!error) {
                                handleError(error);
                            }
                        }}
                    />
                </Modal>

            </div>
        </PageContent>


    )
})
