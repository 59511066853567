import './EventPhotoUploader.scss'

import React, { useContext, useState } from 'react'

import { EventPhotoUploaderProps } from './EventPhotoUploader.model'
import { useTranslation } from 'react-i18next';
import { LoaderContext } from '../../contexts';
import { message } from 'antd';
import { Api } from '../../api';

export const EventPhotoUploader: React.FC<EventPhotoUploaderProps> = React.memo(({ eventId, uploadCallback, editKey }) => {

    const { t } = useTranslation();

    const { setLoaderState } = useContext(LoaderContext);

    const [files, setFiles] = useState<any>([]);

    const upload = async (b: any) => {

        setLoaderState(true);

        try {
            for (var f of files) {
                const r = await Api.sendFile<any>(`events/${eventId}/photos?editKey=${editKey}`, f);

                if (r.ok)
                    message.success(`Файл ${f.name} загружен`);
                else
                    message.error(`Ошибка загрузки файла ${f.name}. ${r.exceptionResult?.Message}`);
            };
        } catch {

        }

        setLoaderState(false);
        uploadCallback();
    }

     const handleChange = (event:any) => {
        const filesUpload = event.target.files;

         if (filesUpload.length > 0) {
             setFiles(filesUpload);
         }
         else {
             setFiles([]);
             message.info('Выберите файлы');
         }
    };

    return (
        <div className="content-element">
            <form className="uploader" encType="multipart/form-data" >
                <input type="file" multiple name="file" className="upload-file" onChange={handleChange} />
                {files.length > 0 && (<input type="button" value={`${t('download')}`} onClick={upload} />)}
            </form>
        </div>
    )
})
