import './EventCreate.scss'

import React, { FC, useCallback, useEffect } from 'react'
import { Form, Input, Button } from 'antd'
import { EventCreateProps } from './EventCreate.model';
import { EventCreateContract } from '../../../api';
import { useTranslation } from 'react-i18next';

export const EventCreate: React.FC<EventCreateProps> = React.memo(({ eventCreate, onAddEvent }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const [form] = Form.useForm()

    const handleFinish = useCallback(
        (values: Partial<EventCreateContract>) => {
            console.log(213);

            onAddEvent(values as EventCreateContract)

            form.resetFields();
        },
        [form, onAddEvent]
    )

    useEffect(() => {
        form.resetFields()
    }, [form, eventCreate])

    return (
        <Form
            className="form"
            name="eventsCreateForm"
            initialValues={eventCreate}
            onFinish={handleFinish}
            form={form}
        >
            <Form.Item
                hidden={true}
                name="date"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('events.create.name')}
                name="name"
                rules={[
                    {
                        required: true,
                        message: tt('events.create.name_required_message'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item >
                <Button
                    form="eventsCreateForm"
                    type="primary"
                    htmlType="submit"
                    className="button"
                >
                   {t('events.create.bt_create')}
                </Button>

            </Form.Item>
        </Form>
    )
})
