import './PhotoUploader.scss'

import React, { useContext } from 'react'

import { PhotoUploaderProps } from './PhotoUploader.model'
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { DraggerProps } from 'antd/lib/upload/Dragger';
import { API_BASE_URL } from '../../consts';
import { LoaderContext } from '../../contexts';
import { Upload } from 'antd';

export const PhotoUploader: React.FC<PhotoUploaderProps> = React.memo(({ url, uploadCallback }) => {
    const { t } = useTranslation();

    const { setLoaderState } = useContext(LoaderContext);

    const { Dragger } = Upload;

    const uploadProps: DraggerProps = {
        name: 'file',
        multiple: true,
        action: `${API_BASE_URL}/api/v1/${url}`,
        showUploadList: false,

        onChange(info: any) {
            const { status } = info.file;
            if (status === 'uploading') {
                setLoaderState(true);
            }
            else {
                setLoaderState(false);
                uploadCallback();
            }

            if (status === 'done') {
                console.log('onChange', `${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log('onChange', `${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('event.dragger.title')}</p>
            <p className="ant-upload-hint">{t('event.dragger.description')}</p>
        </Dragger>
    )
})
