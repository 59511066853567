import React from 'react'

import { LoaderContextProps } from './LoaderContextProps'

export const LoaderContext = React.createContext<LoaderContextProps>({
    loaderState: false,
    setLoaderState: (state) => {
        
    },
})
