import React, { useState } from 'react'
import { useBarcode } from '@createnextapp/react-barcode';
import { Slider, Space } from 'antd';

export interface BarcodeProps {
    value?: string
}

export const Barcode: React.FC<BarcodeProps> = React.memo(({ value }) => {

    const [inputValue, setInputValue] = useState<number>(3);

    const { inputRef } = useBarcode({
        value: value ?? '',
        options: {
            background: '#ffffff',
            width: inputValue
        },
    });

    const onChange = (value: number) => {
        if (isNaN(value)) {
            return;
        }

        setInputValue(value);
    };

    return <Space direction='vertical' style={{ width: 400 }}>
        <div>
            <svg ref={inputRef} />
        </div>
        <Slider
            min={1}
            max={4}
            onChange={onChange}
            value={typeof inputValue === 'number' ? inputValue : 0}
            step={0.3}
        />
    </Space>;
}
)
