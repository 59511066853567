import './PhotoList.scss'

import React from 'react'

import { PhotoListProps } from './PhotoList.model'
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Image, List, Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const PhotoList: React.FC<PhotoListProps> = React.memo(({ photos, onDeletePhoto, className }) => {
    const { t } = useTranslation();

    const onDelPhoto = (id: string) => {
        if (onDeletePhoto !== undefined)
            onDeletePhoto(id);
    }

    return (
        <>
            <List className={className}
                grid={{
                    gutter: 30,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3,
                }}
                dataSource={photos}
                renderItem={item => (
                    <List.Item className={'list-item-without-body'}>
                        <Card
                            style={{ width: 300 }}
                            cover={
                                <Image
                                    alt={item.originFileName}
                                    src={item.fileName}
                                />
                            }
                            actions={[
                                <Popconfirm
                                    title={t('event_photos.delete_confirm')}
                                    onConfirm={() => onDelPhoto(item.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}>
                                    <Button type="link" icon={< DeleteOutlined />}>{t('delete')}</Button>
                                </Popconfirm>,
                            ]}
                        />
                    </List.Item>
                )}
            />
        </>
    )
})
