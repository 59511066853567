import './Event.scss'

import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react'


import { PageContent, ContentTitle, EventPhotos, EventPhotoUploader } from '../../components';
import { useParams } from 'react-router-dom';
import { LoaderContext } from '../../contexts';
import { Api, EventDetailContract } from '../../api';
import { EventUpdate, EventView } from './components';
import { useTranslation } from 'react-i18next';

export const Event: FC = React.memo(() => {
    const { t } = useTranslation();

    const urlParams = useParams()

    const [eventData, setEventData] = useState<EventDetailContract | null>(null)
    const { setLoaderState } = useContext(LoaderContext)

    const dataFetch = useCallback(async () => {
        try {
            setLoaderState(true);

            const response = await Api.get<EventDetailContract>(`events/${urlParams?.id}`)

            if (!response.ok || !response.result)
                return

            setEventData(response.result)

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [urlParams, setLoaderState])

    useEffect(() => {
        dataFetch()
    }, [dataFetch])

    

    const onDeletePhoto = async (id: string) => {

        try {
            setLoaderState(true);

            const response = await Api.delete(`events/${urlParams?.id}/photos/${id}`);

            if (!response)
                return;

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }

        await dataFetch(); // TODO without reload;
    }

    return (
        <PageContent>

            <ContentTitle title={`${t('event.title')} ${eventData?.name}`} />

            {eventData?.isOur
                ? (<EventUpdate eventProps={eventData} refresh={() => { }} />)
                : (<EventView eventProps={eventData} />)}

            <EventPhotos
                eventId={eventData?.id ?? ''}
                photos={eventData?.photos ?? []}
                className="content-element"
                onDeletePhoto={onDeletePhoto}
                viewKey={''}
                editKey={''}
                setTitlePhotoAvailable={eventData?.isOur ?? true}
                dataFetch={dataFetch}
            />

            {eventData?.canUploadPhoto ?
                <EventPhotoUploader eventId={urlParams?.id ?? ''} uploadCallback={dataFetch} />
                : <></>}
        </PageContent>
    )
})
