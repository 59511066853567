import './BackTo.scss'

import React from 'react'

import { BackToProps } from './BackTo.model'
import { NavLink } from 'react-router-dom'

export const BackTo: React.FC<BackToProps> = React.memo(({ text, to }) => {
    return (
        <div className='back-to'>
            <NavLink to={to}>
                {`<-${text}`}
            </NavLink>
        </div>
    )
})
