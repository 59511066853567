import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Form, List, message, Popconfirm, Space, Typography } from 'antd'
import { Api, ProductCategoryContract, ProductCategoryNameContract } from '../../../api';
import { LoaderContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import Table from 'antd/lib/table';
import Column from 'antd/es/table/Column';
import { DeleteOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';


export interface ProductCategoryNamesProps {
    productCotegoryId?: string
}

export interface FormProps {
    names?: string
}

export const ProductCategoryNames: React.FC<ProductCategoryNamesProps> = React.memo(({ productCotegoryId }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const { Link, Title, Text } = Typography;
    const { setLoaderState } = useContext(LoaderContext)
    const [data, setData] = useState<ProductCategoryContract>();
    const [form] = Form.useForm<FormProps>()

    const dataFetch = useCallback(async (id?: string) => {

        if (!id)
            return;

        try {
            setLoaderState(true);

            const response = await Api.get<ProductCategoryContract>(`admin/ProductCategories/${id}`)

            if (!response.ok || !response.result)
                return

            setData(response.result);
        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }, [setLoaderState])

    const onDelete = async (nameId: string) => {
        try {
            setLoaderState(true);

            const response = await Api.deleteWithoutResult(`admin/ProductCategories/${data?.id}/Names/${nameId}`);

            if (!response.ok) {
                message.error(response.exceptionResult?.Message ?? 'Error on delete');
                return;
            }

            await dataFetch(productCotegoryId);

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }

    const onMark = async (nameId: string) => {
        try {
            setLoaderState(true);

            const response = await Api.patchWithoutRequestBody(`admin/ProductCategories/${data?.id}/Names/${nameId}/mark`);

            if (!response.ok) {
                message.error(response.exceptionResult?.Message ?? 'Error on mark');
                return;
            }

            await dataFetch(productCotegoryId);

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }

    const onUnMark = async (nameId: string) => {
        try {
            setLoaderState(true);

            const response = await Api.patchWithoutRequestBody(`admin/ProductCategories/${data?.id}/Names/${nameId}/unmark`);

            if (!response.ok) {
                message.error(response.exceptionResult?.Message ?? 'Error on unmark');
                return;
            }

            await dataFetch(productCotegoryId);

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false);
        }
    }

    const handleFinish = useCallback(
        async (values: Partial<FormProps>) => {
            const names = (values as FormProps).names?.split('\n');

            if (!names) {
                message.error("Add values");
                return;
            }

            try {
                setLoaderState(true);

                const response = await Api.post<string[], ProductCategoryNameContract[]>(`admin/ProductCategories/${data?.id}/Names`, names);

                if (!response.ok || !response.result) {
                    message.error(response.exceptionResult?.Message ?? 'Error on delete');
                    return
                }
                    

                dataFetch(data?.id)

            } catch (err) {
                console.error(err)
            } finally {
                setLoaderState(false);
            }

            form.resetFields();
        },
        [form, data, dataFetch, setLoaderState]
    )

    useEffect(() => {
        dataFetch(productCotegoryId)
    }, [dataFetch, productCotegoryId])

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }} >
            <Title level={4} >{data?.name}</Title>
            {productCotegoryId && <Form
                className="form"
                name="ProductCategoryNameCreateForm"
                onFinish={handleFinish}
                form={form}
            >
                <Form.Item
                    label="Names"
                    name="names"
                    rules={[
                        {
                            required: true,
                            message: tt('events.create.name_required_message'),
                        },
                    ]}
                >
                    <TextArea rows={6} />
                </Form.Item>

                <Form.Item >
                    <Button
                        form="ProductCategoryNameCreateForm"
                        type="primary"
                        htmlType="submit"
                        className="button"
                    >
                        {t('add')}
                    </Button>

                </Form.Item>
            </Form>}


            <Table<ProductCategoryNameContract> dataSource={data?.products?.sort((a, b) => (Number(b.isDraft) - Number(a.isDraft)))}
                pagination={false}
                rowKey='id'
            >
                <Column<ProductCategoryNameContract>
                    title={t('product_category.name')}
                    dataIndex="name"
                    key="name"
                />

                <Column<ProductCategoryNameContract>
                    title={t('product_category.isDraft')}
                    dataIndex="isDraft"
                    key="isDraft"
                    render={(text, item) => (
                        <>{item.isDraft ? <Text underline strong type="danger">draft</Text> : <Text type="success">not draft</Text>}</>
                    )}
                />

                <Column<ProductCategoryNameContract>
                    title={t('product_category.createDate')}
                    dataIndex="createDate"
                    key="createDate"
                />

                <Column<ProductCategoryNameContract>
                    title={t('actions')}
                    key="action"
                    render={(item) => item.id !== 'all' ?
                        <Space size={'large'}>
                            {item.isDraft && <CheckOutlined onClick={() => onMark(item.id)} />}
                            {!item.isDraft && <CloseOutlined onClick={() => onUnMark(item.id)} />}

                            <Popconfirm
                                title={t('product_list.delete_text')}
                                onConfirm={() => onDelete(item.id)}
                                okText={t('yes')}
                                cancelText={t('no')}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Space>

                        : <></>}
                />
            </Table>
           
        </Space>
    )
})
