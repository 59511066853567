import React, {
    FC,
    useEffect,
    useState,
} from 'react'

import { Table, Space, Popconfirm, Typography } from 'antd';
import { BudgetResponse, BudgetType } from '../../../api';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { CheckOutlined, DeleteOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons';
import { DateText } from './DateText';
import { CreditCard } from './CreditCard';

export interface TableCurrentProps {
    data: BudgetResponse[];
    formatDate: (date: Date, index: number) => string;
    confirmCurrentSum: (budgetResponse: BudgetResponse) => void;
    updateData: (budgetResponse: BudgetResponse) => void;
    transerCurrentSumShowModal: (budgetResponse: BudgetResponse) => void;
    onDelete: (id: string) => void;
    budgetTypeToName: (budgetType: BudgetType) => string;
}

export const TableCurrent: FC<TableCurrentProps> = React.memo(({ data,
    formatDate,
    confirmCurrentSum,
    updateData,
    onDelete,
    budgetTypeToName,
    transerCurrentSumShowModal }) => {
    const { t } = useTranslation();

    const { Text } = Typography;

    const [budgetData, setBudgetData] = useState<BudgetResponse[]>();
    const [sumData, setSumData] = useState<number>();

    const formatLast4DigitsOfCard = (budgetResponse: BudgetResponse) => {
        if (budgetResponse.last4DigitsOfCard)
            return `(${budgetResponse.last4DigitsOfCard})`

        return '';
    }

    useEffect(() => {
        const d = data.filter(a => a.budgetType === BudgetType.Current);
        setBudgetData(d);
        setSumData(d.reduce((partialSum, a) => partialSum + a.amount, 0))
    }, [data])

    return (
        <>
            <Table<BudgetResponse> dataSource={budgetData}
                pagination={false}
                rowKey={(item) => `${item.id}_${item.date}`}
                className='purchases_table'
                title={() => <h4>{budgetTypeToName(BudgetType.Current)}</h4>}
                footer={() => `${t('total')}: ${sumData}`}
            >
                <Column<BudgetResponse> title={t('budget.date')}
                    dataIndex="date"
                    key="date"
                    responsive={["sm"]}
                    render={(d, item, index) =>
                    (
                        <DateText date={item.date} formatedDate={formatDate(item.date, index)} />
                    )}
                />

                <Column<BudgetResponse> title={t('budget.title')}
                    dataIndex="title"
                    key="title"
                    responsive={["sm"]}
                />

                <Column<BudgetResponse> title={t('budget.short_amount')}
                    dataIndex="amount"
                    key="amount"
                    responsive={["sm"]}
                    render={(a, item) => {
                        return (
                            <Space direction="horizontal">
                                <Text>{item.amount}</Text>
                                <CreditCard budgetResponse={item} />
                            </Space>
                        )
                    }}
                />

                <Column<BudgetResponse> title={t('budget.last4DigitsOfCard')}
                    dataIndex="last4DigitsOfCard"
                    key="last4DigitsOfCard"
                    responsive={["sm"]}
                />

                <Column<BudgetResponse> title={t('budget.data')}
                    dataIndex="date"
                    key="date"
                    responsive={["xs"]}
                    render={(d, item, index) => (
                        <DateText date={item.date} formatedDate={formatDate(item.date, index)} >{item.title}: {item.amount} {formatLast4DigitsOfCard(item)}<CreditCard budgetResponse={item} /></DateText>
                    )}
                />

                <Column<BudgetResponse>
                    title={t('actions')}
                    key="action"
                    align="right"
                    render={(text, item) => (
                        <Space>
                            <SwapOutlined onClick={() => transerCurrentSumShowModal(item)} />
                            <CheckOutlined onClick={() => confirmCurrentSum(item)} />
                            <EditOutlined onClick={() => updateData(item)} />
                            <Popconfirm
                                title={t('products.delete_text')}
                                onConfirm={() => onDelete(item.id)}
                                okText={t('yes')}
                                cancelText={t('no')}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
        </>
    )
})
