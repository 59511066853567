import React from 'react'
import { Popconfirm, Space, Table, Tag, Typography } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PurchasesProp } from '../Purchases.model';
import Column from 'antd/lib/table/Column';
import { ShortDateTime } from '../../../components';
import { PurchaseItemNames } from './PurchaseItemNames';

export interface TableDataProps {
    purchasesData: PurchasesProp[],
    onDelete: (id: string) => void;
}

export const TableData: React.FC<TableDataProps> = React.memo(({ purchasesData, onDelete }) => {
    const { t } = useTranslation();

    const { Text } = Typography;
    

    return (
        <Table<PurchasesProp> dataSource={purchasesData}
            pagination={false}
            rowKey={record => record.id}
            summary={pageData => {
                let totalAmaount = 0;


                pageData.forEach(({ amount }) => {
                    totalAmaount += amount ?? 0;
                });

                return (
                    <>
                        <Table.Summary.Row
                        >
                            <Table.Summary.Cell
                                index={0}
                            >
                                {t("total")}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                                index={1}>
                                <Text>
                                    {totalAmaount}
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        >
            <Column<PurchasesProp> title={t('purchases.item_names')}
                responsive={["sm"]}
                key="itemName"
                render={(text, item) => (
                    <PurchaseItemNames purchase={item} />
                )}
            />

            <Column<PurchasesProp> title={t('purchases.item_names')}
                responsive={["xs"]}
                key="itemName"
                render={(text, item) => (
                    <Space direction="vertical" >
                        <PurchaseItemNames purchase={item} />
                        <Text type="secondary">{`${t('purchase.amount')}: ${item.amount}`}</Text>
                        {item.purchaseCategory?.name !== undefined && <Text type="secondary">{`${t('purchases.purchase_category')}: ${item.purchaseCategory?.name}`}</Text>}
                        <Text type="secondary">{`${t('purchase.createDate')}: `}<ShortDateTime date={item.createDate} /></Text>
                        {!item.hasReceipt || (<Tag color="green" key={item.id}>{t('purchases.has_receipt')}</Tag>)}
                    </Space>
                )}
            />

            <Column<PurchasesProp> title={t('purchase.amount')}
                responsive={["sm"]}
                dataIndex="amount"
                key="amount"
            />

            <Column<PurchasesProp> title={t('purchases.purchase_category')}
                responsive={["sm"]}
                dataIndex="purchaseCategory"
                key="purchaseCategory"
                render={(text, item) => (
                    <Text type="secondary">{item.purchaseCategory?.name}</Text>
                )}
            />

            <Column<PurchasesProp> title={t('purchases.has_receipt')}
                responsive={["sm"]}
                dataIndex="hasReceipt"
                key="hasReceipt"
                render={(text, item) => item.hasReceipt ? (<Tag color="green" key={item.id}>{t('purchases.has_receipt')}</Tag>) : (<></>)}
            />

            <Column<PurchasesProp> title={t('purchase.createDate')}
                responsive={["sm"]}
                dataIndex="createDate"
                key="createDate"
                width='12%'
                render={(text, item) => <ShortDateTime date={item.createDate} />}
            />

            <Column<PurchasesProp>
                title={t('actions')}
                key="action"
                render={(text, item) => (
                    <Space>
                        <Popconfirm
                            title={t('products.delete_text')}
                            onConfirm={() => onDelete(item.id)}
                            okText={t('yes')}
                            cancelText={t('no')}>
                            <DeleteOutlined />
                        </Popconfirm>
                    </Space>
                )}
            />
        </Table>
    )
})
