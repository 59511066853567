import React from 'react'
import { Badge } from 'antd'
import { PurchasesProp } from '../Purchases.model';
import { NavLink } from 'react-router-dom';


export interface PurchaseItemNamesProps {
    purchase: PurchasesProp,
}

export const PurchaseItemNames: React.FC<PurchaseItemNamesProps> = React.memo(({ purchase }) => {
    return (
        <>
            {purchase.purchaseItems?.length > 5
                ?
                (<Badge count={purchase.purchaseItems.length - 5} offset={[17, 8]} style={{ backgroundColor: 'blue' }}>
                    <NavLink to={`/purchases/${purchase.id}/`} key={purchase.id} className="link">{purchase.purchaseItems.map(a => a.name).filter((name, index) => {
                        return index < 5;
                    }).join(", ")
                    }</NavLink>
                </Badge>)
                :
                (<NavLink to={`/purchases/${purchase.id}/`} key={purchase.id} className="link">{purchase.purchaseItems.map(a => a.name).join(", ")}</NavLink>)
            }
        </>
    )
})
