import React, { useCallback, useState } from 'react'
import { Button, Modal, Space } from 'antd'
import { Api, CreateTempTokenResponse } from '../../../api';
import { useTranslation } from 'react-i18next';

export interface CreateTelegramTempTokenBottonProps {
    dataFetch: () => void;
}

export const CreateTelegramTempTokenBotton: React.FC<CreateTelegramTempTokenBottonProps> = React.memo(({ dataFetch }) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);


    const handleFinish = useCallback(
        async () => {

            setLoading(true);

            try {
                const response = await Api.post<any, CreateTempTokenResponse>(`tokens`, {});

                setLoading(false);

                if (!response.ok)
                    return;

                Modal.info({
                    title: t('profile.telegram.modal.title'),
                    content: (
                        <Space direction='vertical'>
                            <a href={`https://t.me/FamilyMattersRuBot?start=${response.result.id}_${response.result.password}`}
                                target="_blank"
                            >
                                {t('profile.telegram.modal.description')}
                            </a>
                        </Space>
                    ),
                    onOk() { dataFetch() },
                });
            }catch{
                setLoading(false);
            }
        },
        [dataFetch, t]
    )

    return (
        <Button onClick={handleFinish} type="primary" loading={loading}>
            {t('profile.telegram.button')}
        </Button>
    )
})
