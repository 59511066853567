import './ContentGroup2.scss'

import React from 'react'
import { Typography } from 'antd'


export interface ContentGroup2Props {
    title: string
    children: React.ReactNode,
    className?: string
}

export const ContentGroup2: React.FC<ContentGroup2Props> = React.memo(
    ({ title, children, className }) => {

        const { Title } = Typography;

        return (
            <div className={`element-group2 ${className}`}>
                <Title level={5} className="element-title2">{title}</Title>
                <div className="element-content2">
                    {children}
                </div>
            </div>
        )
    }
)
