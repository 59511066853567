import './Home.scss'

import React, {
    FC, useContext,
} from 'react'

import 'antd/dist/reset.css';
import { PageContent } from '../../components';
import { useLocation } from 'react-router-dom'
import { PublicEvents } from '..';
import { AuthorizedContent, UnauthorizedContent } from './components';
import { UserContext } from '../../contexts';

export const Home: FC = React.memo(() => {

    const query = new URLSearchParams(useLocation().search);

    const eventId = query.get("eventId");

    const userData = useContext(UserContext)

    return (
        <PageContent>
            {eventId && <PublicEvents />}
            {!eventId && userData.isAutorized && <AuthorizedContent />}
            {!eventId && !userData.isAutorized && <UnauthorizedContent />}
        </PageContent>
    )
})
