import './LoaderContextProvider.scss'

import React, { FC, useMemo, useState } from 'react'
import { Spin } from 'antd'

import { LoaderContext } from './LoaderContext'
import { LoaderContextProviderProps } from './LoaderContextProviderProps'

export const LoaderContextProvider: FC<LoaderContextProviderProps> = React.memo(({ children }) => {
    const [loaderState, setLoaderState] = useState<boolean>(false)
    const spin = useMemo(
        () => loaderState && <div className="loader-wrapper"><Spin className="glb-loader" size="large" /></div>,
        [loaderState]
    )

    return (
        <LoaderContext.Provider value={{ loaderState, setLoaderState }}>
            {spin}
            {children}
        </LoaderContext.Provider>
    )
})