import React, { useCallback, useContext } from 'react'

import { EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu, Modal, Space } from 'antd';
import { Api, EventDetailContract, EventSetTitlePhotoRequest, PhotoContract } from '../../../api';
import { LoaderContext } from '../../../contexts';


export interface MoreBottonProps {
    eventId: string,
    photoContract: PhotoContract,
    setTitlePhotoAvailable: boolean,
    dataFetch?: () => void,
    selectUpdateDatePhoto: (photo: PhotoContract) => void;
}

export const MoreButton: React.FC<MoreBottonProps> = React.memo(({ eventId, photoContract, setTitlePhotoAvailable, dataFetch, selectUpdateDatePhoto }) => {
    const { t } = useTranslation();
    const { setLoaderState } = useContext(LoaderContext);

    const info = () => {
        Modal.info({
            title: t('event_photos.more_button.modal.file_info'),
            content: (
                <div>
                    <p>{`${t('event_photos.more_button.modal.original_file_name')}: ${photoContract.originFileName}`}</p>
                    <p>{`${t('event_photos.more_button.modal.date')}: ${photoContract.date}`}</p>
                </div>
            ),
            onOk() { },
        });
    };

    const setTitlePhoto = useCallback(async () => {

        setLoaderState(true)

        const request = { titlePhotoId: photoContract.id } as EventSetTitlePhotoRequest;

        const response = await Api.patch<EventSetTitlePhotoRequest, EventDetailContract>(`events/${eventId}/set-title-photo`, request);

        setLoaderState(false)

        if (!response.ok || response.result === null)
            return

        if (dataFetch)
            dataFetch();

    }, [setLoaderState, photoContract, eventId, dataFetch])

    const items = setTitlePhotoAvailable
        ? [
            {
                label: t('event_photos.more_button.set_title_photo'),
                key: '1',
                onClick: setTitlePhoto
            },
            {
                label: t('event_photos.more_button.show_info'),
                key: '2',
                onClick: () => { selectUpdateDatePhoto(photoContract) }
            },
        ]
        : [];

    const menu = (
        <Menu
            items={items}
        />
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
                    <EllipsisOutlined />
            </a>
        </Dropdown>
    )
})
