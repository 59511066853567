import './ProductListCreate.scss'

import React, { useCallback, useEffect } from 'react'
import { Form, Input, Button, Space, Select } from 'antd'
import { ProductListCreateProps } from './ProductListCreate.model';
import { ProductListRequest } from '../../../api';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const ProductListCreate: React.FC<ProductListCreateProps> = React.memo(({ onAddProductList, productList, onCancelAddProductList, purchaseCategories }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const [form] = Form.useForm()

    const handleFinish = useCallback(
        (values: Partial<ProductListRequest>) => {
            onAddProductList(values as ProductListRequest)

            form.resetFields();
        },
        [form, onAddProductList]
    )

    const isUpdating = productList.id !== undefined;

    const onPurchaseCategoryChange = (value: string) => {
        form.setFieldsValue({ defaultPurchaseCategoryId: value });
    };

    const Option = Select.Option;

    useEffect(() => {
        form.resetFields()
    }, [form, productList])

    return (
        <Form
            className="form"
            name="productListCreateForm"
            initialValues={productList}
            onFinish={handleFinish}
            form={form}
        >
            <Form.Item
                hidden={true}
                name="id"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('product_list.create.name')}
                name="name"
                rules={[
                    {
                        required: true,
                        message: tt('product_list.create.name_required_message'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={<NavLink to={`/purchase-categories`} className="link">
                    {t('purchases.purchase_category')}
                </NavLink>}
                name="defaultPurchaseCategoryId"
            >
                <Select
                    placeholder={t('purchases.purchase_category')}
                    onChange={onPurchaseCategoryChange}
                    allowClear
                >
                    {
                        purchaseCategories.map(a => <Option key={a.id} value={a.id}>{a.name}</Option>)
                    }
                </Select>
            </Form.Item>

            <Form.Item >
                <Space>
                    <Button
                        form="productListCreateForm"
                        type="primary"
                        htmlType="submit"
                        className="button"
                    >
                        {isUpdating ? (<>{t('product_list.create.bt_update')}</>) : (<>{t('product_list.create.bt_add')}</>)}
                    </Button>

                    {isUpdating && (<Button htmlType="button" className="button" onClick={onCancelAddProductList}>
                        {t('product_list.create.bt_reset')}
                    </Button>)}
                </Space>
            </Form.Item>
        </Form>
    )
})
