import './Logon.scss'

import React from 'react'
import { LoginElement } from './LoginElement';
import { useTranslation } from 'react-i18next';

export const Login: React.FC = React.memo(() => {
    const { t } = useTranslation();

    return (
        <div>
            <>
                {t('header.logon.login')}&nbsp;<a href="/login-telegram.html">Telegram</a>,&nbsp;<LoginElement name="Google" scheme="Google" />,&nbsp;<LoginElement name="VK" scheme="Vkontakte" />,&nbsp;<LoginElement name="Яндекс" scheme="Yandex" />,&nbsp;<LoginElement name="mail.ru" scheme="MailRu" />
            </>
        </div>
    )
})
