import React from 'react'

import { UserContextProps } from './UserContextProps'

export const UserContext = React.createContext<UserContextProps>({
    isRequested: false,
    isAutorized: false,
    userName: "",
    loginProviderName: "",
    isAvailableEvents: false,
    familyFeatures: 0
})
