import './ProductCreate.scss'

import React, { ChangeEvent, useCallback, useContext, useState } from 'react'
import { Form, Input, Button, InputNumber, message, Tag, Space } from 'antd'
import { ProductCreateProps } from './ProductCreate.model';
import { ProductDto } from '../Products.model';
import { useTranslation } from 'react-i18next';
import { LoaderContext } from '../../../contexts';
import { useParams } from 'react-router-dom';
import { Api } from '../../../api';
import { Guid } from "guid-typescript";

export const ProductCreate: React.FC<ProductCreateProps> = React.memo(({ onAddProduct }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [form] = Form.useForm()
    const { setLoaderState } = useContext(LoaderContext);
    const urlParams = useParams();
    const product = { name: '', count: 1 } as ProductDto;
    const [products, setProducts] = useState<ProductDto[]>([])

    const handleAddProduct = useCallback(
        async (values: Partial<ProductDto>) => {

            setLoaderState(true)

            //TODO
            if (products.length > 1) {
                products.forEach(async p => {
                    p.listId = urlParams.productListId ?? '';

                    const r = await Api.post<ProductDto, ProductDto>(`products`, p);

                    if (r.ok) {
                        setProducts(a => a.filter(f => f.id !== p.id));
                        onAddProduct(r.result as ProductDto);
                    }
                });

                form.resetFields();
            }
            else {
                const pr = values as ProductDto;
                pr.listId = urlParams.productListId ?? '';
                const r = await Api.post<ProductDto, ProductDto>(`products`, pr);

                if (r.ok) {
                    const addedProduct = r.result as ProductDto;
                    onAddProduct(addedProduct);
                    form.resetFields();
                    message.success(`${t('added')} "${addedProduct.name}"`);
                }
                else
                    message.error(t('product.add_error_message'));
            }

            setLoaderState(false);
        },
        [form, onAddProduct, setLoaderState, t, urlParams.productListId, products]
    )

    const parseProductFromString = (s: string): ProductDto => {

        const a = s.split('-');

        const c = a.length > 0 ? parseInt(a[1]) : 1

        return {
            id: Guid.create().toString(),
            name: a[0],
            count: isNaN(c) ? 1 : c,
        } as ProductDto
    }

    const handleNameOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const name = e.target.value;

            const arr = name.split(',')
                .filter(a => a.length > 0)
                .map((a) => parseProductFromString(a));

            setProducts(s => [...arr]);
        },
        []
    );

    return (
        <Form
            className="content-element"
            name="productCreateForm"
            initialValues={product}
            onFinish={handleAddProduct}
            form={form}
        >
            <Form.Item >
                <Space>
                    <Button
                        form="productCreateForm"
                        type="primary"
                        htmlType="submit"
                        className="like_alice"
                    >
                        {t('product.bt_add')}
                    </Button>

                    <a href="https://dialogs.yandex.ru/store/skills/4b64f757-semejnye-dela?utm_source=site&utm_medium=badge&utm_campaign=v1&utm_term=d3" target="_blank" rel="noopener noreferrer"><img alt="Алиса это умеет" src="https://dialogs.s3.yandex.net/badges/v1-term3.svg" /></a>
                </Space>
            </Form.Item>

            <Form.Item
                label={t('product.name')}
                name="name"
                rules={[
                    {
                        required: true,
                        message: tt('product.name_required_message'),
                    },
                ]}
            >
                <Input onChange={handleNameOnChange} autoComplete="off" />
            </Form.Item>

            {products.length <= 1 &&
                <>
                    < Form.Item
                        label={t('count')}
                        name="count"
                    >
                        <InputNumber min={1} type="number" />
                    </Form.Item>

                    < Form.Item
                        label={t('product.unit')}
                        name="unit"
                    >
                        <Input  />
                    </Form.Item>

                    < Form.Item
                        label={t('product.expectedPrice')}
                        name="expectedPrice"
                    >
                        <InputNumber min={1} type="number" />
                    </Form.Item>

                    < Form.Item
                        label={t('product.priority')}
                        name="priority"
                    >
                        <InputNumber type="number" />
                    </Form.Item>
                </>
            }

            {products.length > 1 &&
                <Form.Item >
                    {products.map(p => <Tag>{p.name} - {p.count}</Tag>)}
                </Form.Item>
            }

            <Form.Item >
                <Space>
                    <Button
                        form="productCreateForm"
                        type="primary"
                        htmlType="submit"
                        className="like_alice"
                    >
                        {t('product.bt_add')}
                    </Button>

                    <a href="https://dialogs.yandex.ru/store/skills/4b64f757-semejnye-dela?utm_source=site&utm_medium=badge&utm_campaign=v1&utm_term=d3" target="_blank" rel="noopener noreferrer"><img alt="Алиса это умеет" src="https://dialogs.s3.yandex.net/badges/v1-term3.svg" /></a>
                </Space>
            </Form.Item>
        </Form>
    )
})
