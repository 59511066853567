import React, { useCallback, useContext, useEffect } from 'react'


import { useTranslation } from 'react-i18next';
import { Button, Form, Input, message, Modal } from 'antd';
import { Api, PhotoContract, UpdateDatePhotoRequest } from '../../../api';
import { LoaderContext } from '../../../contexts';
import moment from 'moment';
import { DATE_TIME_LOCAL_FORMAT } from '../../../consts';


export interface UpdateDateModalProps {
    eventId: string;
    photo: PhotoContract | undefined;
    setPhoto: (photo: PhotoContract | undefined) => void;
}

export const UpdateDateModal: React.FC<UpdateDateModalProps> = React.memo(({ eventId, photo, setPhoto }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const { setLoaderState } = useContext(LoaderContext);
    const [updateDatePhotoForm] = Form.useForm()

    const onFinish = useCallback(
        async (values: Partial<PhotoContract>) => {

            const request = { ...values } as UpdateDatePhotoRequest

            setLoaderState(true)

            const r = await Api.patch<UpdateDatePhotoRequest, PhotoContract>(`events/${eventId}/photos/${values?.id}/set-date`, request);

            setLoaderState(false);

            if (!r.ok)
                message.error(r.exceptionResult.Message)

            setPhoto(undefined);
            updateDatePhotoForm.resetFields();

        },
        [eventId, updateDatePhotoForm, setPhoto, setLoaderState]
    )

    useEffect(() => {
        const cd = photo?.date !== undefined
            ? moment(photo.date).format(DATE_TIME_LOCAL_FORMAT)
            : undefined;

        updateDatePhotoForm.setFieldsValue({ ...photo, date: cd });
    }, [photo, updateDatePhotoForm])

    return (
        <Modal forceRender title={t('event_photos.more_button.modal.file_info')}
            open={photo !== undefined}
            onOk={() => updateDatePhotoForm.submit()}
            onCancel={() => setPhoto(undefined)}
            footer={[]}
        >
            <Form
                className="form"
                name="updateDatePhotoForm"
                onFinish={onFinish}
                form={updateDatePhotoForm}
            >
                <Form.Item
                    name="id"
                    hidden
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('event_photos.more_button.modal.original_file_name')}
                    name="originFileName"
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label={t('event_photos.more_button.modal.date')}
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: tt('product.name_required_message'),
                        },
                    ]}
                >
                    <Input type="datetime-local" />
                </Form.Item>

                <Form.Item
                    name="action"
                >
                    <Button
                        form="updateDatePhotoForm"
                        type="primary"
                        htmlType="submit"
                    >
                        {t('update')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
})
