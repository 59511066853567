import { Typography } from 'antd';
import React, {
    FC,
} from 'react'

import { BudgetResponse } from '../../../api';


export interface BudgetTitleProps {
    budgetResponse: BudgetResponse;
}

export const BudgetTitle: FC<BudgetTitleProps> = React.memo(({ budgetResponse }) => {

    const { Text } = Typography;

    return (
        <>
            {budgetResponse.done ? <Text delete>{budgetResponse.title}</Text> : budgetResponse.title}
        </>
    )
})
