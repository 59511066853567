export interface ProductsUrlParamProp {
    productListId: string
}

export interface ProductDto {
    id: string
    name: string
    count: number
    state: ProductState
    listId: string,
    expectedPrice: number,
    priority: number | null,
    unit: string,
    createDeviceName: string,
    createUserName: string,
    createDate: Date,
    fileName?: string,
    isNextTime: boolean,
    productCategoryName: string,
    rootProductCategoryName: string,
    rootProductCategoryWeight?: number,
}

export enum ProductState {
    'New' = 1,
    'Ordering' = 2,
}

export interface PurchaseCreateDto {
    ammount: number
    createDate: Date
}