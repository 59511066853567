import './Help.scss'

import React, {
    FC,
} from 'react'

import 'antd/dist/reset.css';
import { ContentTitle, PageContent } from '../../components';
import { Card, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import { articles } from './Article';

export const Help: FC = React.memo(() => {

    return (
        <PageContent>
            <ContentTitle title={'Помощь'} />

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

                {articles.map(a =>
                    <Card key={a.id} title={<NavLink to={a.id}>{a.title}</NavLink>}>
                        {a.content}
                    </Card>
                )}
            </Space>
        </PageContent>
    )
})
