import './FilterBlock.scss'

import React, { FormEvent, useCallback, useEffect } from 'react'
import { Form, Input, Button, Space, Checkbox, Select } from 'antd'
import { useTranslation } from 'react-i18next';
import { PurchaseCategoryResponse } from '../../../api';

export interface FilterBlockProps {
    onLoadData: () => void;
    purchaseCategories: PurchaseCategoryResponse[],
}

export interface FilterBlockUrlParamProp {
    HasReceipt?: boolean,
    ItemName?: string,
    CreateDateFrom?: string,
    CreateDateTo?: string,
    AmountFrom?: string,
    AmountTo?: string,
    PurchaseCategoryId?: string,
}

export const FilterBlock: React.FC<FilterBlockProps> = React.memo(({ onLoadData, purchaseCategories }) => {

    const { t } = useTranslation();
    const tt = (p: string): string => t(p);
    const [form] = Form.useForm()

    const handleFinish = useCallback(
        (values: Partial<FilterBlockUrlParamProp>) => {
            const searchParams = new URLSearchParams(window.location.search);

            if (values.HasReceipt !== undefined)
                searchParams.set("HasReceipt", values.HasReceipt.toString());

            if (values.ItemName !== undefined && values.ItemName !== null)
                searchParams.set("ItemName", values.ItemName);

            if (values.CreateDateFrom !== undefined && values.CreateDateFrom !== null)
                searchParams.set("CreateDateFrom", values.CreateDateFrom);

            if (values.CreateDateTo !== undefined && values.CreateDateTo !== null)
                searchParams.set("CreateDateTo", values.CreateDateTo);

            if (values.AmountFrom !== undefined && values.AmountFrom !== null)
                searchParams.set("AmountFrom", values.AmountFrom);

            if (values.AmountTo !== undefined && values.AmountTo !== null)
                searchParams.set("AmountTo", values.AmountTo);

            if (values.PurchaseCategoryId !== undefined && values.PurchaseCategoryId !== null)
                searchParams.set("PurchaseCategoryId", values.PurchaseCategoryId);

            window.history.pushState(null, "purchases", '/purchases?' + searchParams.toString());

            onLoadData();
        },
        [onLoadData]
    )

    const handleReset = useCallback(
        (event: FormEvent<HTMLFormElement>) => {

            window.history.pushState(null, "purchases", '/purchases');

            //crutch
            window.location.reload();
        },
        []
    )

    const onPurchaseCategoryChange = (value: string) => {
        form.setFieldsValue({ PurchaseCategoryId: value });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        const initialValues = {
            HasReceipt: strToBool(searchParams.get("HasReceipt")),
            ItemName: searchParams.get("ItemName"),
            AmountFrom: searchParams.get("AmountFrom"),
            AmountTo: searchParams.get("AmountTo"),
            CreateDateFrom: searchParams.get("CreateDateFrom"),
            PurchaseCategoryId: searchParams.get("PurchaseCategoryId"),
        } as FilterBlockUrlParamProp;

        form.setFieldsValue(initialValues);
    }, [form])

    if (!('URLSearchParams' in window))
        return (<></>)

    const strToBool = (s: string | null | undefined) => {
        if (s === undefined || s === null)
            return undefined;

        return s === "true";
    }

    const { Option } = Select;

    return (
        <Form
            className="form"
            name="FilterBlock"
            onFinish={handleFinish}
            onReset={handleReset}
            form={form}
        >
            <Form.Item
                label={t('purchases.filter.has_receipt')}
                name="HasReceipt"
                valuePropName="checked"
            >
                <Checkbox />
            </Form.Item>

            <Form.Item
                label={t('purchases.filter.item_name')}
                name="ItemName"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('purchase.amount')}
                style={{ margin: '0px' }}
            >
                <Form.Item
                    name="AmountFrom"
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                    <Input type="number" placeholder={tt('purchases.filter.amount_from')} />
                </Form.Item>

                <Form.Item
                    name="AmountTo"
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder={tt('purchases.filter.amount_to')} />
                </Form.Item>
            </Form.Item>

            <Form.Item
                label={t('purchases.filter.create_date')}
                style={{ margin: '0px' }}
            >
                <Form.Item
                    name="CreateDateFrom"
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                    <Input type="date" placeholder={tt('purchases.filter.create_date_from')} />
                </Form.Item>

                <Form.Item
                    name="CreateDateTo"
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                    <Input type="date" placeholder={tt('purchases.filter.create_date_to')} />
                </Form.Item>
            </Form.Item>

            <Form.Item
                label={t('purchases.purchase_category')}
                name="PurchaseCategoryId"
            >
                <Select
                    placeholder={t('purchases.purchase_category')}
                    onChange={onPurchaseCategoryChange}
                    allowClear
                >
                    {
                        purchaseCategories.map(a => <Option key={a.id} value={a.id}>{a.name}</Option>)
                    }
                </Select>
            </Form.Item>


            <Form.Item >
                <Space>
                    <Button
                        form="FilterBlock"
                        type="primary"
                        htmlType="submit"
                        className="button"
                    >
                        <>{t('purchases.filter.bt_filter')}</>
                    </Button>
                </Space>

                <Space>
                    <Button
                        form="FilterBlock"
                        htmlType="reset"
                        className="button"
                    >
                        <>{t('reset')}</>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
})
