import React from 'react'
import { Badge, Popconfirm, Space, Typography } from 'antd'
import { ProductCategoryContract } from '../../../api';
import { useTranslation } from 'react-i18next';

export interface ProductCategoryElementProps {
    item: ProductCategoryContract
    onEdit: (item: ProductCategoryContract) => void;
    onDelete: (id: string) => void;
}

export const ProductCategoryElement: React.FC<ProductCategoryElementProps> = React.memo(({ item, onEdit, onDelete }) => {
    const { t } = useTranslation();
    const { Link } = Typography;

    return (
        <Space direction="horizontal">
            <Badge count={item.productsDraftCount} offset={[15, 10]}>
                <Space direction="horizontal" size={'large'}>
                    <div>{item.icon}</div>
                    <div>{item.weight}</div>
                    <div>{item.name}</div>
                    <div>({item.shortName})</div>
                </Space>
            </Badge>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <Link onClick={() => onEdit(item)}>Edit</Link>
            <Popconfirm
                title={t('products.delete_text')}
                onConfirm={() => onDelete(item.id)}
                okText={t('yes')}
                cancelText={t('no')}>
                <Link>Remove</Link>
            </Popconfirm>
        </Space>
    )
})
