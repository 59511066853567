import './EventView.scss'

import React, { FC, useEffect } from 'react'
import { Form, Image, Input } from 'antd'
import { EventViewProps } from './EventView.model';
import { useTranslation } from 'react-i18next';

export const EventView: React.FC<EventViewProps> = React.memo(({ eventProps }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm()

    useEffect(() => {
        form.resetFields();
    }, [eventProps, form])

    return (
        <Form
            className="content-element"
            name="eventCreateForm"
            initialValues={eventProps || {}}
            form={form}
        >

            <Form.Item label={t('event.create.title_photo')}>
                <Image width={100} src={eventProps?.titlePhoto.fileName} />
            </Form.Item>

            <Form.Item label={t('event.create.name')} name="name">
                <Input readOnly={true} />
            </Form.Item>

            <Form.Item label={t('event.create.public_url_view')} name="isPublicUrlView">
                <a href={eventProps?.publicUrlView} >{eventProps?.publicUrlView}</a>
            </Form.Item>

            <Form.Item label={t('event.create.public_url_edit')} name="isPublicUrlEdit">
                <a href={eventProps?.publicUrlEdit} >{eventProps?.publicUrlEdit}</a>
            </Form.Item>

            <Form.Item label={t('event.create.date')} name="date">
                <Input type="datetime-local" readOnly={true} />
            </Form.Item>
        </Form>
    )
})
