import './EventUpdate.scss'

import React, { useCallback, useEffect, useState, useContext } from 'react'
import { Form, Input, Button, Switch, Image, Checkbox, Row, Col } from 'antd'
import { EventUpdateProps } from './EventUpdate.model';
import { EventDetailContract, EventUpdateContract, Api, FriendsContract } from '../../../api';
import { LoaderContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { ContentGroup } from '../../../components';

export const EventUpdate: React.FC<EventUpdateProps> = React.memo(({ eventProps, refresh }) => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const { setLoaderState } = useContext(LoaderContext)
    const [form] = Form.useForm()
    const [eventData, setEventData] = useState<EventDetailContract | null>(null)
    const [friendsData, setFriendsData] = useState<FriendsContract[]>([])

    const friendsFetch = useCallback(async () => {

        try {
            setLoaderState(true)

            const response = await Api.get<FriendsContract[]>(`friends`)

            if (!response.result)
                return

            setFriendsData(response.result)

        } catch (err) {
            console.error(err)
        } finally {
            setLoaderState(false)
        }

    }, [setLoaderState])

    useEffect(() => {
        friendsFetch()
    }, [friendsFetch])

    const handleFinish = useCallback(
        async (values: Partial<EventDetailContract>) => {

            const e = values as EventDetailContract;

            setLoaderState(true)

            const eventUpdateContract = {
                name: e.name,
                date: e.date,
                isPublicUrlEdit: eventData?.isPublicUrlEdit,
                isPublicUrlView: eventData?.isPublicUrlView,
                titlePhotoId: eventData?.titlePhoto?.id
            } as EventUpdateContract;

            const response = await Api.put<EventUpdateContract, EventDetailContract>(`events/${eventData?.id}`, eventUpdateContract)

            setLoaderState(false)

            if (!response.ok || response.result === undefined)
                return;

            setEventData(response.result)

            form.resetFields();
        },
        [form, eventData, setLoaderState]
    )

    const onChange = async (checkedValues: any) => {

        const guestId = checkedValues.target.value;
        let guests = eventData?.guests ?? [];

        if (checkedValues.target.checked) {
            const requestResult = await Api.postWithoutResponse<FriendsContract>(`events/${eventData?.id}/guests/${guestId}`, {} as FriendsContract)
            if (requestResult)
                guests = guests.concat(guestId);
        }
        else {
            const requestResult = await Api.delete(`events/${eventData?.id}/guests/${guestId}`);
            if (requestResult)
                guests = guests.filter(a => a !== guestId);
        }

        setEventData({
            ...eventData,
            guests: guests
        } as EventDetailContract);
    }

    useEffect(() => {
        setEventData(eventProps)
    }, [eventProps, setEventData])

    useEffect(() => {
        form.resetFields();
    }, [eventData, form])

    const onIsPublicUrlView = (e: boolean) => {
        setEventData(prev => {
            if (prev === null)
                return prev

            prev.isPublicUrlView = e;

            return prev;
        })
    }

    const onIsPublicUrlEdit = (e: boolean) => {
        setEventData(prev => {
            if (prev === null)
                return prev

            prev.isPublicUrlEdit = e;

            return prev;
        })
    }

    return (
        <div className="content-element">
            <ContentGroup title={''}>
                <Form
                    name="eventCreateForm"
                    initialValues={eventData || {}}
                    onFinish={handleFinish}
                    form={form}
                >
                    <Form.Item label={t('event.create.title_photo')} name="titlePhotoImage">
                        <Image width={100} src={eventProps?.titlePhoto?.fileName} />
                    </Form.Item>

                    <Form.Item
                        label={t('event.create.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: tt('event.create.name_required_message'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label={t('event.create.public_url_view')} name="isPublicUrlView">
                        <div>
                            <Switch defaultChecked={eventData?.isPublicUrlView} onChange={onIsPublicUrlView} />
                            <span>&nbsp;&nbsp;</span>
                            <a href={eventData?.publicUrlView} >{eventData?.publicUrlView}</a>
                        </div>
                    </Form.Item>

                    <Form.Item label={t('event.create.public_url_edit')} name="isPublicUrlEdit">
                        <div>
                            <Switch defaultChecked={eventData?.isPublicUrlEdit} onChange={onIsPublicUrlEdit} />
                            <span>&nbsp;&nbsp;</span>
                            <a href={eventData?.publicUrlEdit} >{eventData?.publicUrlEdit}</a>
                        </div>
                    </Form.Item>

                    <Form.Item label={t('event.create.date')} name="date">
                        <Input type="datetime-local" />
                    </Form.Item>

                    <Form.Item
                        name="actions"
                    >
                        <Button
                            form="eventCreateForm"
                            type="primary"
                            htmlType="submit"
                        >
                            {t('event.create.save')}
                        </Button>
                    </Form.Item>
                </Form>
            </ContentGroup>

            <ContentGroup title={t('event.create.guests')}>
                <Row>
                    {friendsData.map(function (d, idx) {
                        return (<Col span={8} key={idx}>
                            <Checkbox onChange={onChange}
                                value={d.familyId}
                                checked={eventData?.guests.some(value => value === d.familyId)}
                            >
                                {d.surname}
                            </Checkbox>
                        </Col>)
                    })}
                </Row>
            </ContentGroup>
        </div>
    )
})
