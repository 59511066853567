import './ProductListsAuthorized.scss'

import React, {
    FC,
} from 'react'


import { PageContent } from '../../../components';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'antd';


export const ProductListsUnauthorized: FC = React.memo(() => {
    const { t } = useTranslation();
    const tt = (p: string): string => t(p);

    const { Title } = Typography;

    return (
        <PageContent>
            <Title>Семейные покупки</Title>

            <Card>
                <div dangerouslySetInnerHTML={{ __html: tt('home.unauthorized_content.products.description') }} />
            </Card>
        </PageContent>
    )
})
